import React from 'react';
import clsx from 'clsx';
import { Trans, t } from '@lingui/macro';
import { t8 } from '../../../../i18n/i18n';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Description,
  Button,
  FixErrorsTooltip,
  CheckBox,
  FormControlLabel,
} from '../../../Common';
import './UserTypeSelectDialog.scss';
import { UserType } from '../../../../models/user-type';
import { validate } from './validators';
import { hasErrors } from '../../../../utils/index';
import { useValidation } from '../../../Common/Form/useValidation';
import EmailConfiguration from '../../EmailConfiguration/EmailConfiguration';
import { useEmailConfiguration } from '../../EmailConfiguration/useEmailConfiguration';
import { supportEmail } from '../../../../config';

const UserTypeSelectDialog = ({ onClose, onContinue, customerName }) => {
  const [userType, setUserType] = React.useState(null);
  const [shouldSendEmail, setShouldSendEmail] = React.useState(false);
  const emailConfiguration = useEmailConfiguration(customerName);

  const [
    { email, errors: emailErrors, hasActiveErrors: hasActiveEmailErrors },
    { setAllTouched: setEmailAllTouched },
  ] = emailConfiguration;

  const [errors, touched, setTouched, containsActiveErrors] = useValidation(
    userType,
    validate,
    {},
    {}
  );

  const setAllTouched = () => {
    setTouched({
      ...touched,
      userType: true,
    });
    setEmailAllTouched();
  };

  const handleContinue = () => {
    if (hasErrors(errors) || (shouldSendEmail && hasErrors(emailErrors))) {
      setAllTouched();
    } else {
      onContinue({ userType, email: shouldSendEmail ? email : null });
    }
  };

  return (
    <Dialog open size="sm">
      <DialogTitle onClose={onClose}>
        <Trans>Select user type</Trans>
      </DialogTitle>
      <DialogContent>
        <div className="d-flex row justify-content-left">
          <div className="p-2 col-6">
            <div
              className={clsx('mg-user-type-frame mg-cursor-pointer', {
                selected: userType === UserType.MACHINE,
              })}
              onClick={() => setUserType(UserType.MACHINE)}
              role="button"
              tabIndex={0}
              onKeyPress={() => setUserType(UserType.MACHINE)}
            >
              <h5 className="mt-2 mg-text-align-center">
                <Trans>Machine user</Trans>
              </h5>
              <div className="m-2 mg-body-2">
                <Trans>
                  Machine user with client ID and secret for backend application
                  communication
                </Trans>
              </div>
            </div>
          </div>
          <div className="p-2 col-6">
            <div
              className={clsx('mg-user-type-frame mg-cursor-pointer', {
                selected: userType === UserType.REGULAR,
              })}
              onClick={() => {
                setUserType(UserType.REGULAR);
                setShouldSendEmail(false);
              }}
              role="button"
              tabIndex={-1}
              onKeyPress={() => {
                setUserType(UserType.REGULAR);
                setShouldSendEmail(false);
              }}
            >
              <h5 className="mt-2 mg-text-align-center">
                <Trans>Regular user</Trans>
              </h5>
              <div className="m-2 mg-body-2">
                <Trans>
                  Regular user with email and password to allow login to
                  products
                </Trans>
              </div>
            </div>
          </div>
          {touched.userType && errors.userType && (
            <p className="mg-error-message mt-1 ml-3">{errors.userType}</p>
          )}
        </div>
        {userType === UserType.MACHINE && (
          <div>
            <FormControlLabel label={t8(t`Send credentials after creation`)}>
              <CheckBox
                checked={shouldSendEmail}
                onChange={() => setShouldSendEmail(prev => !prev)}
              />
            </FormControlLabel>
            {shouldSendEmail && (
              <>
                <Description className="mb-3 mt-1">
                  <Trans id="users.email.message">
                    Credentials will be sent to the entered emails from
                    {supportEmail}
                  </Trans>
                </Description>
                <EmailConfiguration emailConfiguration={emailConfiguration} />
              </>
            )}
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <Trans>Cancel</Trans>
        </Button>
        <FixErrorsTooltip
          show={
            containsActiveErrors || (shouldSendEmail && hasActiveEmailErrors)
          }
        >
          <Button
            color="secondary"
            onClick={handleContinue}
            disabled={
              containsActiveErrors || (shouldSendEmail && hasActiveEmailErrors)
            }
          >
            <Trans>Continue</Trans>
          </Button>
        </FixErrorsTooltip>
      </DialogActions>
    </Dialog>
  );
};

export default UserTypeSelectDialog;
