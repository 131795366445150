import React from 'react';
import IconButton from '../IconButton/index';
import { ClearIcon } from '../../../assets/index';

const DialogTitle = ({ children, onClose }) => {
  return (
    <div className="mg-dialog-title-root d-flex justify-content-between pt-4 pb-3 px-4">
      <h3>{children}</h3>
      {onClose && (
        <IconButton onClick={onClose}>
          <ClearIcon />
        </IconButton>
      )}
    </div>
  );
};

export default DialogTitle;
