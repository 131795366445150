import React from 'react';
import { useRoles } from '../../store/hooks/useRoles';
import { validate } from './UserCreateDialog/UserInfoDialog/validators';
import { useValidation } from '../Common/Form/useValidation';
import { useVesselInsights } from './useVesselInsights';
import { useRoleRules } from '../../store/hooks/useRoleRules';
import { useContracts } from '../../store/hooks/useContracts';
import { isBefore, getUtcNow } from '../../utils';

export const useRoleSelection = (initialRoles, salesforceAccountId) => {
  const { isFetching, roles, adminRoleId, vesselInsightsRoleId } = useRoles();
  const { roleRules, isFetching: isRoleRulesFetching } = useRoleRules();
  const [, isContractsFetching, productCodes] = useContracts(
    salesforceAccountId
  );

  const vesselInsights = useVesselInsights();
  const [
    {
      vesselInsightsModel,
      errors: vesselInsightsErrors,
      containsActiveErrors: vesselInsightsContainsActiveErrors,
    },
    { onAllTouched: onVesselInsightsAllTouched },
  ] = vesselInsights;

  const [selectedRoles, setSelectedRoles] = React.useState([]);

  React.useEffect(() => {
    setSelectedRoles(initialRoles.map(r => r.id));
  }, [initialRoles]);

  const vesselInsightsRoleSelected = React.useMemo(() => {
    const rule =
      roleRules && roleRules.find(rr => rr.roleId === vesselInsightsRoleId);
    const productCode =
      rule && productCodes.find(pc => pc.productCode === rule.productCode);
    const productExpired =
      productCode && isBefore(productCode.endDate, getUtcNow());
    return (
      (!rule || !!(rule && productCode && !productExpired)) &&
      (selectedRoles.includes(adminRoleId) ||
        selectedRoles.includes(vesselInsightsRoleId))
    );
  }, [
    adminRoleId,
    productCodes,
    roleRules,
    selectedRoles,
    vesselInsightsRoleId,
  ]);

  const [errors, touched, setTouched, containsActiveErrors] = useValidation(
    selectedRoles,
    validate,
    {},
    {}
  );

  const handleRoleChange = React.useCallback(
    (roleId, checked) => {
      setTouched({
        ...touched,
        selectedRoles: true,
      });
      if (roleId === adminRoleId && checked) {
        setSelectedRoles([roleId]);
      } else {
        setSelectedRoles(prev =>
          checked ? [...prev, roleId] : prev.filter(id => id !== roleId)
        );
      }
    },
    [adminRoleId, setTouched, touched]
  );

  const setAllTouched = () => {
    setTouched({
      ...touched,
      selectedRoles: true,
    });
    if (vesselInsightsRoleSelected) {
      onVesselInsightsAllTouched();
    }
  };

  return [
    {
      isFetching: isFetching || isRoleRulesFetching || isContractsFetching,
      roles,
      selectedRoles,
      adminRoleId,
      vesselInsights,
      vesselInsightsRoleSelected,
      vesselInsightsModel,
      vesselInsightsErrors,
      errors,
      touched,
      containsActiveErrors:
        containsActiveErrors || vesselInsightsContainsActiveErrors,
    },
    { onRoleChange: handleRoleChange, setAllTouched },
  ];
};
