import React from 'react';
import { Trans } from '@lingui/macro';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Loading,
  FixErrorsTooltip,
} from '../../../Common';
import { hasErrors } from '../../../../utils/index';
import UserDetailsRolesEdit from '../../../Users/UserDetailDialog/UserDetailsRolesEdit';
import { useContracts } from '../../../../store/hooks/useContracts';
import { useRoleSelection } from '../../../Users/useRoleSelection';

const TenantRolesDialog = ({ selectedCustomer, onClose, onContinue }) => {
  const [initialRoles] = React.useState([]);
  const [
    {
      isFetching,
      roles,
      selectedRoles,
      adminRoleId,
      vesselInsights,
      vesselInsightsRoleSelected,
      vesselInsightsModel,
      vesselInsightsErrors,
      errors,
      touched,
      containsActiveErrors,
    },
    { onRoleChange, setAllTouched },
  ] = useRoleSelection(
    initialRoles,
    (selectedCustomer || {}).customerDetails.salesforceAccountId
  );

  const [, isContractsFetching, productCodes] = useContracts(
    (selectedCustomer || {}).customerDetails.salesforceAccountId
  );

  const handleContinue = () => {
    if (
      hasErrors(errors) ||
      (vesselInsightsRoleSelected && hasErrors(vesselInsightsErrors))
    ) {
      setAllTouched();
    } else {
      onContinue(
        selectedRoles,
        vesselInsightsRoleSelected ? vesselInsightsModel : null
      );
    }
  };

  return (
    <Dialog open>
      {(isFetching || isContractsFetching) && <Loading />}
      <DialogTitle onClose={onClose}>
        <Trans>Select roles</Trans>
      </DialogTitle>
      <DialogContent>
        <h5 className="mb-2">
          <Trans>Select roles to assign to users of created tenant.</Trans>
        </h5>
        {touched.selectedRoles && errors.selectedRoles && (
          <p className="mg-error-message mt-1">{errors.selectedRoles}</p>
        )}
        <UserDetailsRolesEdit
          adminRoleId={adminRoleId}
          selectedRoles={selectedRoles}
          roles={roles}
          vesselInsightsRoleSelected={vesselInsightsRoleSelected}
          onRoleChange={onRoleChange}
          vesselInsights={vesselInsights}
          initialVesselInsights={selectedCustomer.vesselInsights}
          productCodes={productCodes}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <Trans>Cancel</Trans>
        </Button>
        <FixErrorsTooltip show={containsActiveErrors}>
          <Button
            color="secondary"
            onClick={handleContinue}
            disabled={containsActiveErrors}
          >
            <Trans>Create</Trans>
          </Button>
        </FixErrorsTooltip>
      </DialogActions>
    </Dialog>
  );
};

export default TenantRolesDialog;
