import React from 'react';
import ReactPaginate from 'react-paginate';
import { firstPageNumber } from '../../../constants';

const patternDotsOnSides = {
  marginPagesDisplayed: 1,
  pageRangeDisplayed: 4,
};

const patternMiddleDots = {
  marginPagesDisplayed: 5,
  pageRangeDisplayed: 2,
};
const minimalDotsOnSidesLength = 11;

export default function Pagination({
  activePageNumber,
  pageCount,
  pageChanged,
}) {
  const usePatternMiddleDots =
    activePageNumber < patternMiddleDots.marginPagesDisplayed ||
    activePageNumber > pageCount - patternMiddleDots.marginPagesDisplayed + 1 ||
    pageCount < minimalDotsOnSidesLength;
  const pattern = usePatternMiddleDots ? patternMiddleDots : patternDotsOnSides;

  const handlePageChange = e => {
    pageChanged(e.selected + firstPageNumber);
  };

  return (
    <ReactPaginate
      forcePage={activePageNumber - firstPageNumber}
      previousLabel="Previous"
      nextLabel="Next"
      breakLabel="..."
      breakClassName="mg-pagination-break"
      pageCount={pageCount}
      marginPagesDisplayed={pattern.marginPagesDisplayed}
      pageRangeDisplayed={pattern.pageRangeDisplayed}
      onPageChange={handlePageChange}
      pageLinkClassName="mg-pagination-link"
      containerClassName="mg-pagination-wrapper"
      pageClassName="mg-pagination-entry"
      activeClassName="mg-active"
      previousClassName="mg-pagination-entry"
      previousLinkClassName="mg-pagination-link"
      nextClassName="mg-pagination-entry"
      nextLinkClassName="mg-pagination-link"
      disabledClassName="mg-disabled"
      hrefBuilder={e => e.toString()}
    />
  );
}
