export const KEY_CODES = {
  ENTER: 13,
  ESCAPE: 27,
};

export const KEYS = {
  COMMA: ',',
  SEMICOLON: ';',
  NEW_LINE: '\n',
  ENTER: 'Enter',
  TAB: 'Tab',
};

export const FileExtensions = {
  CSV: '.csv',
};

export const firstPageNumber = 1; // we use 1 based pagination for routes and api

// https://en.wikipedia.org/wiki/List_of_UTC_time_offsets
export const utcTimeOffsetsZero = '00:00:00';
export const utcTimeOffsets = [
  '-12:00:00',
  '-11:00:00',
  '-10:00:00',
  '-09:30:00',
  '-09:00:00',
  '-08:00:00',
  '-07:00:00',
  '-06:00:00',
  '-05:00:00',
  '-04:00:00',
  '-03:30:00',
  '-03:00:00',
  '-02:00:00',
  '-01:00:00',
  utcTimeOffsetsZero,
  '01:00:00',
  '02:00:00',
  '03:00:00',
  '03:30:00',
  '04:00:00',
  '04:30:00',
  '05:00:00',
  '05:30:00',
  '05:45:00',
  '06:00:00',
  '06:30:00',
  '07:00:00',
  '08:00:00',
  '08:45:00',
  '09:00:00',
  '09:30:00',
  '10:00:00',
  '10:30:00',
  '11:00:00',
  '12:00:00',
  '12:45:00',
  '13:00:00',
  '14:00:00',
];
