import React from 'react';
import { useHistory } from 'react-router-dom';
import { firstPageNumber } from '../constants';

export const usePageChange = (
  template,
  pageNumberPlaceholder = ':pageNumber'
) => {
  const history = useHistory();

  const changeLocation = React.useCallback(
    number => {
      const pageNumber = number;
      const pathname = template.replace(pageNumberPlaceholder, pageNumber);
      const location = { pathname };
      history.push(location);
    },
    [history, template, pageNumberPlaceholder]
  );

  const goToFirstPage = React.useCallback(
    () => changeLocation(firstPageNumber),
    [changeLocation]
  );

  return [changeLocation, goToFirstPage];
};
