import { shouldLoadUnlessInStore } from './utils/index';
import { getHttpActionNames, buildHttpGetAction } from './utils/httpActions';
import { buildUri } from '../../services/buildUri';

export const RoleGroupsReadActionNames = getHttpActionNames('ROLE_GROUPS');

const fetchRoleGroups = () => dispatch => {
  const url = buildUri('roleGroups');
  const action = buildHttpGetAction(RoleGroupsReadActionNames, url);
  return dispatch(action);
};

const shouldLoadRoleGroups = state => {
  return shouldLoadUnlessInStore(state.roleGroups, 'roleGroups');
};

export const loadRoleGroupsIfNeeded = () => (dispatch, getState) => {
  if (shouldLoadRoleGroups(getState())) {
    fetchRoleGroups()(dispatch);
  }
};
