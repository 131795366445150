import React from 'react';
import { Trans } from '@lingui/macro';
import { useTenants } from '../../store/hooks/useTenants';
import AppLayout from '../AppLayout/AppLayout';
import AppHeader from '../AppHeader/AppHeader';
import { tenantsPathTemplate } from '../../routes/routesPaths';
import { useUrlPagination } from '../Common/utils/useUrlPagination';
import { Loading, TablePageContainer, TablePageFooter } from '../Common';
import TenantsTable from './TenantsTable/TenantsTable';
import TenantDetailDialog from './TenantDetailDialog/TenantDetailDialog';
import TenantCreateDialog from './TenantCreateDialog/TenantCreateDialog';
import TenantsActions from './TenantsActions/TenantsActions';

const Tenants = () => {
  const [tenantDetail, setTenantDetail] = React.useState(null);
  const [popupOpen, setPopupOpen] = React.useState(false);
  const [pageNumber, changePage, goToFirstPage] = useUrlPagination(
    tenantsPathTemplate
  );

  const [tenants, { sort, setSearchTerm, update, create }] = useTenants(
    pageNumber,
    goToFirstPage
  );

  const { page, isFetching, hasFilters } = tenants;

  const handleViewDetail = tenant => {
    setTenantDetail({ tenant, editMode: false });
  };

  const handleEdit = tenant => {
    setTenantDetail({ tenant, editMode: true });
  };

  const handleDetailClose = () => {
    setTenantDetail(null);
  };

  const handleDetailSubmit = tenant => {
    update(tenant);
    handleDetailClose();
  };

  const handleCreate = tenant => {
    setPopupOpen(false);
    create(tenant);
  };

  return (
    <AppLayout
      header={
        <AppHeader
          title={<Trans>Tenants</Trans>}
          actionsArea={<TenantsActions onAdd={() => setPopupOpen(true)} />}
        />
      }
    >
      {isFetching && <Loading />}
      <TablePageContainer minWidth="1400px">
        <TenantsTable
          tenants={tenants}
          onViewDetail={handleViewDetail}
          onSearchTermChange={setSearchTerm}
          onEdit={handleEdit}
          onSort={sort}
        />
        <TablePageFooter
          page={page}
          isFetching={isFetching}
          hasFilters={hasFilters}
          noItemsMessage={
            <Trans>There are no results matching the filters found.</Trans>
          }
          onPageChange={changePage}
        />
      </TablePageContainer>
      {tenantDetail && (
        <TenantDetailDialog
          tenant={tenantDetail.tenant}
          editMode={tenantDetail.editMode}
          onClose={handleDetailClose}
          onSubmit={handleDetailSubmit}
        />
      )}
      {popupOpen && (
        <TenantCreateDialog
          onClose={() => setPopupOpen(false)}
          onCreate={handleCreate}
        />
      )}
    </AppLayout>
  );
};

export default Tenants;
