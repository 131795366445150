import React from 'react';
import { Trans } from '@lingui/macro';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Description,
  DialogActions,
  Button,
  FixErrorsTooltip,
  FramedContainer,
  List,
  ListItem,
  Loading,
  IconButton,
  FormInputWithLength,
} from '../../../Common';
import { useConnections } from '../../../../store/hooks/useConnections';
import { validate } from './validators';
import { useValidation } from '../../../Common/Form/useValidation';
import { hasErrors } from '../../../../utils/index';
import { EditIcon } from '../../../../assets/index';

const ConnectionSelectDialog = ({ onClose, onContinue }) => {
  const { isFetching, connections } = useConnections();
  const [state, setState] = React.useState({});

  const [errors, touched, setTouched, containsActiveErrors] = useValidation(
    state,
    validate,
    {},
    {}
  );

  const handleConnectionChange = name => {
    setState(prev => {
      return {
        ...prev,
        connectionName: name,
      };
    });
  };

  const setAllTouched = () => {
    setTouched({ connectionName: true, description: true });
  };

  const handleContinue = () => {
    if (hasErrors(errors)) {
      setAllTouched();
    } else {
      onContinue(state);
    }
  };

  const handleCustomerReset = () => {
    setState({});
  };

  const handleDescriptionChange = description => {
    setState(prev => {
      return {
        ...prev,
        description,
      };
    });
  };

  const handleDescriptionTouched = () => {
    setTouched(prev => {
      return {
        ...prev,
        description: true,
      };
    });
  };

  return (
    <Dialog open size="sm">
      {isFetching && <Loading />}
      <DialogTitle onClose={onClose}>
        <Trans>Select associated connection</Trans>
      </DialogTitle>
      <DialogContent>
        {connections && !!connections.length && (
          <>
            <Description className="mb-2">
              <Trans>
                Select a connection to associate with created tenant.
              </Trans>
            </Description>
            {state.connectionName ? (
              <div className="d-flex justify-content-between">
                <div
                  className="mg-overflow-ellipsis mt-1"
                  title={state.connectionName}
                >
                  {state.connectionName}
                </div>
                <IconButton onClick={handleCustomerReset}>
                  <EditIcon />
                </IconButton>
              </div>
            ) : (
              <>
                <FramedContainer className="mt-2">
                  <List>
                    {connections &&
                      connections.length &&
                      connections.map(c => {
                        return (
                          <ListItem
                            key={c.name}
                            value={c.name}
                            title={c.name}
                            onClick={() => handleConnectionChange(c.name)}
                          >
                            {c.name}
                          </ListItem>
                        );
                      })}
                  </List>
                </FramedContainer>
                {touched.connectionName && errors.connectionName && (
                  <p className="mg-error-message mt-1 ml-3">
                    {errors.connectionName}
                  </p>
                )}
              </>
            )}
          </>
        )}
        {!isFetching && (!connections || !connections.length) && (
          <>
            <p className="mt-2 mg-body-1">
              <Trans>There are no available connections found.</Trans>
            </p>
            {touched.connectionName && errors.connectionName && (
              <p className="mg-error-message mt-2">{errors.connectionName}</p>
            )}
          </>
        )}
        {state && state.connectionName && (
          <>
            <Description className="my-2">
              <Trans>Provide a description of created tenant.</Trans>
            </Description>
            <FormInputWithLength
              className="mt-2"
              maxLength={200}
              multiline
              fullWidth
              label={<Trans>Description</Trans>}
              value={state.description}
              onChange={e => handleDescriptionChange(e.target.value)}
              error={touched.description && errors.description}
              onBlur={handleDescriptionTouched}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <Trans>Cancel</Trans>
        </Button>
        {connections && !!connections.length && (
          <FixErrorsTooltip show={containsActiveErrors}>
            <Button
              color="secondary"
              onClick={handleContinue}
              disabled={containsActiveErrors}
            >
              <Trans>Continue</Trans>
            </Button>
          </FixErrorsTooltip>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ConnectionSelectDialog;
