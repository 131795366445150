import React from 'react';
import Pagination from '../Pagination/Pagination';
import PaginationEntries from '../PaginationEntries/PaginationEntries';
import { getNumberOfPages } from '../../../utils/index';
import { firstPageNumber } from '../../../constants';

const PaginationFooter = ({ page = {}, onPageChanged }) => {
  const numberOfPages = getNumberOfPages(
    page.filteredItemsCount || 0,
    page.itemsPerPage || 1
  );
  return (
    numberOfPages > 1 && (
      <div className="mg-bg-white d-flex justify-content-between align-items-center p-3">
        <PaginationEntries page={page} />
        <Pagination
          pageCount={numberOfPages}
          pageChanged={onPageChanged}
          activePageNumber={page.pageNumber || firstPageNumber}
        />
      </div>
    )
  );
};

export default PaginationFooter;
