export const isNullOrEmpty = value => value == null || value === '';

export const trim = value =>
  isNullOrEmpty(value) ? '' : value.toString().trim();

export const trimChar = (string, charToRemove) => {
  let value = string;
  while (value.charAt(0) === charToRemove) {
    value = value.substring(1);
  }

  while (value.charAt(value.length - 1) === charToRemove) {
    value = value.substring(0, value.length - 1);
  }

  return value;
};
