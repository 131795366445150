import React from 'react';
import { t } from '@lingui/macro';
import { t8 } from '../../../i18n/i18n';
import { useValidation } from '../../Common/Form/useValidation';
import { validate } from './validators';
import { uniqueBy } from '../../../utils';

export const useEmailConfiguration = customerName => {
  const [email, setEmail] = React.useState({
    subject: '',
    emailAddresses: [],
  });

  React.useEffect(() => {
    setEmail(prev => {
      return {
        ...prev,
        subject: `${t8(t`Credentials for customer`)} ${customerName}`,
      };
    });
  }, [customerName]);

  const [errors, touched, setTouched, hasActiveErrors] = useValidation(
    email,
    validate,
    {},
    {}
  );

  const handleSubjectChange = event => {
    setEmail({ ...email, subject: event.target.value });
  };

  const handleSubjectBlur = () => {
    setTouched({ ...touched, subject: true });
  };

  const handleInputParsed = emailsToAdd => {
    setEmail({
      ...email,
      emailAddresses: uniqueBy(
        [...emailsToAdd.map(e => e.trim()), ...email.emailAddresses],
        e => e.toLowerCase()
      ),
    });
  };

  const handleEmailAddressBlur = () => {
    setTouched({ ...touched, emailAddresses: true });
  };

  const handleEmailAddressClear = emailAddress => {
    setEmail({
      ...email,
      emailAddresses: email.emailAddresses.filter(e => e !== emailAddress),
    });
  };

  const setAllTouched = () => {
    setTouched({ subject: true, emailAddresses: true });
  };

  return [
    { email, errors, touched, hasActiveErrors },
    {
      handleSubjectChange,
      handleSubjectBlur,
      handleInputParsed,
      handleEmailAddressClear,
      handleEmailAddressBlur,
      setAllTouched,
    },
  ];
};
