import React from 'react';
import { deepEqual } from '../../../utils/index';

const useDeepCompareMemoize = value => {
  const ref = React.useRef(value);

  if (!deepEqual(ref.current, value)) {
    ref.current = value;
  }

  return ref.current;
};

export const useDeepMemo = (factory, deps) => {
  const memo = React.useMemo(factory, useDeepCompareMemoize(deps));
  return memo;
};
