import React from 'react';
import { t } from '@lingui/macro';
import Input from '../Input';
import InputAdornment from '../InputAdornment/InputAdornment';
import { ZoomIcon, ClearIcon } from '../../../assets';
import IconButton from '../IconButton/IconButton';
import Popper from '../Popper';
import ClickAwayListener from '../ClickAwayListener';
import Paper from '../Paper';
import List from '../List';
import ListItem from '../ListItem';
import { KEY_CODES } from '../../../constants';
import { t8 } from '../../../i18n/i18n';
import Hint from '../Hint/Hint';

class SearchInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openState: false,
      displayNode: null,
      menuMinWidthState: 0,
      searchTermState: props.searchTerm,
    };
  }

  componentDidUpdate(prevProps) {
    const { searchTerm: prevSearchTerm } = prevProps;
    const { searchTerm } = this.props;
    if (searchTerm !== prevSearchTerm) {
      this.setSearchTermState(searchTerm);
      if (searchTerm) {
        this.setOpenState(false);
      }
    }
  }

  setSearchTermState = searchTermState => {
    this.setState({ searchTermState });
  };

  keepFocusInside = event => {
    event.preventDefault();
  };

  handleClickAway = event => {
    const { displayNode } = this.state;
    if (displayNode && displayNode.contains(event.target)) {
      return;
    }

    this.setOpenState(false);
  };

  setOpenState = openState => {
    this.setState(state => {
      const { displayNode } = state;
      const menuMinWidthState = displayNode ? displayNode.clientWidth : 0;
      return { openState, menuMinWidthState };
    });
  };

  handleMouseDown = () => {
    const { searchTerm, recentSearches } = this.props;
    if (!searchTerm && recentSearches && recentSearches.length) {
      this.setOpenState(true);
    }
  };

  handleItemClick = value => () => {
    this.setOpenState(false);
    this.setState({ searchTermState: value });
    this.search(value);

    const { onChange } = this.props;
    if (onChange) {
      onChange(value);
    }
  };

  handleChange = event => {
    const { onChange } = this.props;
    const { value } = event.target;
    if (onChange) {
      onChange(event.target.value);
    }

    this.setState({ searchTermState: value });

    if (!value) {
      this.resetSearchTerm();
    }

    if (value) {
      this.setOpenState(false);
    }
  };

  setDisplayNode = node => {
    this.setState({ displayNode: node });
  };

  handleSearch = () => {
    const { searchTermState } = this.state;
    this.search(searchTermState);
  };

  resetSearchTerm = () => {
    this.search('');
  };

  search = value => {
    const { onSearchClick } = this.props;
    if (onSearchClick) {
      onSearchClick(value || '');
    }
  };

  handleKeyUp = event => {
    event.persist();
    if (event.keyCode === KEY_CODES.ENTER) {
      this.handleSearch();
    }
  };

  render() {
    const {
      recentSearches = [],
      placeholder,
      searchTerm,
      className,
      info,
    } = this.props;
    const {
      displayNode,
      openState,
      menuMinWidthState,
      searchTermState,
    } = this.state;
    const open = displayNode !== null && openState;

    const showClearAction = searchTerm && searchTermState === searchTerm;

    const placeholderText = placeholder || t8(t`Search`);

    const { error } = this.props;

    return (
      <>
        <Input
          fullWidth
          className={className}
          placeholder={placeholderText}
          value={searchTermState || ''}
          onChange={this.handleChange}
          onFocus={this.handleFocus}
          onMouseDown={this.handleMouseDown}
          onKeyUp={this.handleKeyUp}
          ref={this.setDisplayNode}
          error={error}
          startAdornment={
            info && (
              <InputAdornment position="start">
                <Hint textAlignStart>{info}</Hint>
              </InputAdornment>
            )
          }
          endAdornment={
            <InputAdornment position="end">
              {showClearAction ? (
                <IconButton
                  onMouseDown={this.keepFocusInside}
                  onClick={this.resetSearchTerm}
                >
                  <ClearIcon />
                </IconButton>
              ) : (
                <IconButton
                  onMouseDown={this.keepFocusInside}
                  onClick={this.handleSearch}
                  disabled={!searchTermState}
                >
                  <ZoomIcon />
                </IconButton>
              )}
            </InputAdornment>
          }
        />
        <Popper
          className="mg-search-input-popper"
          open={open}
          anchorEl={displayNode}
          placement="bottom-start"
        >
          <ClickAwayListener onClickAway={this.handleClickAway}>
            <Paper
              style={{
                minWidth: menuMinWidthState,
              }}
            >
              <List>
                {recentSearches.map(rs => (
                  <ListItem key={rs} onClick={this.handleItemClick(rs)}>
                    {rs}
                  </ListItem>
                ))}
              </List>
            </Paper>
          </ClickAwayListener>
        </Popper>
      </>
    );
  }
}

export default SearchInput;
