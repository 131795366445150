import React from 'react';
import { Trans } from '@lingui/macro';
import {
  FormInputWithLength,
  Tooltip,
  InputAdornment,
  IconButton,
} from '../../Common';
import { ContentCopyIcon } from '../../../assets/index';
import { copyToClipboard } from '../../../utils';

const UserDetailRegularCredentials = ({
  userDetails,
  editMode,
  onDetailsChange,
  onDetailsTouched,
  detailsErrors,
  detailsTouched,
  validationPending,
}) => {
  return (
    <>
      <h5 className="mb-2">
        <Trans>User credentials</Trans>
      </h5>
      <FormInputWithLength
        fullWidth
        maxLength={100}
        label={<Trans>User name</Trans>}
        disabled={!editMode}
        value={userDetails.userName}
        onChange={e => onDetailsChange(e.target.value, 'userName')}
        onBlur={() => onDetailsTouched('userName')}
        error={detailsTouched.userName && detailsErrors.userName}
        endAdornment={
          <InputAdornment position="end">
            <Tooltip fullWidth title={<Trans>Copy to clipboard</Trans>}>
              <IconButton onClick={() => copyToClipboard(userDetails.userName)}>
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        }
      />
      <FormInputWithLength
        fullWidth
        className="mt-2"
        maxLength={100}
        label={<Trans>Email</Trans>}
        disabled={!editMode}
        value={userDetails.email}
        onChange={e => onDetailsChange(e.target.value, 'email')}
        onBlur={() => onDetailsTouched('email')}
        error={
          detailsTouched.email && !validationPending && detailsErrors.email
        }
        endAdornment={
          <InputAdornment position="end">
            <Tooltip fullWidth title={<Trans>Copy to clipboard</Trans>}>
              <IconButton onClick={() => copyToClipboard(userDetails.email)}>
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        }
      />
    </>
  );
};

export default UserDetailRegularCredentials;
