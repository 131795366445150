import React from 'react';
import './FormControlLabel.scss';
import clsx from 'clsx';

const FormControlLabel = ({ children, className, label, disabled }) => {
  return (
    // eslint-disable-next-line jsx-a11y/label-has-associated-control
    <label
      className={clsx(
        'mg-form-control-label-root',
        { 'mg-disabled': disabled },
        className
      )}
    >
      {React.cloneElement(children, { disabled })}
      <span className="mg-form-control-label-label">{label}</span>
    </label>
  );
};

export default FormControlLabel;
