import React, { useState, useEffect, useContext } from 'react';
import createAuth0Client from '@auth0/auth0-spa-js';
import { clearState } from '../services/localStorageService';

// A function that routes the user to the right place
// after login
const DEFAULT_REDIRECT_CALLBACK = appState => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const Auth0Context = React.createContext();

const useAuth0 = () => useContext(Auth0Context);

// eslint-disable-next-line import/no-mutable-exports
let getAccessToken = null;

const Auth0Provider = ({
  children,
  onRedirectCallback = DEFAULT_REDIRECT_CALLBACK,
  roleClaimType,
  oidClaimType,
  auth0Options,
  appMetadataClaimType,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initAuth0 = async () => {
      const auth0FromHook = await createAuth0Client({
        ...auth0Options,
        redirect_uri: window.location.origin,
      });
      setAuth0(auth0FromHook);

      if (
        window.location.search.includes('code=') &&
        window.location.search.includes('state=')
      ) {
        const { appState } = await auth0FromHook.handleRedirectCallback();
        onRedirectCallback(appState);
      }

      const authenticated = await auth0FromHook.isAuthenticated();

      setIsAuthenticated(authenticated);

      if (authenticated) {
        await Promise.all([
          auth0FromHook.getUser(),
          auth0FromHook.getIdTokenClaims(),
        ]).then(([u, data]) => {
          setUser({
            ...u,
            roles: data[roleClaimType],
            customerId:
              (data[appMetadataClaimType] &&
                data[appMetadataClaimType].customerId) ||
              null,
            profitId:
              (data[appMetadataClaimType] &&
                data[appMetadataClaimType].profit_id) ||
              u.name,
            oid: data[oidClaimType],
          });
        });
      }

      setLoading(false);
    };
    initAuth0();
  }, [
    appMetadataClaimType,
    auth0Options,
    oidClaimType,
    onRedirectCallback,
    roleClaimType,
  ]);

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const u = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(u);
  };

  const logout = () => {
    clearState();
    window.location.href = `https://${
      auth0Options.domain
    }/v2/logout?client_id=${
      auth0Options.client_id
    }&returnTo=${encodeURIComponent(window.location.origin)}`;
  };

  getAccessToken = (...p) => auth0Client.getTokenSilently(...p);

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        getAccessToken: (...p) => getAccessToken(...p),
        logout: (...p) => logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

export { useAuth0, Auth0Context, Auth0Provider, getAccessToken };
