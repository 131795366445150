export const Permission = {
  UsersCreate: 'users:create',
  UsersDelete: 'users:delete',
  UsersRotateSecret: 'users:rotateSecret',
  UsersSendCredentials: 'users:sendCredentials',
  UsersEdit: 'users:edit',
  UsersViewSecret: 'users:viewSecret',
  TenantsView: 'tenants:view',
  TenantsEdit: 'tenants:edit',
  TenantsCreate: 'tenants:create',
};
