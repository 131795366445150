import React from 'react';
import { Trans, t } from '@lingui/macro';
import {
  CheckBox,
  SearchInputWithOptions,
  Loading,
  TableSortLabel,
  TableClearFilters,
  SearchInput,
  ListItem,
  Tooltip,
  IconButton,
} from '../../../Common';
import { t8 } from '../../../../i18n/i18n';
import { useCustomers } from '../../../../store/hooks/useCustomers';
import { Permission } from '../../../../auth/app-permissions/permission';
import { Sorting } from '../../../../models/sorting';
import { FindInPageIcon } from '../../../../assets/index';
import { useRoles } from '../../../../store/hooks/useRoles';
import { useUserPermissions } from '../../../../auth/app-permissions/useUserPermissions';
import UserTypeFilterMenu from './UserTypeFilterMenu';

const UserTableHeader = ({
  users,
  tableSelection: [{ allSelected, indeterminate }, { allSelectedChange }],
  onSearchTermChange,
  onClearFilters,
  onSort,
}) => {
  const [userTypeSearchExpanded, setUserTypeSearchExpanded] = React.useState(
    false
  );
  const [roleSearchTerm, setRoleSearchTerm] = React.useState('');
  const anchorEl = React.useRef(null);
  const hasPermission = useUserPermissions();

  const columns = [
    {
      className: 'mg-col-name',
      label: t8(t`Name/ID`),
      sortingField: 'userName',
    },
    {
      className: 'mg-col-customer',
      label: t8(t`Customer`),
      sortingField: 'customer',
    },
  ];
  const {
    sortingDirection,
    sortingField,
    hasFilters,
    searchTerms,
    recentSearches,
  } = users;
  const [
    {
      customers,
      isFetching: isCustomerFetching,
      searchTerm: customerSearchTerm,
    },
    { setSearchTerm, reset },
  ] = useCustomers('userTable');
  const { isFetching, roles } = useRoles();

  const handleSelectAllChange = React.useCallback(
    event => allSelectedChange(event.target.checked),
    [allSelectedChange]
  );

  const handleSortingChange = column => {
    onSort({
      sortingField: column.sortingField,
      sortingDirection:
        column.sortingField === sortingField &&
        sortingDirection !== Sorting.DESC
          ? Sorting.DESC
          : Sorting.ASC,
    });
  };

  const handleClearFilters = () => {
    setRoleSearchTerm('');
    reset();
    onClearFilters();
  };

  const handleSearchTermChange = (searchField, searchValue) => {
    onSearchTermChange({ searchField, searchValue });
  };

  const handleSearch = searchValue => {
    setSearchTerm(searchValue);
  };

  const handleCustomerReset = () => {
    reset();
    handleSearchTermChange('customer', '');
  };

  const handleCustomerClick = e => {
    const customer = e.target.value;
    handleSearch(customer.name);
    handleSearchTermChange('customer', customer.salesforceAccountId);
  };

  const handleRoleSearch = v => {
    setRoleSearchTerm(v);
  };

  const handleRoleClick = e => {
    const role = e.target.value;
    handleRoleSearch(role.name);
    handleSearchTermChange('role', role.id);
  };

  const handleRoleReset = () => {
    handleSearchTermChange('role', '');
    setRoleSearchTerm('');
  };

  const handleUserTypeChange = userType => {
    setUserTypeSearchExpanded(false);
    handleSearchTermChange('userType', `${userType}`);
  };

  const filteredRoles = React.useMemo(() => {
    return (
      roles &&
      roles.length &&
      roles.filter(r =>
        r.name.toLowerCase().includes(roleSearchTerm.toLowerCase())
      )
    );
  }, [roleSearchTerm, roles]);

  return (
    <>
      {(isCustomerFetching || isFetching) && <Loading />}
      <thead>
        <tr>
          <th className="mg-col-checkbox mg-icon-cell">
            <CheckBox
              checked={allSelected}
              indeterminate={indeterminate}
              onChange={handleSelectAllChange}
              disabled={!hasPermission(Permission.UsersDelete)}
            />
          </th>
          <th className="mg-col-type">Type</th>
          {columns.map(column => (
            <th className={column.className} key={column.label}>
              <TableSortLabel
                active={
                  column.sortingField && sortingField === column.sortingField
                }
                direction={sortingDirection}
                onClick={() => handleSortingChange(column)}
              >
                <span className="mg-overflow-ellipsis" title={column.label}>
                  {column.label}
                </span>
              </TableSortLabel>
            </th>
          ))}
          <th className="mg-col-roles">
            <Trans>Roles</Trans>
          </th>
          <th className="mg-col-actions">
            <Trans>Actions</Trans>
          </th>
        </tr>
        <tr>
          <th className="mg-icon-cell">
            <TableClearFilters
              hasFilters={hasFilters}
              onClear={handleClearFilters}
            />
          </th>
          <th className="mg-col-type">
            <>
              <Tooltip
                placement="top"
                title={<Trans>Filter by user type</Trans>}
              >
                <IconButton
                  ref={anchorEl}
                  className="mg-table-clear-filters"
                  color="white"
                  onClick={() => {
                    setUserTypeSearchExpanded(true);
                  }}
                >
                  <FindInPageIcon />
                </IconButton>
              </Tooltip>
              <UserTypeFilterMenu
                anchorEl={anchorEl}
                open={userTypeSearchExpanded}
                onUserTypeChange={handleUserTypeChange}
                userType={
                  searchTerms.userType === undefined ||
                  searchTerms.userType === ''
                    ? null
                    : +searchTerms.userType
                }
                onClose={() => setUserTypeSearchExpanded(false)}
              />
            </>
          </th>
          <th>
            <SearchInput
              placeholder={t8(t`Enter Name/ID`)}
              searchTerm={searchTerms && searchTerms.userName}
              onSearchClick={value => handleSearchTermChange('userName', value)}
              recentSearches={recentSearches.userName}
            />
          </th>
          <th>
            <SearchInputWithOptions
              fullWidth
              placeholder={t8(t`Enter Customer`)}
              searchTerm={customerSearchTerm}
              onSearch={handleSearch}
              onClick={handleCustomerClick}
              onReset={handleCustomerReset}
            >
              {customers &&
                customers.length &&
                customers.map(entry => {
                  return (
                    <ListItem
                      key={entry.customerDetails.salesforceAccountId}
                      title={entry.customerDetails.name}
                      value={entry.customerDetails}
                    >
                      {entry.customerDetails.name}
                    </ListItem>
                  );
                })}
              {(!customers || !customers.length) &&
                (customerSearchTerm.length > 2 ? (
                  <ListItem title={t8(t`No customer found`)} disabled>
                    <Trans>No customer found</Trans>
                  </ListItem>
                ) : (
                  <ListItem
                    title={t8(t`Search must contain at least 3 symbols`)}
                    disabled
                  >
                    <Trans>Search must contain at least 3 symbols</Trans>
                  </ListItem>
                ))}
            </SearchInputWithOptions>
          </th>
          <th>
            <SearchInputWithOptions
              fullWidth
              placeholder={t8(t`Enter Role`)}
              searchTerm={roleSearchTerm}
              onSearch={handleRoleSearch}
              onClick={handleRoleClick}
              onReset={handleRoleReset}
            >
              {filteredRoles && filteredRoles.length ? (
                filteredRoles.map(entry => {
                  return (
                    <ListItem key={entry.id} title={entry.name} value={entry}>
                      {entry.name}
                    </ListItem>
                  );
                })
              ) : (
                <ListItem title={t8(t`No roles found`)} disabled>
                  <Trans>No roles found</Trans>
                </ListItem>
              )}
            </SearchInputWithOptions>
          </th>
          <th />
        </tr>
      </thead>
    </>
  );
};

export default UserTableHeader;
