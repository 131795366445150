import React from 'react';
import clsx from 'clsx';

const InputAdornment = props => {
  const {
    children,
    className,
    component: Component = 'div',
    position,
    ...other
  } = props;

  return (
    <Component
      className={clsx(
        'mg-input-adornment-root',
        {
          'mg-input-adornment-position-start': position === 'start',
          'mg-input-adornment-position-end': position === 'end',
        },
        className
      )}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {children}
    </Component>
  );
};

export default InputAdornment;
