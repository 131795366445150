import React from 'react';
import clsx from 'clsx';
import './CircularProgress.scss';

const SIZE = 44;

const CircularProgress = props => {
  const { className, size = 40, thickness = 3.6 } = props;

  return (
    <div
      className={clsx(
        'mg-circular-progress-root',
        'mg-circular-progress-indeterminate',
        className
      )}
      style={{ width: size, height: size }}
      role="progressbar"
    >
      <svg
        className="mg-circular-progress-svg"
        viewBox={`${SIZE / 2} ${SIZE / 2} ${SIZE} ${SIZE}`}
      >
        <circle
          className={clsx(
            'mg-circular-progress-circle',
            'mg-circular-progress-circle-indeterminate'
          )}
          cx={SIZE}
          cy={SIZE}
          r={(SIZE - thickness) / 2}
          fill="none"
          strokeWidth={thickness}
        />
      </svg>
    </div>
  );
};

export default CircularProgress;
