import { t } from '@lingui/macro';
import { isNullOrEmpty } from './core/string-utils';
import { filterUnique } from './core/array-utils';
import { containsTruthyValue, containTruthyValues } from './core/object-utils';
import { t8 } from '../i18n/i18n';
import { isValidDate } from './date-utils';

export const requiredMessage = prefix => t8(t`${prefix} is required.`);

export const isRequired = name => value => {
  return isNullOrEmpty(value) ? requiredMessage(name) : null;
};

const betweenMessage = (prefix, minValue, maxValue) =>
  t8(t`${prefix} must be between ${minValue} and ${maxValue}`);

export const isBetweenInclusiveValidation = name => (
  value,
  minValue,
  maxValue
) => {
  return value > maxValue || value < minValue
    ? betweenMessage(name, minValue, maxValue)
    : null;
};

export const hasErrors = errorObj => {
  return containsTruthyValue(errorObj, ['boolean', 'string']);
};

export const hasActiveErrors = (errorObj, touchedObj) => {
  return containTruthyValues(errorObj, touchedObj, ['boolean', 'string']);
};

export const getErrorList = (errors, touched, keys) => {
  return Object.keys({ ...errors })
    .filter(key => keys.includes(key))
    .filter(key => errors[key] && touched[key])
    .map(key => errors[key])
    .filter(filterUnique);
};

export const isDateRangeValid = ({ startDate, endDate }) => {
  return isValidDate(startDate) && isValidDate(endDate);
};

export const isDateRangeEmpty = ({ startDate, endDate }) => {
  return !startDate && !endDate;
};

export const isDateRangeValidOrEmpty = dateRange => {
  return isDateRangeValid(dateRange) || isDateRangeEmpty(dateRange);
};

export const validateDateRange = ({ startDate, endDate }) => {
  return !isDateRangeValidOrEmpty({ startDate, endDate });
};
