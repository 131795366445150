import React from 'react';
import clsx from 'clsx';
import './Pillow.scss';
import IconButton from '../IconButton/index';
import { ClearIcon } from '../../../assets/index';

const Pillow = ({
  className,
  children,
  color = 'light',
  onDelete,
  icon,
  title,
}) => {
  return (
    <div
      className={clsx(
        'mg-pillow',
        { [`mg-${color}`]: color !== 'light' },
        className
      )}
    >
      {icon}
      <span title={title} className="mg-pillow-text">
        {children}
      </span>
      {onDelete && (
        <IconButton
          color={color === 'error' ? 'white' : 'error'}
          size="small"
          className="ml-2"
          onClick={onDelete}
        >
          <ClearIcon />
        </IconButton>
      )}
    </div>
  );
};

export default Pillow;
