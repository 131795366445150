import React from 'react';
import groupBy from 'lodash/groupBy';
import max from 'lodash/max';
import { useFetchFromStore } from './utils/useFetchFromStore';
import { loadContracts } from '../actions';

export const useContracts = customerId => {
  const load = React.useCallback(() => loadContracts(customerId), [customerId]);

  const { contracts, isFetching } = useFetchFromStore(
    load,
    state => state.contracts
  );

  const contractsMemo = React.useMemo(() => contracts || [], [contracts]);

  const productCodes = React.useMemo(() => {
    const productEndDate = contractsMemo
      .map(c =>
        c.contractProducts.map(cp => {
          return {
            productCode: cp.productCode,
            contractEndDate: c.contractEndDate,
          };
        })
      )
      .flat();
    return Object.entries(groupBy(productEndDate, r => r.productCode)).map(
      grouped => {
        return {
          productCode: grouped[0],
          endDate: max(grouped[1].map(c => c.contractEndDate)),
        };
      }
    );
  }, [contractsMemo]);

  return [contractsMemo, isFetching, productCodes];
};
