import React from 'react';
import { t } from '@lingui/macro';
import { t8 } from '../../../i18n/i18n';
import TenantsTableHeader from './TenantsTableHeader/TenantsTableHeader';
import { Pillow, Tooltip, IconButton } from '../../Common';
import { Permission } from '../../../auth/app-permissions/permission';
import CanUser from '../../Common/CanUser/CanUser';
import { InfoIcon, EditIcon } from '../../../assets/index';
import './TenantsTable.scss';

const TenantsTable = ({
  tenants,
  onViewDetail,
  onEdit,
  onSort,
  onSearchTermChange,
}) => {
  const handleRowClick = React.useCallback(user => onViewDetail(user), [
    onViewDetail,
  ]);

  const handleEditClicked = tenant => {
    onEdit(tenant);
  };

  return (
    <div>
      <table className="mg-table mg-tenant-table mt-4">
        <TenantsTableHeader
          tenants={tenants}
          onSearchTermChange={onSearchTermChange}
          onSort={onSort}
        />
        <tbody>
          {(tenants.page.items || []).map(tenant => {
            return (
              <tr
                key={tenant.tenant.id}
                className="mg-cursor-pointer"
                onClick={() => handleRowClick(tenant)}
              >
                <td className="mg-overflow-ellipsis" title={tenant.tenant.name}>
                  {tenant.tenant.name}
                </td>
                <td
                  className="mg-overflow-ellipsis"
                  title={tenant.tenant.description}
                >
                  {tenant.tenant.description}
                </td>
                <td
                  className="mg-overflow-ellipsis"
                  title={tenant.customerDetails.name}
                >
                  {tenant.customerDetails.name}
                </td>
                <td>
                  {tenant.tenant.roles.slice(0, 2).map(r => (
                    <Pillow key={r.id} color="dark">
                      {r.name || r.id}
                    </Pillow>
                  ))}
                  {tenant.tenant.roles.length > 2 && (
                    <Tooltip
                      title={tenant.tenant.roles
                        .slice(2, tenant.tenant.roles.length)
                        .map(r => {
                          return <p key={r.id}>{r.name}</p>;
                        })}
                    >
                      <Pillow
                        key={0}
                        className="mg-border-gray-dark"
                        color="dark"
                      >
                        +{tenant.tenant.roles.length - 2}
                      </Pillow>
                    </Tooltip>
                  )}
                </td>
                <td>
                  <CanUser
                    permission={Permission.TenantsEdit}
                    no={
                      <Tooltip title={t8(t`View details`)}>
                        <IconButton className="mg-subscription-entry-actions">
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    }
                  >
                    <Tooltip title={t8(t`Edit tenant`)}>
                      <IconButton
                        className="mg-subscription-entry-actions"
                        onClick={e => {
                          e.stopPropagation();
                          handleEditClicked(tenant);
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </CanUser>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TenantsTable;
