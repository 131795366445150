import React from 'react';
import { isNullOrEmpty } from '../../../utils';

const getShrinked = (focused, value) => focused || !isNullOrEmpty(value);

export const useShrink = ([focused, value]) => {
  const [shrink, setShrink] = React.useState(getShrinked(focused, value));

  React.useEffect(() => {
    setShrink(getShrinked(focused, value));
  }, [focused, value]);

  return shrink;
};
