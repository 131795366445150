import React from 'react';
import { t } from '@lingui/macro';
import clsx from 'clsx';
import { t8 } from '../../../i18n/i18n';
import Input from '../Input';
import InputAdornment from '../InputAdornment/InputAdornment';
import IconButton from '../IconButton/IconButton';
import { ClearIcon, ZoomIcon } from '../../../assets/index';
import SelectBase from '../SelectBase/SelectBase';
import { KEY_CODES } from '../../../constants';
import ClickAwayListener from '../ClickAwayListener/ClickAwayListener';
import { isNullOrEmpty } from '../../../utils/index';

const SearchInputWithOptions = ({
  className,
  placeholder,
  searchTerm,
  error,
  container = null,
  value = '',
  onSearch,
  onReset,
  onClick,
  children,
  fullWidth,
}) => {
  const placeholderText = placeholder || t8(t`Search`);
  const [searchTermState, setSearchTermState] = React.useState('');
  const [displayNode, setDisplayNode] = React.useState();
  const [openState, setOpenState] = React.useState(false);
  const [menuWidth, setMenuWidth] = React.useState();
  React.useEffect(() => {
    setSearchTermState(searchTerm);
  }, [searchTerm]);

  const update = open => {
    if (open) {
      displayNode.focus();
    }

    setMenuWidth(displayNode.clientWidth);
    setOpenState(open);
  };

  const handleChange = e => {
    const term = e.target.value;
    if (isNullOrEmpty(term)) {
      onReset();
    }
    setSearchTermState(e.target.value);
  };

  const handleFocus = () => {
    update(true);
  };

  const handleBlur = () => {
    update(false);
  };

  const handleMouseDown = () => {};
  const handleKeyUp = e => {
    e.persist();
    if (e.keyCode === KEY_CODES.ENTER) {
      onSearch(searchTermState);
    }
  };

  const handleItemClick = child => event => {
    handleBlur();
    if (onClick) {
      const { value: selected } = child.props;
      event.persist();
      // eslint-disable-next-line no-param-reassign
      event.target = { value: selected };
      onClick(event, child);
    }
  };

  const handleItemMouseDown = event => {
    event.preventDefault();
  };

  const keepFocusInside = event => {
    event.preventDefault();
  };

  const resetSearchTerm = () => {
    onReset();
    setSearchTermState('');
  };

  const open = displayNode !== null && openState;
  const showClearAction = searchTerm && searchTermState === searchTerm;
  return (
    <ClickAwayListener onClickAway={handleBlur} mouseEvent="onMouseDown">
      <div
        className={clsx('mg-input-base-root', {
          'mg-full-width': fullWidth,
        })}
      >
        <Input
          fullWidth={fullWidth}
          className={className}
          placeholder={placeholderText}
          value={searchTermState || ''}
          onChange={handleChange}
          onFocus={handleFocus}
          onMouseDown={handleMouseDown}
          onKeyUp={handleKeyUp}
          ref={setDisplayNode}
          error={error}
          endAdornment={
            <InputAdornment position="end">
              {showClearAction ? (
                <IconButton
                  onMouseDown={keepFocusInside}
                  onClick={resetSearchTerm}
                >
                  <ClearIcon />
                </IconButton>
              ) : (
                <IconButton
                  onMouseDown={keepFocusInside}
                  onClick={() => {
                    handleFocus();
                    onSearch(searchTermState);
                  }}
                  disabled={!searchTermState}
                >
                  <ZoomIcon />
                </IconButton>
              )}
            </InputAdornment>
          }
        />
        <SelectBase
          open={open}
          displayNode={displayNode}
          container={container}
          onItemClick={handleItemClick}
          onClickAway={() => {}}
          menuWidth={menuWidth}
          value={value}
          onItemMouseDown={handleItemMouseDown}
        >
          {children}
        </SelectBase>
      </div>
    </ClickAwayListener>
  );
};

export default SearchInputWithOptions;
