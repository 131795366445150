import React from 'react';
import { Rifm } from 'rifm';
import Input from '../Input/Input';

const FormatInputBase = ({
  accept,
  mask,
  refuse,
  format = v => v,
  replace,
  value,
  onChange,
  maxLength,
  type = 'text',
  ...other
}) => {
  return (
    <Rifm
      accept={accept}
      mask={mask}
      format={format}
      replace={replace}
      value={value}
      onChange={onChange}
      refuse={refuse}
    >
      {({ onChange: onChangeProp, value: valueProp }) => (
        <Input
          fullWidth
          value={valueProp}
          onChange={onChangeProp}
          maxLength={maxLength}
          type={type}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...other}
        />
      )}
    </Rifm>
  );
};

export default FormatInputBase;
