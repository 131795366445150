import {
  CustomersReadActionNames,
  CUSTOMERS_SEARCH_TERM_CHANGE,
} from '../actions';

export const customers = (state = {}, action) => {
  switch (action.type) {
    case CustomersReadActionNames.REQUEST:
      return {
        ...state,
        [action.domain]: {
          ...state[action.domain],
          isFetching: true,
        },
      };
    case CustomersReadActionNames.SUCCESS:
      return {
        ...state,
        [action.domain]: {
          ...state[action.domain],
          isFetching: false,
          customers: action.response,
        },
      };
    case CustomersReadActionNames.FAILURE:
      return {
        ...state,
        [action.domain]: {
          ...state[action.domain],
          isFetching: false,
          customers: null,
        },
      };
    case CUSTOMERS_SEARCH_TERM_CHANGE:
      return {
        ...state,
        [action.domain]: {
          ...state[action.domain],
          searchTerm: action.searchTerm,
        },
      };
    default:
      return state;
  }
};
