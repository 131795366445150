import React from 'react';
import clsx from 'clsx';
import './Divider.scss';

const Divider = ({
  component: Component = 'hr',
  flexItem = true,
  orientation = 'vertical',
  color = 'bg',
  className,
}) => {
  return (
    <Component
      className={clsx(
        'mg-divider-root',
        {
          'mg-divider-flex-item': flexItem,
          'mg-divider-vertical': orientation === 'vertical',
          [`mg-bg-color-${color}`]: color !== 'bg',
        },
        className
      )}
    />
  );
};

export default Divider;
