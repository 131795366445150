import { callApi } from '../../services/callApi';

export const CALL_API = 'Call API';

export default () => next => async action => {
  const callAPI = action[CALL_API];
  if (typeof callAPI === 'undefined') {
    next(action);
    return;
  }

  const { types, endpoint, method, payload, id } = callAPI;

  const actionWith = data => {
    const finalAction = { ...action, ...data };
    delete finalAction[CALL_API];
    return finalAction;
  };

  const [requestType, success, failureType] = types;
  const successCallback =
    typeof success === 'object' ? success.callback : undefined;
  const successType = typeof success === 'object' ? success.type : success;
  next(actionWith({ type: requestType, payload, id }));

  try {
    const response = await callApi(endpoint, method, payload);
    next(
      actionWith({
        response,
        payload,
        id,
        type: successType,
      })
    );
    if (successCallback) {
      successCallback(response);
    }
  } catch (error) {
    next(
      actionWith({
        type: failureType,
        payload,
        id,
        error: error || 'Something bad happened',
      })
    );
  }
};
