import React from 'react';
import clsx from 'clsx';
import {
  CheckBoxIcon,
  CheckBoxOutlineIcon,
  CheckBoxIndeterminateIcon,
} from '../../../assets';

const CheckBox = ({
  className,
  checked,
  indeterminate,
  disabled = false,
  name,
  value,
  onChange,
  onClick,
}) => {
  const IconCheckedComponent = checked ? CheckBoxIcon : CheckBoxOutlineIcon;
  const IconComponent = indeterminate
    ? CheckBoxIndeterminateIcon
    : IconCheckedComponent;

  return (
    <span
      className={clsx(
        'mg-check-box-root p-2',
        {
          'mg-disabled': disabled,
          'mg-checked': checked,
          'mg-indeterminate': indeterminate,
        },
        className
      )}
    >
      <span className="mg-check-box-label">
        <input
          disabled={disabled}
          className="mg-check-box-input"
          type="checkbox"
          checked={checked}
          name={name}
          value={value}
          onChange={onChange}
          onClick={onClick}
        />
        <IconComponent className="mg-check-box-svg" />
      </span>
    </span>
  );
};

export default React.memo(CheckBox);
