import React from 'react';
import { Trans } from '@lingui/macro';
import LinkButton from '../../Common/LinkButton/LinkButton';
import { supportEmail } from '../../../config';

const ErrorPage = ({ message }) => {
  return (
    <div className="d-flex flex-column justify-content-center align-items-center m-5">
      <h1 className="m-5">༼ つ ◕_◕ ༽つ</h1>
      <h1 className="m-2">{message}</h1>
      <p>
        <Trans>
          Please, try{' '}
          <LinkButton onClick={() => window.location.replace('/')}>
            reloading
          </LinkButton>{' '}
          the application or contact&nbsp;
          <a className="mg-link" href={`mailto:${supportEmail}`}>
            {supportEmail}
          </a>
        </Trans>
      </p>
    </div>
  );
};

export default ErrorPage;
