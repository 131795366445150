import { Language } from '../../i18n/language';

export const locale = (
  state = {
    language: Language.EN,
  },
  action
) => {
  switch (action.type) {
    default:
      return state;
  }
};
