export const checkPermissions = (rules, role, permission, data, user) => {
  const userPermissions = rules[role];
  if (!userPermissions) {
    return false;
  }

  const staticPermissions = userPermissions.static;

  if (staticPermissions && staticPermissions.has(permission)) {
    return true;
  }

  const dynamicPermissions = userPermissions.dynamic;

  if (dynamicPermissions) {
    const permissionCondition = dynamicPermissions[permission];
    if (!permissionCondition) {
      return false;
    }

    return permissionCondition(data, user);
  }
  return false;
};
