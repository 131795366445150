import React from 'react';
import { useFetchFromStore } from './utils/useFetchFromStore';
import { loadCustomers, setCustomersSearchTerm } from '../actions';
import { useThunkDispatch } from './utils/useThunkDispatch';

export const useCustomers = (domain = 'default') => {
  const dispatch = useThunkDispatch();
  const load = React.useCallback(() => loadCustomers(domain), [domain]);

  const { customers, isFetching, searchTerm } = useFetchFromStore(
    load,
    state => state.customers[domain] || {}
  );

  const setSearchTerm = React.useCallback(
    search => {
      dispatch(setCustomersSearchTerm(search, domain));
      dispatch(load());
    },
    [dispatch, domain, load]
  );

  const reset = React.useCallback(() => {
    dispatch(setCustomersSearchTerm('', domain));
    dispatch(load());
  }, [dispatch, domain, load]);

  return [
    {
      customers: customers || [],
      isFetching: isFetching || false,
      searchTerm: searchTerm || '',
    },
    { setSearchTerm, reset },
  ];
};
