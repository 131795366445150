import React from 'react';
import { Trans } from '@lingui/macro';
import './VesselInfo.scss';
import { IconButton } from '../../../Common';
import { ClearIcon } from '../../../../assets/index';

const VesselInfo = ({ vessel, onDelete }) => {
  return (
    <div className="d-flex mg-vessel-frame justify-content-between p-1">
      <div>
        <p>
          <Trans>IMO</Trans>: {vessel.imo}
        </p>
        <p>
          <Trans>MMSI</Trans>: {vessel.mmsi}
        </p>
      </div>
      {onDelete && (
        <div>
          <IconButton size="small" onClick={onDelete}>
            <ClearIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default VesselInfo;
