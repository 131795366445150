import React from 'react';
import { Trans } from '@lingui/macro';
import { toast } from 'react-toastify';
import { ToastMessage } from '../Common';
import { useSendEmail } from './useSendEmail';

export const useEmailNotification = () => {
  const [isEmailSending, setIsEmailSending] = React.useState(false);
  const [
    sendEmail,
    sendInvitation,
    sendEmailChangedNotification,
  ] = useSendEmail();

  const handleEmailSend = (email, userId, userCreated = false) => {
    setIsEmailSending(true);
    sendEmail(
      userId,
      email,
      () => {
        toast(
          <ToastMessage
            text={
              userCreated ? (
                <Trans>Machine user is created and email is sent</Trans>
              ) : (
                <Trans>Email is sent</Trans>
              )
            }
          />
        );
        setIsEmailSending(false);
      },
      () => {
        toast(
          <ToastMessage
            color="error"
            text={<Trans>Error while sending email</Trans>}
          />
        );
        setIsEmailSending(false);
      }
    );
  };

  const handleInvitationSend = emailAddress => {
    setIsEmailSending(true);
    sendInvitation(
      emailAddress,
      () => {
        toast(
          <ToastMessage
            text={<Trans>User is created and invitation is sent</Trans>}
          />
        );
        setIsEmailSending(false);
      },
      () => {
        toast(
          <ToastMessage
            color="error"
            text={<Trans>Error while sending invitation</Trans>}
          />
        );
        setIsEmailSending(false);
      }
    );
  };

  const handleEmailChangedNotificationSend = emailAddress => {
    setIsEmailSending(true);
    sendEmailChangedNotification(
      emailAddress,
      () => {
        toast(
          <ToastMessage
            text={<Trans>Email changed notification is sent</Trans>}
          />
        );
        setIsEmailSending(false);
      },
      () => {
        toast(
          <ToastMessage
            color="error"
            text={<Trans>Error while sending notification</Trans>}
          />
        );
        setIsEmailSending(false);
      }
    );
  };
  return [
    { isEmailSending },
    {
      onEmailSend: handleEmailSend,
      onInvitationSend: handleInvitationSend,
      onEmailChangedNotificationSend: handleEmailChangedNotificationSend,
    },
  ];
};
