import React from 'react';
import clsx from 'clsx';

const IconButton = React.forwardRef(
  (
    {
      className,
      onClick,
      children,
      onMouseDown,
      disabled,
      size = 'medium',
      color = 'primary',
    },
    ref
  ) => {
    return (
      <button
        disabled={disabled}
        type="button"
        className={clsx(
          'mg-icon-button-root',
          {
            'mg-disabled': disabled,
            [`mg-size-${size}`]: size !== 'medium',
            [`mg-color-${color}`]: color !== 'primary',
          },
          className
        )}
        onClick={onClick}
        onMouseDown={onMouseDown}
        ref={ref}
      >
        {children}
      </button>
    );
  }
);

export default IconButton;
