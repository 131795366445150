import React from 'react';
import { Trans } from '@lingui/macro';
import Tooltip from '../Tooltip/index';

const FixErrorsTooltip = ({ className, children, show }) => {
  return (
    <Tooltip
      className={className}
      title={show && <Trans>Please, correct errors.</Trans>}
    >
      {children}
    </Tooltip>
  );
};

export default FixErrorsTooltip;
