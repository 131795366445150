import React from 'react';
import { useSelector } from 'react-redux';
import {
  loadTenants,
  setTenantsSorting,
  setTenantsSearchTerm,
  updateTenant,
  createTenant,
} from '../actions/index';
import { useThunkDispatch } from './utils/useThunkDispatch';
import { useFetchPage } from './utils/useFetchPage';

export const useTenants = (pageNumber, goToFirstPage) => {
  const dispatch = useThunkDispatch();
  const [fetch, goToFirstPageAndFetch] = useFetchPage(
    dispatch,
    loadTenants,
    pageNumber,
    goToFirstPage
  );

  const tenants = useSelector(store => store.tenants);

  const sort = React.useCallback(
    sorting => {
      dispatch(setTenantsSorting(sorting));
      fetch();
    },
    [dispatch, fetch]
  );

  const setSearchTerm = React.useCallback(
    searchTerm => {
      const previousValue = tenants.searchTerms[searchTerm.searchField] || '';
      if (searchTerm.searchValue !== previousValue) {
        dispatch(setTenantsSearchTerm(searchTerm));
        goToFirstPageAndFetch();
      }
    },
    [dispatch, goToFirstPageAndFetch, tenants.searchTerms]
  );

  const update = React.useCallback(
    (tenant, onSuccess) =>
      dispatch(
        updateTenant(tenant, () => {
          fetch();
          if (onSuccess) {
            onSuccess();
          }
        })
      ),
    [dispatch, fetch]
  );

  const create = React.useCallback(
    (tenant, onSuccess) =>
      dispatch(
        createTenant(tenant, () => {
          fetch();
          if (onSuccess) {
            onSuccess();
          }
        })
      ),
    [dispatch, fetch]
  );

  return [{ ...tenants }, { sort, setSearchTerm, update, create }];
};
