import {
  TenantsReadActionNames,
  TENANTS_SORTING_CHANGE,
  TENANTS_SEARCH_TERM_CHANGE,
  TenantsUpdateActionNames,
  TenantsCreateActionNames,
} from '../actions';
import { Sorting } from '../../models/sorting';
import { getRecentSearches } from './utils/index';

export const tenants = (
  state = {
    isFetching: false,
    page: { items: [] },
    searchTerms: {},
    recentSearches: [],
    sortingField: 'customer',
    sortingDirection: Sorting.ASC,
  },
  action
) => {
  switch (action.type) {
    case TenantsReadActionNames.REQUEST:
      return { ...state, isFetching: true };
    case TenantsReadActionNames.SUCCESS:
      return {
        ...state,
        isFetching: false,
        page: action.response,
      };
    case TenantsReadActionNames.FAILURE:
      return {
        ...state,
        isFetching: false,
        page: {},
      };
    case TENANTS_SORTING_CHANGE:
      return {
        ...state,
        sortingField: action.sorting.sortingField,
        sortingDirection: action.sorting.sortingDirection,
      };
    case TENANTS_SEARCH_TERM_CHANGE:
      return {
        ...state,
        searchTerms: {
          ...state.searchTerms,
          [action.searchTerm.searchField]: action.searchTerm.searchValue.trim(),
        },
        recentSearches: getRecentSearches(state, action),
      };
    case TenantsUpdateActionNames.SUCCESS:
    case TenantsUpdateActionNames.FAILURE:
    case TenantsCreateActionNames.SUCCESS:
    case TenantsCreateActionNames.FAILURE:
      return { ...state, isFetching: false };
    case TenantsUpdateActionNames.REQUEST:
    case TenantsCreateActionNames.REQUEST:
      return { ...state, isFetching: true };
    default:
      return state;
  }
};
