import React from 'react';
import FixErrorsTooltip from '../FixErrorsTooltip/FixErrorsTooltip';
import Button from '../Button/index';

const FormSubmitButton = ({ className, disabled, onClick, children }) => {
  return (
    <FixErrorsTooltip show={disabled} className={className}>
      <Button disabled={disabled} color="secondary" onClick={onClick}>
        {children}
      </Button>
    </FixErrorsTooltip>
  );
};

export default FormSubmitButton;
