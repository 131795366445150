import React from 'react';
import clsx from 'clsx';

const ListItem = ({
  children,
  className,
  onClick,
  disabled,
  title,
  selected,
  size = 'medium',
}) => {
  const handleClick = event => {
    if (disabled) {
      return;
    }
    if (onClick) {
      onClick(event);
    }
  };
  return (
    <li
      role="presentation"
      className={clsx(
        'mg-list-item-root',
        'mg-list-item-button',
        'mg-menu-item-root',
        `mg-size-${size}`,
        { 'mg-disabled': disabled, 'mg-selected': selected },
        className
      )}
      onClick={handleClick}
      onKeyDown={() => {}}
      title={title}
    >
      {children}
    </li>
  );
};

export default ListItem;
