import React from 'react';
import { Trans } from '@lingui/macro';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Loading,
  FixErrorsTooltip,
  CanUser,
} from '../../Common';
import { UserType } from '../../../models/user-type';
import { useAsyncValidation } from '../../Common/Form/useValidation';
import { validateUserDetails } from '../UserCreateDialog/UserInfoDialog/validators';
import { hasErrors } from '../../../utils/index';
import { Permission } from '../../../auth/app-permissions/permission';
import UserDetailsRolesEdit from './UserDetailsRolesEdit';
import UserDetailsRolesView from './UserDetailsRolesView';
import UserDetailMachineCredentials from './UserDetailMachineCredentials';
import UserDetailRegularCredentials from './UserDetailRegularCredentials';
import { useDeepMemo } from '../../Common/utils/useDeepMemo';
import UserDetailExternalCredentials from './UserDetailExternalCredentials';
import { useContracts } from '../../../store/hooks/useContracts';
import { useRoleSelection } from '../useRoleSelection';

const UserDetailDialog = ({
  user,
  onClose,
  onSubmit,
  editMode: editModeProp = false,
}) => {
  const [editMode, setEditMode] = React.useState(false);

  const [
    {
      isFetching,
      roles,
      selectedRoles,
      adminRoleId,
      vesselInsights,
      vesselInsightsRoleSelected,
      vesselInsightsModel,
      vesselInsightsErrors,
      errors,
      touched,
      containsActiveErrors,
    },
    { onRoleChange, setAllTouched },
  ] = useRoleSelection(
    user.user.roles,
    user.customerDetails.salesforceAccountId
  );

  const [, isContractsFetching, productCodes] = useContracts(
    user.customerDetails.salesforceAccountId
  );

  const [userDetails, setUserDetails] = React.useState({
    email: '',
    userName: '',
  });

  const userDetailsMemo = useDeepMemo(() => userDetails, [userDetails]);
  const [
    detailsErrors,
    detailsTouched,
    detailsSetTouched,
    detailsContainsActiveErrors,
    validationPending,
  ] = useAsyncValidation(userDetailsMemo, validateUserDetails, {}, {});

  React.useEffect(() => {
    setEditMode(editModeProp);
  }, [editModeProp]);

  const setDialogAllTouched = () => {
    setAllTouched();
    detailsSetTouched({
      email: true,
      userName: true,
    });
  };

  React.useEffect(() => {
    setUserDetails({
      email: user.user.userInfo && user.user.userInfo.emailAddress,
      userName: user.user.userName,
      initialEmail: user.user.userInfo && user.user.userInfo.emailAddress,
    });
  }, [user.user]);

  const handleDetailsChange = (value, prop) => {
    setUserDetails(prev => {
      return {
        ...prev,
        [prop]: value,
      };
    });
  };

  const handleDetailsTouched = prop => {
    detailsSetTouched({
      ...detailsTouched,
      [prop]: true,
    });
  };

  const handleSubmit = () => {
    if (
      hasErrors(errors) ||
      (user.user.userType === UserType.REGULAR && hasErrors(detailsErrors)) ||
      (vesselInsightsRoleSelected && hasErrors(vesselInsightsErrors))
    ) {
      setDialogAllTouched();
    } else {
      onSubmit(
        {
          id: user.user.id,
          roles: selectedRoles,
          userName: userDetails.userName,
          email: userDetails.email,
          vesselInsights: vesselInsightsRoleSelected
            ? vesselInsightsModel
            : null,
        },
        user.user.userType === UserType.REGULAR &&
          userDetails.email !== userDetails.initialEmail
      );
    }
  };

  const handleEdit = () => {
    setEditMode(true);
  };

  return (
    <Dialog open>
      {(isFetching || isContractsFetching) && <Loading />}
      <DialogTitle onClose={onClose}>
        {editMode ? (
          <Trans>Edit user details</Trans>
        ) : (
          <Trans>User details</Trans>
        )}
      </DialogTitle>
      <DialogContent>
        <div>
          <h5 className="mb-2">
            <Trans>Customer name</Trans>
          </h5>
          <p className="mg-body-2 mb-2">{user.customerDetails.name}</p>
        </div>
        {user.user.userType === UserType.MACHINE && (
          <UserDetailMachineCredentials user={user} />
        )}
        {user.user.userType === UserType.REGULAR && (
          <UserDetailRegularCredentials
            userDetails={userDetails}
            editMode={editMode}
            detailsErrors={detailsErrors}
            detailsTouched={detailsTouched}
            validationPending={validationPending}
            onDetailsChange={handleDetailsChange}
            onDetailsTouched={handleDetailsTouched}
          />
        )}
        {user.user.userType === UserType.EXTERNAL && (
          <UserDetailExternalCredentials userDetails={userDetails} />
        )}
        <h5 className="mb-2">
          {editMode ? (
            <Trans>Select assigned roles</Trans>
          ) : (
            <Trans>Assigned roles</Trans>
          )}
        </h5>
        {touched.selectedRoles && errors.selectedRoles && (
          <p className="mg-error-message mt-1">{errors.selectedRoles}</p>
        )}
        {editMode ? (
          <UserDetailsRolesEdit
            adminRoleId={adminRoleId}
            selectedRoles={selectedRoles}
            roles={roles}
            vesselInsightsRoleSelected={vesselInsightsRoleSelected}
            onRoleChange={onRoleChange}
            vesselInsights={vesselInsights}
            initialVesselInsights={user.user.vesselInsights}
            productCodes={productCodes}
          />
        ) : (
          <UserDetailsRolesView
            selectedRoles={selectedRoles}
            roles={roles}
            vesselInsightsRoleSelected={vesselInsightsRoleSelected}
            vesselInsights={user.user.vesselInsights}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {editMode ? <Trans>Cancel</Trans> : <Trans>Close</Trans>}
        </Button>
        {editMode ? (
          <FixErrorsTooltip
            show={
              containsActiveErrors ||
              (user.user.userType === UserType.REGULAR &&
                detailsContainsActiveErrors)
            }
          >
            <Button
              color="secondary"
              onClick={handleSubmit}
              disabled={
                containsActiveErrors ||
                (user.user.userType === UserType.REGULAR &&
                  detailsContainsActiveErrors)
              }
            >
              <Trans>Update</Trans>
            </Button>
          </FixErrorsTooltip>
        ) : (
          <CanUser permission={Permission.UsersEdit} data={user.user.oid}>
            <Button color="secondary" onClick={handleEdit}>
              <Trans>Edit</Trans>
            </Button>
          </CanUser>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default UserDetailDialog;
