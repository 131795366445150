import React from 'react';
import PaginationFooter from '../PaginationFooter/PaginationFooter';
import EmptyMessage from '../EmptyMessage/EmptyMessage';
import NoMatchesMessage from '../NoMatchesMessage/NoMatchesMessage';

const TablePageFooter = ({
  page,
  isFetching,
  hasFilters,
  noItemsMessage,
  onPageChange,
}) => {
  const hasItems = page.items && page.items.length > 0;
  return (
    <>
      {hasItems && (
        <PaginationFooter page={page} onPageChanged={onPageChange} />
      )}
      {!hasItems &&
        !isFetching &&
        (hasFilters ? (
          <NoMatchesMessage />
        ) : (
          <EmptyMessage>{noItemsMessage}</EmptyMessage>
        ))}
    </>
  );
};

export default TablePageFooter;
