export const titleBase = 'Shipping Content Services';

export const supportEmail = 'shippingsupport@dtn.com';

export const defaultItemsPerPage = 24;
export const defaultTableItemsPerPage = 10;

export const LOCAL_STORAGE_STATE_KEY = '__USER_MANAGEMENT_STATE__';
export const saveStateAtMostOncePerMs = 1000;

export const recentSearchesMaxCount = 4;

// make sure to keep these in sync
// with $grid-breakpoints variable in src\styles\bootstrap\_variables.scss
export const mediaQueries = {
  sm: '(min-width: 480px)',
  md: '(min-width: 720px)',
  lg: '(min-width: 1024px)',
  xl: '(min-width: 1280px)',
};

export const ChartColors = {
  Black: '#000000',
  Red: '#da3b01',
  Yellow: '#fce100',
  Green: '#8cbd18',
  DarkGreen: '#0b6a0b',
  Aqua: '#00b7c3',
  Blue: '#0078d4',
  DeepBlue: '#004e8c',
  Purple: '#5c2e91',
  Pink: '#e3008c',
  DeepPink: '#9b0062',
};

// make sure to keep these in sync
// with variables in src\styles\utils\_colors.scss
export const Colors = {
  PrimaryDark: '#102840',
  GrayDark: '#707a8a',
  White: '#ffffff',
};
