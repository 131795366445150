import isEqual from 'lodash/isEqual';
import { filterUnique } from './array-utils';

export const containsTruthyValue = (
  obj,
  truthyTypes = ['boolean', 'string']
) => {
  const isArray = Array.isArray(obj);
  const isObject = !isArray && typeof obj === 'object';
  const type = typeof obj;
  const isTruthyType = truthyTypes.some(t => type === t) || obj == null;

  if (isTruthyType) {
    return !!obj;
  }

  if (isObject) {
    return Object.keys(obj).some(key =>
      containsTruthyValue(obj[key], truthyTypes)
    );
  }

  if (isArray) {
    return obj.some(item => containsTruthyValue(item, truthyTypes));
  }

  return false;
};

export const containTruthyValues = (obj1, obj2, truthyTypes = ['boolean']) => {
  const isArray = Array.isArray(obj1);
  const isObject = !isArray && typeof obj1 === 'object';
  const type = typeof obj1;
  const isTruthyType = truthyTypes.some(t => type === t) || obj1 == null;

  if (isTruthyType) {
    return !!obj1 && !!obj2;
  }

  if (isObject) {
    return Object.keys(obj1).some(
      key => obj2 && containTruthyValues(obj1[key], obj2[key], truthyTypes)
    );
  }

  if (isArray) {
    return obj1.some(
      (item, i) => obj2 && containTruthyValues(item, obj2[i], truthyTypes)
    );
  }

  return false;
};

export const deepEqual = (obj1, obj2) => {
  return isEqual(obj1, obj2);
  // Debug only: Uncomment the following to find the difference
  // return isEqualWith(obj1, obj2, (a, b) => {
  //   if (!isEqualWith(a, b)) {
  //     console.log('difference', a, b);
  //   }
  // });
};

export const getChangedProps = (changedObj, initialObj) => {
  return Object.keys(changedObj).reduce((prev, curr) => {
    if (!deepEqual(changedObj[curr], initialObj[curr])) {
      return {
        ...prev,
        [curr]: changedObj[curr],
      };
    }
    return prev;
  }, {});
};

export const getUniqueValues = map => Object.values(map).filter(filterUnique);

export const omit = (obj, keys) => {
  const strKeys = keys.map(k => k.toString());
  return Object.keys(obj).reduce((acc, curr) => {
    if (!strKeys.includes(curr)) {
      acc[curr] = obj[curr];
    }
    return acc;
  }, {});
};

export const isEmptyObject = obj => {
  if (obj == null) {
    return true;
  }
  return Object.keys(obj).length === 0;
};

export const nullIfUndefined = value => {
  return value == null ? null : value;
};

export const getPropByPath = (obj, path) => {
  const keys = path.split('.');

  let o = obj;
  for (let i = 0; i < keys.length; i += 1) {
    if (o == null) {
      return o;
    }
    o = o[keys[i]];
  }

  return o;
};

export const areEqualValues = (a, b) => {
  if (typeof b === 'object' && b !== null) {
    return a === b;
  }

  return String(a) === String(b);
};
