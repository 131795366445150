import React from 'react';
import clsx from 'clsx';

const FramedContainer = ({ className, children }) => {
  return (
    <div className={clsx('mg-framed-container', className)}>{children}</div>
  );
};

export default FramedContainer;
