import React from 'react';

const DialogActions = ({ children }) => {
  return (
    <div className="mg-dialog-actions-root mg-dialog-actions-spacing d-flex align-items-center justify-content-end px-4 py-3">
      {children}
    </div>
  );
};

export default DialogActions;
