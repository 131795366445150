import React from 'react';
import { omit, reduceToObject } from '../../../utils/index';

export const useTableSelection = (
  items,
  initSelected = {},
  idSelector,
  valueSelector
) => {
  const getId = React.useCallback(
    item => (idSelector ? idSelector(item) : item.id),
    [idSelector]
  );

  const getValue = React.useCallback(
    item => (valueSelector ? valueSelector(item) : item),
    [valueSelector]
  );

  const [selected, setSelected] = React.useState(initSelected);

  React.useEffect(() => setSelected({}), [items]);

  const allSelected = React.useMemo(() => {
    return !!(items && items.length && items.every(r => selected[r.id]));
  }, [items, selected]);

  const selectedChange = React.useCallback(
    item =>
      setSelected(currSelected => {
        const checked = !currSelected[getId(item)];
        return checked
          ? {
              ...currSelected,
              [getId(item)]: getValue(item),
            }
          : omit(currSelected, [getId(item)]);
      }),
    [getId, getValue]
  );

  const allSelectedChange = React.useCallback(
    checked =>
      setSelected(checked ? reduceToObject(items || [], getId, getValue) : {}),
    [items, getId, getValue]
  );

  const selectedIds = React.useMemo(() => Object.keys(selected), [selected]);

  const indeterminate = React.useMemo(
    () => selectedIds.length > 0 && !allSelected,
    [allSelected, selectedIds.length]
  );

  return [
    { selected, allSelected, selectedIds, indeterminate },
    { selectedChange, allSelectedChange, setSelected },
  ];
};
