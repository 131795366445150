import React from 'react';
import clsx from 'clsx';
import { GenericUserImage } from '../../../assets/index';

const Avatar = ({ className, pictureUrl, alt }) => {
  return (
    <div className={clsx('mg-avatar', className)}>
      <img
        className="mg-avatar-image"
        src={pictureUrl || GenericUserImage}
        alt={alt}
      />
    </div>
  );
};

export default Avatar;
