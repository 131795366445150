// see how to make more advanced configurations:
// https://auth0.com/blog/role-based-access-control-rbac-and-react-apps/#Role-Based-Access-Control-Example-in-React-Apps

import { Permission } from './permission';
import { Role } from './role';
import { isNotSelf } from './permission-utils';

const adminStaticPermissions = new Set([
  Permission.UsersCreate,
  Permission.UsersRotateSecret,
  Permission.UsersSendCredentials,
  Permission.UsersViewSecret,
  Permission.TenantsView,
  Permission.TenantsEdit,
  Permission.TenantsCreate,
]);

const rules = {
  [Role.Admin]: {
    static: adminStaticPermissions,
    dynamic: {
      [Permission.UsersEdit]: isNotSelf,
      [Permission.UsersDelete]: isNotSelf,
    },
  },
  [Role.UserAdmin]: {
    static: adminStaticPermissions,
    dynamic: {
      [Permission.UsersEdit]: isNotSelf,
      [Permission.UsersDelete]: isNotSelf,
    },
  },
};

export default rules;
