import React from 'react';
import clsx from 'clsx';

const List = props => {
  const { children, className, component: Component = 'ul', ...other } = props;

  return (
    <Component
      className={clsx('mg-list-root', 'mg-list-padding', className)}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    >
      {children}
    </Component>
  );
};

export default List;
