import React from 'react';
import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';
import { LogoOffshoreForecastsIcon } from '../../assets';
import Nav from './Nav/Nav';
import NavTab from './NavTab/NavTab';
import './AppBar.scss';
import UserProfile from './UserProfile/UserProfile';
import CanUser from '../Common/CanUser/CanUser';
import { Permission } from '../../auth/app-permissions/permission';

const AppBar = () => {
  return (
    <div className="mg-app-bar d-flex no-gutters flex-shrink-0 justify-content-between align-items-center px-4">
      <div className="col-xl-4">
        <Link to="/" className="mg-link">
          <LogoOffshoreForecastsIcon />
        </Link>
      </div>
      <div className="col-xl-4 d-flex justify-content-center">
        <Nav>
          <NavTab to="/users">
            <Trans>Users</Trans>
          </NavTab>
          <CanUser permission={Permission.TenantsView}>
            <NavTab to="/tenants">
              <Trans>Tenants</Trans>
            </NavTab>
          </CanUser>
        </Nav>
      </div>
      <div className="col-xl-4 d-flex justify-content-end">
        <UserProfile />
      </div>
    </div>
  );
};

export default AppBar;
