export const roundTo = (value, decimals) => {
  return Math.round(value * 10 ** decimals) / 10 ** decimals;
};

export const abs = value => Math.abs(value);

export const tryGetNumber = value => {
  const number = Number(value);
  return Number.isNaN(number) ? value : number;
};

export const isNumber = value => {
  const number = Number(value);
  return !Number.isNaN(number);
};

export const toNumber = value => {
  return Number(value || 0);
};

export const isInRange = (value, range) => {
  if (value == null) {
    return false;
  }

  const [min, max] = range;
  return min <= value && value <= max;
};

export const getRandomInteger = maxValue =>
  Math.floor(Math.random() * maxValue);
