import { getAccessToken } from '../auth/react-auth0-spa';

// eslint-disable-next-line no-underscore-dangle
const API_ROOT = window.__REACT_APP_API_ROOT__;

export const HTTP_GET = 'GET';
export const HTTP_POST = 'POST';
export const HTTP_PUT = 'PUT';
export const HTTP_PATCH = 'PATCH';
export const HTTP_DELETE = 'DELETE';

const addBodyToRequest = [HTTP_POST, HTTP_PUT, HTTP_PATCH, HTTP_DELETE];

const baseCallApi = async (endpoint, method, body) => {
  const fullUrl =
    endpoint.indexOf(API_ROOT) === -1 ? API_ROOT + endpoint : endpoint;

  const token = await getAccessToken();

  return fetch(fullUrl, {
    method,
    body: addBodyToRequest.includes(method)
      ? body && JSON.stringify(body)
      : undefined,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  });
};

export const callApi = async (endpoint, method = HTTP_GET, body) => {
  const response = await baseCallApi(endpoint, method, body);

  let json;
  try {
    json = await response.json();
  } catch {
    json = '';
  }

  if (!response.ok) {
    return Promise.reject(response);
  }

  return json;
};

const buildBlob = async response => {
  let blob;
  try {
    blob = await response.blob();
  } catch {
    blob = '';
  }

  if (!response.ok) {
    return Promise.reject(response);
  }

  return blob;
};

export const callDownloadApi = async (endpoint, body) => {
  const response = await baseCallApi(
    endpoint,
    body ? HTTP_POST : HTTP_GET,
    body
  );
  return buildBlob(response);
};

export const callPureDownloadApi = async endpoint => {
  const response = await fetch(endpoint, {
    method: HTTP_GET,
  });

  return buildBlob(response);
};
