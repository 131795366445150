import React from 'react';
import { useValidation } from '../Common/Form/useValidation';
import { validate } from './validators';

export const useVesselInsights = () => {
  const [vesselInsightsModel, setVesselInsightsModel] = React.useState({});
  const [errors, touched, setTouched, containsActiveErrors] = useValidation(
    vesselInsightsModel,
    validate,
    {},
    {}
  );

  const handleModelChange = React.useCallback(
    (value, prop) => {
      setVesselInsightsModel(prev => {
        return {
          ...prev,
          [prop]: typeof value === 'function' ? value(prev) : value,
        };
      });
    },
    [setVesselInsightsModel]
  );

  const handleModelTouched = prop => {
    setTouched(prev => {
      return { ...prev, [prop]: true };
    });
  };

  const handleAllTouched = () => {
    setTouched({
      routeGuardId: true,
      vessels: true,
    });
  };

  const handleReset = React.useCallback(() => {
    setTouched({});
    setVesselInsightsModel({});
  }, [setTouched, setVesselInsightsModel]);

  return [
    { vesselInsightsModel, errors, touched, containsActiveErrors },
    {
      onModelChange: handleModelChange,
      onModelTouched: handleModelTouched,
      onAllTouched: handleAllTouched,
      onReset: handleReset,
    },
  ];
};
