import React from 'react';
import { connect } from 'react-redux';
import { I18nProvider } from '@lingui/react';
import { i18n } from './i18n';

class I18nLoader extends React.Component {
  constructor() {
    super();
    this.state = {
      catalogs: {},
    };
  }

  componentDidMount() {
    const { language } = this.props;
    this.loadCatalog(language);
  }

  shouldComponentUpdate(nextProps, nextState) {
    const { language } = this.props;
    const { language: nextLanguage } = nextProps;
    const { catalogs } = nextState;

    if (nextLanguage !== language && !catalogs[nextLanguage]) {
      this.loadCatalog(nextLanguage);
      return false;
    }

    return true;
  }

  loadCatalog = async language => {
    const catalog = await import(
      /* webpackMode: "lazy", webpackChunkName: "i18n-[index]" */
      `@lingui/loader!./locales/${language}/messages.json`
    );

    this.setState(state => ({
      catalogs: {
        ...state.catalogs,
        [language]: catalog,
      },
    }));
  };

  render() {
    const { children, language } = this.props;
    const { catalogs } = this.state;

    // Skip rendering when catalog isn't loaded.
    if (!catalogs[language]) return null;

    i18n.activate(language);
    i18n.load(catalogs);

    return <I18nProvider i18n={i18n}>{children}</I18nProvider>;
  }
}

const mapStateToProps = ({ locale }) => {
  return locale;
};

// // Example: depends on implementation of reducer
// const getLanguage = state => {
//   return state.locale.language;
// };

export default connect(mapStateToProps)(I18nLoader);
