import React from 'react';
import { useConfirmationDialog } from '../ConfirmationDialog/useConfirmationDialog';

export const useDeleteEntities = (remove, selectedIds) => {
  const [toDeleteIds, setToDeleteIds] = React.useState([]);
  const [
    { open: deleteConfirmationOpen },
    { onOpen, onClose, onConfirm },
  ] = useConfirmationDialog(() => remove(toDeleteIds));

  const handleDeleteMany = React.useCallback(
    ids => {
      setToDeleteIds(ids);
      onOpen();
    },
    [onOpen]
  );

  const handleDelete = React.useCallback(id => handleDeleteMany([id]), [
    handleDeleteMany,
  ]);

  const handleDeleteSelected = React.useCallback(
    () => handleDeleteMany(selectedIds),
    [handleDeleteMany, selectedIds]
  );

  const handleClose = () => {
    setToDeleteIds([]);
    onClose();
  };

  return [
    { deleteConfirmationOpen },
    {
      handleDeleteSelected,
      handleDelete,
      onClose: handleClose,
      onConfirm,
    },
  ];
};
