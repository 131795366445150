import React from 'react';
import { NavLink } from 'react-router-dom';

const NavTab = ({ to, children }) => {
  return (
    <NavLink
      className="mg-nav-tab mx-5 py-3"
      activeClassName="mg-nav-tab-active"
      to={to}
    >
      {children}
    </NavLink>
  );
};

export default NavTab;
