import React from 'react';
import clsx from 'clsx';
import { ArrowDownwardIcon } from '../../../assets';
import { Sorting } from '../../../models/sorting';

const TableSortLabel = React.forwardRef((props, ref) => {
  const {
    active = false,
    children,
    className,
    component: Component = 'span',
    direction = Sorting.ASC,
    hideSortIcon = false,
    IconComponent = ArrowDownwardIcon,
    onClick,
  } = props;

  return (
    <Component
      ref={ref}
      className={clsx('mg-table-sort-label', className)}
      onClick={onClick}
    >
      {children}
      {!hideSortIcon && active && (
        <IconComponent
          className={clsx('mg-table-sort-label-icon', {
            'mg-asc': direction === Sorting.ASC,
            'mg-desc': direction === Sorting.DESC,
          })}
        />
      )}
    </Component>
  );
});

export default TableSortLabel;
