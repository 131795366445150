import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

const Button = React.forwardRef(
  (
    {
      disabled = false,
      color = 'default',
      onClick,
      children,
      type = 'button',
      className,
      to,
      borderless,
      startIcon: startIconProp,
      size = 'medium',
    },
    ref
  ) => {
    const Component = to ? Link : 'button';

    const startIcon = startIconProp && (
      <span className={clsx('mg-button-start-icon', { 'mr-2': children })}>
        {startIconProp}
      </span>
    );

    return (
      // eslint-disable-next-line react/button-has-type
      <Component
        ref={ref}
        to={to || undefined}
        disabled={disabled}
        type={type}
        onClick={onClick}
        onKeyPress={() => {}}
        className={clsx(
          'mg-button',
          {
            [`mg-button-${color}`]: color !== 'default',
            [`mg-button-${size}`]: size !== 'medium',
            'mg-disabled': disabled,
            'mg-borderless': borderless,
          },
          className
        )}
      >
        {startIcon}
        {children && <span className="mg-button-label">{children}</span>}
      </Component>
    );
  }
);

export default Button;
