import { getHttpActionNames, buildHttpGetAction } from './utils/httpActions';
import { buildUri } from '../../services/buildUri';

export const ConnectionsReadActionNames = getHttpActionNames('CONNECTIONS');

export const loadConnections = () => dispatch => {
  const url = buildUri('tenants/connections');
  const action = buildHttpGetAction(ConnectionsReadActionNames, url);
  return dispatch(action);
};
