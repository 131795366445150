import React from 'react';
import { Trans } from '@lingui/macro';
import { useError } from '../../../store/hooks/useError';
import ErrorPage from '../ErrorPage/ErrorPage';
import NotFound from '../NotFound/NotFound';

const ErrorHandler = ({ children }) => {
  const error = useError();
  if (!error) {
    return children;
  }

  if (!(error instanceof Response)) {
    // should never happen
    // eslint-disable-next-line no-console
    console.error(error);
    return <ErrorPage message={<Trans>Unexpected error.</Trans>} />;
  }

  if (error.status.toString().startsWith('4')) {
    return <NotFound />;
  }

  return <ErrorPage message={<Trans>Server error.</Trans>} />;
};

export default ErrorHandler;
