import React from 'react';
import clsx from 'clsx';
import Label from '../Label/Label';
import { useShrink } from '../Label/useShrink';
import TextareaAutosize from '../TextareaAutosize/TextareaAutosize';

const Input = React.forwardRef((props, ref) => {
  const {
    className,
    startAdornment,
    endAdornment,
    id,
    name,
    disabled,
    onChange,
    onFocus,
    onBlur,
    onMouseDown,
    onKeyUp,
    onKeyDown,
    onPaste,
    placeholder,
    maxLength,
    label,
    error,
    value = '',
    fullWidth = false,
    autoFocus = false,
    multiline = false,
    rows = 2,
    rowsMin,
    type = 'text',
  } = props;

  const [focused, setFocused] = React.useState(false);
  const shrink = useShrink([focused, value]);

  const handleChange = (event, ...args) => {
    if (onChange) {
      onChange(event, ...args);
    }
  };

  const handleFocus = event => {
    if (disabled) {
      event.stopPropagation();
      return;
    }

    setFocused(true);
    if (onFocus) {
      onFocus(event);
    }
  };

  const handleBlur = event => {
    setFocused(false);
    if (onBlur) {
      onBlur(event);
    }
  };

  let inputProps = {};

  let InputComponent = 'input';
  if (multiline) {
    if (rows && !rowsMin) {
      InputComponent = 'textarea';
      inputProps = { rows };
    } else {
      InputComponent = TextareaAutosize;
      inputProps = { rowsMin };
    }
  }

  return (
    <div
      className={clsx(
        'mg-input-base-root',
        {
          'mg-focused': focused,
          'mg-disabled': disabled,
          'mg-adorned-start': startAdornment,
          'mg-adorned-end': endAdornment,
          'mg-error': error,
          'mg-full-width': fullWidth,
        },
        className
      )}
      ref={ref}
    >
      {label && <Label id={id} label={label} shrink={shrink} />}
      {startAdornment}
      <InputComponent
        className={clsx('mg-input-base-input', {
          'mg-input-adorned-start': startAdornment,
          'mg-input-adorned-end': endAdornment,
          'mg-input-multiline': multiline,
        })}
        disabled={disabled}
        id={id}
        name={name}
        onChange={handleChange}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onMouseDown={onMouseDown}
        onKeyUp={onKeyUp}
        onKeyDown={onKeyDown}
        onPaste={onPaste}
        placeholder={placeholder}
        value={value}
        type={type}
        maxLength={maxLength}
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...inputProps}
      />
      {endAdornment}
      <div aria-hidden className="mg-notched-outline-root" />
    </div>
  );
});

export default React.memo(Input);
