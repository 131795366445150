import React from 'react';
import { t, Trans } from '@lingui/macro';
import { CheckBox, Pillow, Tooltip, ConfirmationDialog } from '../../Common';
import {
  PersonIcon,
  AccountBoxIcon,
  ComputerIcon,
} from '../../../assets/index';
import './UsersTable.scss';
import UserActions from './UserActions';
import { UserType } from '../../../models/user-type';
import { t8 } from '../../../i18n/i18n';
import UserSendCredentialsDialog from './UserSendCredentials/UserSendCredentialsDialog';
import { Permission } from '../../../auth/app-permissions/permission';
import { useUserPermissions } from '../../../auth/app-permissions/useUserPermissions';
import UserTableHeader from './UserTableHeader/UserTableHeader';

const getUserIcon = user => {
  switch (user.userType) {
    case UserType.MACHINE:
      return (
        <ComputerIcon
          title={t8(t`Machine user`)}
          className="mg-icon-gray ml-1"
        />
      );
    case UserType.REGULAR:
      return (
        <PersonIcon title={t8(t`Regular user`)} className="mg-icon-gray ml-1" />
      );
    default:
      return (
        <AccountBoxIcon
          title={t8(t`External user`)}
          className="mg-icon-gray ml-1"
        />
      );
  }
};

const UsersTable = ({
  users = {},
  tableSelection,
  onSort,
  onSearchTermChange,
  onClearFilters,
  onViewDetail,
  onEdit,
  onRotate,
  onEmailSend,
}) => {
  const [{ selected }, { selectedChange }] = tableSelection;
  const [sendMailUser, setSendMailUser] = React.useState(null);
  const [rotateUser, setRotateUser] = React.useState(null);

  const hasPermission = useUserPermissions();

  const handleRowClick = React.useCallback(user => onViewDetail(user), [
    onViewDetail,
  ]);

  const handleEditClick = React.useCallback(user => onEdit(user), [onEdit]);

  const handleClose = () => {
    setSendMailUser(null);
  };

  const handleEmailSend = email => {
    onEmailSend(email, sendMailUser.user.id);
    handleClose();
  };

  const handleRotateClose = () => {
    setRotateUser(null);
  };

  const handleRotateSubmit = () => {
    onRotate(rotateUser.user);
    handleRotateClose();
  };

  return (
    <div>
      <table className="mg-table mg-user-table mt-4">
        <UserTableHeader
          users={users}
          tableSelection={tableSelection}
          onSearchTermChange={onSearchTermChange}
          onClearFilters={onClearFilters}
          onSort={onSort}
        />
        <tbody>
          {(users.page.items || []).map(u => {
            const disabled = !hasPermission(Permission.UsersDelete);
            const disabledSelf = !hasPermission(
              Permission.UsersDelete,
              u.user.oid
            );
            return (
              <tr
                key={u.user.id}
                className="mg-cursor-pointer"
                onClick={() => handleRowClick(u)}
              >
                <td className="mg-icon-cell">
                  <Tooltip
                    title={
                      !disabled &&
                      disabledSelf &&
                      t8(t`Not possible to delete yourself.`)
                    }
                  >
                    <CheckBox
                      checked={selected[u.user.id] || false}
                      onChange={() => selectedChange(u.user)}
                      onClick={e => e.stopPropagation()}
                      disabled={disabledSelf}
                    />
                  </Tooltip>
                </td>
                <td className="mg-col-type">{getUserIcon(u.user)}</td>
                <td className="mg-overflow-ellipsis" title={u.user.userName}>
                  {u.user.userName}
                </td>
                <td
                  className="mg-overflow-ellipsis"
                  title={u.customerDetails.name}
                >
                  {u.customerDetails.name}
                </td>
                <td>
                  {u.user.roles.slice(0, 2).map(r => (
                    <Pillow key={r.id} color="dark">
                      {r.name || r.id}
                    </Pillow>
                  ))}
                  {u.user.roles.length > 2 && (
                    <Tooltip
                      title={u.user.roles
                        .slice(2, u.user.roles.length)
                        .map(r => {
                          return <p key={r.id}>{r.name}</p>;
                        })}
                    >
                      <Pillow
                        key={0}
                        className="mg-border-gray-dark"
                        color="dark"
                      >
                        +{u.user.roles.length - 2}
                      </Pillow>
                    </Tooltip>
                  )}
                </td>
                <td>
                  <UserActions
                    onRotate={() => onRotate(u.user)}
                    onMailClicked={() => setSendMailUser(u)}
                    onEditClicked={() => handleEditClick(u)}
                    onRotateClicked={() => setRotateUser(u)}
                    userOid={u.user.oid}
                    userType={u.user.userType}
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {sendMailUser && (
        <UserSendCredentialsDialog
          onClose={handleClose}
          onSubmit={handleEmailSend}
          customerName={sendMailUser.customerDetails.name}
        />
      )}
      {rotateUser && (
        <ConfirmationDialog
          onClose={handleRotateClose}
          onConfirm={handleRotateSubmit}
          title={<Trans>Do you want to rotate credentials?</Trans>}
          confirmButtonName={<Trans>Rotate</Trans>}
          confirmButtonColor="secondary"
        >
          <p className="mg-body-2">
            <Trans>
              This action cannot be undone. It will permanently rotate the
              credentials. All consumers will have to update credentials.
            </Trans>
          </p>
        </ConfirmationDialog>
      )}
    </div>
  );
};

export default UsersTable;
