import React from 'react';
import { Trans } from '@lingui/macro';
import { Button, CanUser } from '../../Common';
import { AddIcon } from '../../../assets/index';
import { Permission } from '../../../auth/app-permissions/permission';

const TenantsActions = ({ onAdd }) => {
  return (
    <div className="d-flex">
      <CanUser permission={Permission.TenantsCreate}>
        <Button
          color="secondary"
          startIcon={<AddIcon />}
          onClick={() => onAdd()}
        >
          <Trans>Tenant</Trans>
        </Button>
      </CanUser>
    </div>
  );
};

export default TenantsActions;
