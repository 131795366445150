import React from 'react';
import { titleBase } from '../config';

export const setDocumentTitle = pageName => {
  window.document.title = `${pageName} - ${titleBase}`;
};

export const useDocumentTitle = title => {
  React.useEffect(() => setDocumentTitle(title), [title]);
};
