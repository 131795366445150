import React from 'react';
import { Trans, t, Plural } from '@lingui/macro';
import { toast } from 'react-toastify';
import { t8 } from '../../i18n/i18n';
import AppLayout from '../AppLayout/AppLayout';
import AppHeader from '../AppHeader/AppHeader';
import { useDocumentTitle } from '../../utils/index';
import UsersTable from './UsersTable/UsersTable';
import {
  Loading,
  TablePageContainer,
  TablePageFooter,
  ToastMessage,
} from '../Common';
import { usersPathTemplate } from '../../routes/routesPaths';
import { useUsers } from '../../store/hooks/useUsers';
import UserCreateDialog from './UserCreateDialog/UserCreateDialog';
import { useUrlPagination } from '../Common/utils/useUrlPagination';
import { useTableSelection } from '../Common/utils/useTableSelection';
import { useDeleteEntities } from '../Common/utils/useDeleteEntities';
import UsersActions from './UsersActions/UsersActions';
import DeleteConfirmationDialog from '../Common/DeleteConfirmationDialog/DeleteConfirmationDialog';
import UserDetailDialog from './UserDetailDialog/UserDetailDialog';
import { Permission } from '../../auth/app-permissions/permission';
import { useUserPermissions } from '../../auth/app-permissions/useUserPermissions';
import { UserType } from '../../models/user-type';
import { useEmailNotification } from './useEmailNotification';

const Users = () => {
  useDocumentTitle(t8(t`Users`));

  const [popupOpen, setPopupOpen] = React.useState(false);
  const [userDetail, setUserDetail] = React.useState(null);
  const [pageNumber, changePage, goToFirstPage] = useUrlPagination(
    usersPathTemplate
  );
  const [
    users,
    { create, remove, update, rotateSecret, sort, setSearchTerm, clearFilters },
  ] = useUsers(pageNumber, goToFirstPage);
  const { page, isFetching, hasFilters } = users;

  const [
    { isEmailSending },
    { onEmailSend, onInvitationSend, onEmailChangedNotificationSend },
  ] = useEmailNotification();

  const handleCreate = (user, destinationEmail) => {
    create(user, id => {
      if (user.userType === UserType.MACHINE) {
        if (destinationEmail) {
          onEmailSend(destinationEmail, id, true);
        } else {
          toast(
            <ToastMessage text={<Trans>Machine user is created.</Trans>} />
          );
        }
      }
      if (user.userType === UserType.REGULAR) {
        onInvitationSend(user.email);
      }
    });
    setPopupOpen(false);
  };

  const tableUsers = React.useMemo(() => page.items.map(i => i.user), [
    page.items,
  ]);

  const hasPermission = useUserPermissions();
  const notSelfUsers = React.useMemo(
    () => tableUsers.filter(u => hasPermission(Permission.UsersDelete, u.oid)),
    [hasPermission, tableUsers]
  );

  const tableSelection = useTableSelection(notSelfUsers);
  const [{ selectedIds }] = tableSelection;

  const handleUpdate = (user, sendNotification) => {
    update(user, () => {
      if (sendNotification) {
        onEmailChangedNotificationSend(user.email);
      }
    });
    setUserDetail(null);
  };

  const handleRemove = React.useCallback(
    userIds => {
      remove(userIds, () => {
        toast(
          <ToastMessage
            text={
              <Plural
                id="users.delete"
                value={userIds.length}
                one="User is deleted."
                other="Users are deleted."
              />
            }
          />
        );
      });
    },
    [remove]
  );

  const [
    { deleteConfirmationOpen },
    { handleDeleteSelected, onClose, onConfirm },
  ] = useDeleteEntities(handleRemove, selectedIds);

  const handleViewDetail = user => {
    setUserDetail({ user, editMode: false });
  };

  const handleEdit = user => {
    setUserDetail({ user, editMode: true });
  };

  const handleDetailClose = () => {
    setUserDetail(null);
  };

  const handleRotate = user => {
    rotateSecret(user.id, () => {
      toast(<ToastMessage text={<Trans>User secret is rotated</Trans>} />);
    });
  };

  return (
    <AppLayout
      header={
        <AppHeader
          title={<Trans>Users</Trans>}
          actionsArea={
            <UsersActions
              selectedIds={selectedIds}
              onAdd={() => setPopupOpen(true)}
              onDeleteSelected={handleDeleteSelected}
            />
          }
        />
      }
    >
      {(isFetching || isEmailSending) && <Loading />}
      <TablePageContainer minWidth="1400px">
        <UsersTable
          users={users}
          tableSelection={tableSelection}
          onSearchTermChange={setSearchTerm}
          onClearFilters={clearFilters}
          onSort={sort}
          onViewDetail={handleViewDetail}
          onEdit={handleEdit}
          onRotate={handleRotate}
          onEmailSend={onEmailSend}
        />
        <TablePageFooter
          page={page}
          isFetching={isFetching}
          hasFilters={hasFilters}
          noItemsMessage={
            <Trans>There are no results matching the filters found.</Trans>
          }
          onPageChange={changePage}
        />
      </TablePageContainer>
      {popupOpen && (
        <UserCreateDialog
          onClose={() => setPopupOpen(false)}
          onCreate={handleCreate}
        />
      )}
      {deleteConfirmationOpen && (
        <DeleteConfirmationDialog onClose={onClose} onConfirm={onConfirm}>
          <Trans>Are you sure you want to delete the user(s)?</Trans>
        </DeleteConfirmationDialog>
      )}
      {userDetail && (
        <UserDetailDialog
          user={userDetail.user}
          editMode={userDetail.editMode}
          onClose={handleDetailClose}
          onSubmit={handleUpdate}
        />
      )}
    </AppLayout>
  );
};

export default Users;
