import { getHttpActionNames, buildHttpGetAction } from './utils/httpActions';
import { buildUri } from '../../services/buildUri';

export const CustomersReadActionNames = getHttpActionNames('CUSTOMERS');
export const CUSTOMERS_SEARCH_TERM_CHANGE = 'CUSTOMERS_SEARCH_TERM_CHANGE';

const fetchCustomers = (searchTerm, domain) => dispatch => {
  const url = buildUri('customers', { searchTerm });
  const action = buildHttpGetAction(CustomersReadActionNames, url);
  return dispatch({ ...action, domain });
};

export const loadCustomers = domain => (dispatch, getState) => {
  const { customers } = getState();
  const { searchTerm } = customers[domain] || {};
  if (searchTerm && searchTerm.length > 2) {
    fetchCustomers(searchTerm, domain)(dispatch);
  } else {
    dispatch({
      type: CustomersReadActionNames.SUCCESS,
      response: null,
      domain,
    });
  }
};

export const setCustomersSearchTerm = (searchTerm, domain) => dispatch => {
  dispatch({
    type: CUSTOMERS_SEARCH_TERM_CHANGE,
    searchTerm,
    domain,
  });
};
