import React, { Suspense } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch,
} from 'react-router-dom';
import Toast from '../Common/Toast/Toast';
import NotFound from '../Errors/NotFound/NotFound';
import Loading from '../Common/Loading/Loading';
import I18nLoader from '../../i18n/I18nLoader';
import ErrorHandler from '../Errors/ErrorHandler/ErrorHandler';
import Users from '../Users/Users';
import {
  usersFirstPage,
  usersPathTemplate,
  tenantsFirstPage,
  tenantsPathTemplate,
} from '../../routes/routesPaths';
import { useAuth0 } from '../../auth/react-auth0-spa';
import Tenants from '../Tenants/Tenants';
import { Permission } from '../../auth/app-permissions/permission';
import { useUserPermissions } from '../../auth/app-permissions/useUserPermissions';

const App = () => {
  const { loading, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const hasPermissions = useUserPermissions();

  const canViewTenants = React.useMemo(
    () => hasPermissions(Permission.TenantsView),
    [hasPermissions]
  );

  if (!isAuthenticated && !loading) {
    loginWithRedirect();
    return null;
  }

  if (loading) {
    return <Loading />;
  }

  const getTenantsRoutes = () => {
    return [
      <Route exact key={1} path="/tenants">
        <Redirect to={tenantsFirstPage} />
      </Route>,
      <Route key={2} path={tenantsPathTemplate}>
        <Tenants />
      </Route>,
    ];
  };

  return (
    <I18nLoader>
      <Router>
        <Toast />
        <ErrorHandler>
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route exact path="/">
                <Redirect to={usersFirstPage} />
              </Route>
              <Route exact path="/users">
                <Redirect to={usersFirstPage} />
              </Route>
              <Route path={usersPathTemplate}>
                <Users />
              </Route>
              {canViewTenants && getTenantsRoutes()}
              <Route path="/logout">{() => logout()}</Route>
              <Route>
                <NotFound />
              </Route>
            </Switch>
          </Suspense>
        </ErrorHandler>
      </Router>
    </I18nLoader>
  );
};

export default App;
