import React from 'react';
import { t } from '@lingui/macro';
import { IconButton, Tooltip, CanUser } from '../../Common';
import {
  LockResetIcon,
  MailOutlineIcon,
  EditIcon,
  InfoIcon,
} from '../../../assets/index';
import { UserType } from '../../../models/user-type';
import { t8 } from '../../../i18n/i18n';
import { Permission } from '../../../auth/app-permissions/permission';

const UserActions = ({
  onMailClicked,
  onEditClicked,
  onRotateClicked,
  userType,
  userOid,
}) => {
  const handleEditClicked = React.useCallback(
    event => {
      event.stopPropagation();
      onEditClicked();
    },
    [onEditClicked]
  );

  const handleMailClick = React.useCallback(
    event => {
      event.stopPropagation();
      onMailClicked();
    },
    [onMailClicked]
  );

  const handleClick = React.useCallback(
    event => {
      event.stopPropagation();
      onRotateClicked();
    },
    [onRotateClicked]
  );

  return (
    <>
      <CanUser
        permission={Permission.UsersEdit}
        data={userOid}
        no={
          <Tooltip title={t8(t`View details`)}>
            <IconButton className="mg-subscription-entry-actions">
              <InfoIcon />
            </IconButton>
          </Tooltip>
        }
      >
        <Tooltip title={t8(t`Edit user`)}>
          <IconButton
            className="mg-subscription-entry-actions"
            onClick={handleEditClicked}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
      </CanUser>
      {userType === UserType.MACHINE && (
        <>
          <CanUser permission={Permission.UsersRotateSecret}>
            <Tooltip title={t8(t`Rotate user secret`)}>
              <IconButton
                className="mg-subscription-entry-actions"
                onClick={handleClick}
              >
                <LockResetIcon />
              </IconButton>
            </Tooltip>
          </CanUser>
          <CanUser permission={Permission.UsersSendCredentials}>
            <Tooltip title={t8(t`Send credentials via email`)}>
              <IconButton
                className="mg-subscription-entry-actions"
                onClick={handleMailClick}
              >
                <MailOutlineIcon />
              </IconButton>
            </Tooltip>
          </CanUser>
        </>
      )}
    </>
  );
};

export default UserActions;
