import React from 'react';
import { Trans } from '@lingui/macro';
import ContractSelectDialog from '../../Users/UserCreateDialog/ContractSelectDialog/ContractSelectDialog';
import ConnectionSelectDialog from './ConnectionSelectDialog/ConnectionSelectDialog';
import TenantRolesDialog from './TenantRolesDialog/TenantRolesDialog';

const TenantCreateDialog = ({ onClose, onCreate }) => {
  const [selectedConnection, setSelectedConnection] = React.useState();
  const [selectedCustomer, setSelectedCustomer] = React.useState();

  const handleContinue = connectionName => {
    setSelectedConnection(connectionName);
  };

  const handleCustomerSubmit = customer => {
    setSelectedCustomer(customer);
  };

  const handleRolesSubmit = (roleIds, vesselInsightsModel) => {
    onCreate({
      accountId: selectedCustomer.customerDetails.salesforceAccountId,
      customerId: selectedCustomer.customerDetails.salesforceCustomerId,
      roles: roleIds,
      name: selectedConnection.connectionName,
      description: selectedConnection.description,
      vesselInsights: vesselInsightsModel,
    });
  };

  return (
    <>
      {!selectedConnection && (
        <ConnectionSelectDialog onClose={onClose} onContinue={handleContinue} />
      )}
      {!!selectedConnection && !selectedCustomer && (
        <ContractSelectDialog
          onClose={onClose}
          onContinue={handleCustomerSubmit}
          customerDescription={
            <Trans>
              Select a customer to associate with users of created tenant.
            </Trans>
          }
        />
      )}
      {!!selectedCustomer && (
        <TenantRolesDialog
          onClose={onClose}
          onContinue={handleRolesSubmit}
          selectedCustomer={selectedCustomer}
        />
      )}
    </>
  );
};

export default TenantCreateDialog;
