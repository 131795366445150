import React from 'react';
import Hint from '../Common/Hint/Hint';

const AppHeader = ({ children, title, info, actionsArea }) => {
  return (
    <div className="mg-app-header d-flex flex-shrink-0 justify-content-between align-items-end px-4 py-3">
      <div className="flex-grow-1">
        {children}
        <div className="d-flex align-items-center mt-1">
          <h1>{title}</h1>
          {info && (
            <Hint className="mg-app-header-info-wrapper ml-2" size="lg">
              {info}
            </Hint>
          )}
        </div>
      </div>
      {actionsArea}
    </div>
  );
};

export default AppHeader;
