import React from 'react';
import IconButton from '../IconButton/index';
import { ClearIcon, FilterListIcon } from '../../../assets/index';

const TableClearFilters = ({ hasFilters, onClear }) => {
  return hasFilters ? (
    <IconButton
      className="mg-table-clear-filters"
      color="white"
      onClick={onClear}
    >
      <ClearIcon />
    </IconButton>
  ) : (
    <IconButton disabled>
      <FilterListIcon className="mg-table-clear-filters" />
    </IconButton>
  );
};

export default TableClearFilters;
