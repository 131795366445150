import React from 'react';
import { Trans } from '@lingui/macro';

const PaginationEntries = ({
  page: { pageNumber = 1, itemsPerPage = 0, filteredItemsCount = 0 },
}) => {
  const entriesStart =
    filteredItemsCount && (pageNumber - 1) * itemsPerPage + 1;
  const entriesEnd = Math.min(pageNumber * itemsPerPage, filteredItemsCount);
  return (
    <p className="mg-body-1-gray">
      <Trans>{`Showing ${entriesStart}–${entriesEnd} of ${filteredItemsCount} entries`}</Trans>
    </p>
  );
};

export default PaginationEntries;
