import { shouldLoadUnlessInStore } from './utils/index';
import {
  getHttpActionNames,
  buildHttpGetAction,
  buildHttpPatchAction,
  buildHttpPostAction,
} from './utils/httpActions';
import { buildUri, buildPageUri } from '../../services/buildUri';
import { Sorting } from '../../models/sorting';

export const TenantsReadActionNames = getHttpActionNames('TENANTS');
export const TenantsUpdateActionNames = getHttpActionNames('TENANTS_UPDATE');
export const TenantsCreateActionNames = getHttpActionNames('TENANTS_CREATE');
export const TENANTS_SORTING_CHANGE = 'TENANTS_SORTING_CHANGE';
export const TENANTS_SEARCH_TERM_CHANGE = 'TENANTS_SEARCH_TERM_CHANGE';

export const loadTenants = (pageNumber, onSuccess) => (dispatch, getState) => {
  const {
    tenants: { sortingField, sortingDirection, searchTerms },
  } = getState();
  const url = buildPageUri('tenants', pageNumber, {
    sortingField,
    ascending: sortingDirection === Sorting.ASC,
    searches: JSON.stringify(searchTerms),
  });
  const action = buildHttpGetAction(TenantsReadActionNames, url, onSuccess);

  return dispatch(action);
};

const fetchTenants = () => dispatch => {
  const url = buildUri('tenants');
  const action = buildHttpGetAction(TenantsReadActionNames, url);
  return dispatch(action);
};

const shouldLoadTenants = state => {
  return shouldLoadUnlessInStore(state.tenants, 'tenants');
};

export const loadTenantsIfNeeded = () => (dispatch, getState) => {
  if (shouldLoadTenants(getState())) {
    fetchTenants()(dispatch);
  }
};

export const setTenantsSorting = sorting => dispatch => {
  dispatch({
    type: TENANTS_SORTING_CHANGE,
    sorting,
  });
};

export const setTenantsSearchTerm = searchTerm => dispatch => {
  dispatch({
    type: TENANTS_SEARCH_TERM_CHANGE,
    searchTerm,
  });
};

export const updateTenant = (tenant, onSuccess) => dispatch => {
  const url = buildUri(`tenants/${tenant.id}`);
  const action = buildHttpPatchAction(
    TenantsUpdateActionNames,
    url,
    tenant,
    onSuccess
  );
  dispatch(action);
};

export const createTenant = (user, onSuccess) => dispatch => {
  const url = buildUri('tenants');
  const action = buildHttpPostAction(
    TenantsCreateActionNames,
    url,
    user,
    onSuccess
  );
  dispatch(action);
};
