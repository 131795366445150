import React from 'react';
import { Trans, t } from '@lingui/macro';
import { t8 } from '../../../../i18n/i18n';
import { CheckBox, FormControlLabel, ListItem, Menu } from '../../../Common';
import { UserType } from '../../../../models/user-type';

const UserTypeFilterMenu = ({
  anchorEl,
  open,
  onUserTypeChange,
  userType,
  onClose,
}) => {
  return (
    <Menu
      anchorEl={anchorEl.current}
      open={open}
      onClickAway={() => {
        onClose();
      }}
      placement="bottom-end"
    >
      <ListItem title={t8(t`All`)} size="small">
        <FormControlLabel label={<Trans>All</Trans>} className="pr-2">
          <CheckBox
            checked={userType === null}
            onChange={() => onUserTypeChange('')}
          />
        </FormControlLabel>
      </ListItem>
      <ListItem title={t8(t`Machine users`)} size="small">
        <FormControlLabel label={<Trans>Machine users</Trans>} className="pr-2">
          <CheckBox
            checked={userType === UserType.MACHINE}
            onChange={() => onUserTypeChange(UserType.MACHINE)}
          />
        </FormControlLabel>
      </ListItem>
      <ListItem title={t8(t`Regular users`)} size="small">
        <FormControlLabel label={<Trans>Regular users</Trans>} className="pr-2">
          <CheckBox
            checked={userType === UserType.REGULAR}
            onChange={() => onUserTypeChange(UserType.REGULAR)}
          />
        </FormControlLabel>
      </ListItem>
      <ListItem title={t8(t`External users`)} size="small">
        <FormControlLabel
          label={<Trans>External users</Trans>}
          className="pr-2"
        >
          <CheckBox
            checked={userType === UserType.EXTERNAL}
            onChange={() => onUserTypeChange(UserType.EXTERNAL)}
          />
        </FormControlLabel>
      </ListItem>
    </Menu>
  );
};

export default UserTypeFilterMenu;
