import React from 'react';
import ReactDOM from 'react-dom';
import ownerDocument from '../utils/ownerDocument';
import useForkRef from '../utils/useForkRef';
import setRef from '../utils/setRef';
import useEventCallback from '../utils/useEventCallback';

function mapEventPropToEvent(eventProp) {
  return eventProp.substring(2).toLowerCase();
}

/**
 * Listen for click events that occur somewhere in the document, outside of the element itself.
 * For instance, if you need to hide a menu when people click anywhere else on your page.
 */
const ClickAwayListener = React.forwardRef(function ClickAwayListener(
  props,
  ref
) {
  const {
    children,
    mouseEvent = 'onClick',
    touchEvent = 'onTouchEnd',
    onClickAway,
  } = props;
  const movedRef = React.useRef(false);
  const nodeRef = React.useRef(null);
  const mountedRef = React.useRef(false);

  React.useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  const handleNodeRef = useForkRef(nodeRef, ref);
  // can be removed once we drop support for non ref forwarding class components
  const handleOwnRef = React.useCallback(
    instance => {
      // #StrictMode ready
      // eslint-disable-next-line react/no-find-dom-node
      setRef(handleNodeRef, ReactDOM.findDOMNode(instance));
    },
    [handleNodeRef]
  );
  const handleRef = useForkRef(children.ref, handleOwnRef);

  const handleClickAway = useEventCallback(event => {
    // Ignore events that have been `event.preventDefault()` marked.
    if (event.defaultPrevented) {
      return;
    }

    // IE 11 support, which trigger the handleClickAway even after the unbind
    if (!mountedRef.current) {
      return;
    }

    // Do not act if user performed touchmove
    if (movedRef.current) {
      movedRef.current = false;
      return;
    }

    // The child might render null.
    if (!nodeRef.current) {
      return;
    }

    // Multi window support
    const doc = ownerDocument(nodeRef.current);

    if (
      doc.documentElement &&
      doc.documentElement.contains(event.target) &&
      !nodeRef.current.contains(event.target)
    ) {
      onClickAway(event);
    }
  });

  const handleTouchMove = React.useCallback(() => {
    movedRef.current = true;
  }, []);

  React.useEffect(() => {
    if (touchEvent !== false) {
      const mappedTouchEvent = mapEventPropToEvent(touchEvent);

      document.addEventListener(mappedTouchEvent, handleClickAway);
      document.addEventListener('touchmove', handleTouchMove);

      return () => {
        document.removeEventListener(mappedTouchEvent, handleClickAway);
        document.removeEventListener('touchmove', handleTouchMove);
      };
    }

    return undefined;
  }, [handleClickAway, handleTouchMove, touchEvent]);

  React.useEffect(() => {
    if (mouseEvent !== false) {
      const mappedMouseEvent = mapEventPropToEvent(mouseEvent);
      document.addEventListener(mappedMouseEvent, handleClickAway);

      return () => {
        document.removeEventListener(mappedMouseEvent, handleClickAway);
      };
    }

    return undefined;
  }, [handleClickAway, mouseEvent]);

  return <>{React.cloneElement(children, { ref: handleRef })}</>;
});

export default ClickAwayListener;
