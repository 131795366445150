import { t } from '@lingui/macro';
import { t8 } from '../../../../i18n/i18n';
import { isRequired } from '../../../../utils/index';

export const validate = state => {
  return {
    connectionName: isRequired(t8(t`Connection`))(state.connectionName),
    description: isRequired(t8(t`Description`))(state.description),
  };
};
