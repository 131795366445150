import {
  UsersReadActionNames,
  UsersCreateActionNames,
  UsersDeleteActionNames,
  UsersUpdateActionNames,
  UsersRotateSecretActionNames,
  USERS_SORTING_CHANGE,
  USERS_SEARCH_TERM_CHANGE,
  USERS_FILTERS_CLEARED,
} from '../actions';
import { Sorting } from '../../models/sorting';
import { getRecentSearches } from './utils/index';

export const users = (
  state = {
    isFetching: false,
    page: { items: [] },
    searchTerms: {},
    recentSearches: [],
    sortingField: 'customer',
    sortingDirection: Sorting.ASC,
  },
  action
) => {
  switch (action.type) {
    case UsersReadActionNames.REQUEST:
      return { ...state, isFetching: true };
    case UsersReadActionNames.FAILURE:
      return {
        ...state,
        isFetching: false,
        page: {},
      };
    case UsersReadActionNames.SUCCESS:
      return {
        ...state,
        isFetching: false,
        page: action.response,
      };
    case UsersCreateActionNames.SUCCESS:
    case UsersCreateActionNames.FAILURE:
    case UsersDeleteActionNames.SUCCESS:
    case UsersDeleteActionNames.FAILURE:
    case UsersUpdateActionNames.SUCCESS:
    case UsersUpdateActionNames.FAILURE:
    case UsersRotateSecretActionNames.SUCCESS:
    case UsersRotateSecretActionNames.FAILURE:
      return { ...state, isFetching: false };
    case UsersCreateActionNames.REQUEST:
    case UsersDeleteActionNames.REQUEST:
    case UsersUpdateActionNames.REQUEST:
    case UsersRotateSecretActionNames.REQUEST:
      return { ...state, isFetching: true };
    case USERS_SORTING_CHANGE:
      return {
        ...state,
        sortingField: action.sorting.sortingField,
        sortingDirection: action.sorting.sortingDirection,
      };
    case USERS_SEARCH_TERM_CHANGE:
      return {
        ...state,
        searchTerms: {
          ...state.searchTerms,
          [action.searchTerm.searchField]: action.searchTerm.searchValue.trim(),
        },
        recentSearches: getRecentSearches(state, action),
      };
    case USERS_FILTERS_CLEARED:
      return {
        ...state,
        searchTerms: {},
      };
    default:
      return state;
  }
};
