import { Trans } from '@lingui/macro';
import React from 'react';
import { hasErrors } from '../../../../utils/index';
import { Button, FixErrorsTooltip, NumericInput } from '../../../Common';
import { useValidation } from '../../../Common/Form/useValidation';
import { validate } from './validators';

const VesselAddInputs = ({ onCreate, onClose, vessels }) => {
  const [state, setState] = React.useState({});

  React.useEffect(() => {
    setState(prev => {
      return {
        ...prev,
        vessels,
      };
    });
  }, [vessels]);

  const [errors, touched, setTouched, containsActiveErrors] = useValidation(
    state,
    validate,
    {},
    {}
  );

  const setAllTouched = () => {
    setTouched({
      ...touched,
      imo: true,
      mmsi: true,
    });
  };

  const handleSubmit = () => {
    if (hasErrors(errors)) {
      setAllTouched();
    } else {
      onCreate(state);
    }
  };

  const handleImoChange = value => {
    setState(prev => {
      return { ...prev, imo: String(value) };
    });
  };

  const handleMmsiChange = value => {
    setState(prev => {
      return { ...prev, mmsi: value };
    });
  };

  return (
    <>
      <NumericInput
        className="mt-3 mx-1"
        fullWidth
        maxLength={100}
        label={<Trans>IMO</Trans>}
        value={state.imo}
        onChange={v => handleImoChange(v)}
        onBlur={() =>
          setTouched(prev => {
            return { ...prev, imo: true };
          })
        }
        error={touched.imo && errors.imo}
      />
      {touched.imo && errors.imo && (
        <p className="mg-error-message mt-1 mx-1">{errors.imo}</p>
      )}
      <NumericInput
        className="mt-3 mx-1"
        fullWidth
        maxLength={100}
        label={<Trans>MMSI</Trans>}
        value={state.mmsi}
        onChange={v => handleMmsiChange(v)}
        onBlur={() =>
          setTouched(prev => {
            return { ...prev, mmsi: true };
          })
        }
        error={touched.mmsi && errors.mmsi}
      />
      {touched.mmsi && errors.mmsi && (
        <p className="mg-error-message mt-1 mx-1">{errors.mmsi}</p>
      )}
      <FixErrorsTooltip show={containsActiveErrors}>
        <Button
          className="mt-3 mx-1"
          size="small"
          color="secondary"
          onClick={handleSubmit}
          disabled={containsActiveErrors}
        >
          <Trans>Create</Trans>
        </Button>
      </FixErrorsTooltip>
      <Button
        className="mt-3 mx-1"
        size="small"
        onClick={onClose}
        disabled={containsActiveErrors}
      >
        <Trans>Cancel</Trans>
      </Button>
    </>
  );
};

export default VesselAddInputs;
