import { ConnectionsReadActionNames } from '../actions';

export const connections = (
  state = {
    isFetching: false,
    connections: null,
  },
  action
) => {
  switch (action.type) {
    case ConnectionsReadActionNames.REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case ConnectionsReadActionNames.SUCCESS:
      return {
        ...state,
        isFetching: false,
        connections: action.response,
      };
    case ConnectionsReadActionNames.FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};
