import React from 'react';
import clsx from 'clsx';

const DialogContent = ({ children, className, scrollable = true }) => {
  return (
    <div
      className={clsx(
        'mg-dialog-content-root py-2 px-4',
        { 'mg-scrollable': scrollable },
        className
      )}
    >
      {children}
    </div>
  );
};

export default DialogContent;
