import { defaultTableItemsPerPage } from '../config';

const buildUriBase = (baseUrl, params) => {
  const urlParams = new URLSearchParams();
  Object.entries(params || {}).forEach(([parameter, value]) => {
    urlParams.set(parameter, value);
  });

  return encodeURI(`${baseUrl}?${urlParams.toString()}`);
};

export const buildPageUri = (resourceName, pageNumber, params) => {
  const baseUrl = `v1/authenticationUserManagement/${resourceName}/page/${pageNumber}/items/${defaultTableItemsPerPage}`;
  return buildUriBase(baseUrl, params);
};

export const buildUri = (resourceName, params) => {
  const baseUrl = `v1/authenticationUserManagement/${resourceName}`;
  return buildUriBase(baseUrl, params);
};
