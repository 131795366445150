import { ContractsReadActionNames } from '../actions';

export const contracts = (
  state = {
    isFetching: false,
    contracts: null,
  },
  action
) => {
  switch (action.type) {
    case ContractsReadActionNames.REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case ContractsReadActionNames.SUCCESS:
      return {
        ...state,
        isFetching: false,
        contracts: action.response,
      };
    case ContractsReadActionNames.FAILURE:
      return {
        ...state,
        isFetching: false,
        contracts: null,
      };
    default:
      return state;
  }
};
