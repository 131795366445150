import React from 'react';
import { Trans } from '@lingui/macro';
import { copyToClipboard } from '../../../utils/clipboard-utils';
import { Input, InputAdornment, Tooltip, IconButton } from '../../Common';
import { ContentCopyIcon } from '../../../assets/index';

const UserDetailExternalCredentials = ({ userDetails }) => {
  return (
    <>
      <h5 className="mb-2">
        <Trans>External user credentials</Trans>
      </h5>
      <Input
        fullWidth
        maxLength={100}
        label={<Trans>User name</Trans>}
        disabled
        value={userDetails.userName}
        endAdornment={
          <InputAdornment position="end">
            <Tooltip fullWidth title={<Trans>Copy to clipboard</Trans>}>
              <IconButton onClick={() => copyToClipboard(userDetails.userName)}>
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        }
      />
    </>
  );
};

export default UserDetailExternalCredentials;
