import React from 'react';
import clsx from 'clsx';
import Popper from '../Popper';
import ClickAwayListener from '../ClickAwayListener';
import Paper from '../Paper';
import List from '../List';

const Menu = props => {
  const {
    className,
    children,
    anchorEl,
    open,
    onClickAway,
    placement = 'left-start',
    color = 'light',
  } = props;

  return (
    <Popper
      className={clsx(
        'mg-menu-popper',
        { [`mg-${color}`]: color !== 'light' },
        className
      )}
      open={open}
      anchorEl={anchorEl}
      placement={placement}
    >
      <ClickAwayListener onClickAway={onClickAway}>
        <Paper className="mg-menu-paper">
          <List>{children}</List>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};

export default Menu;
