/* eslint-disable no-plusplus */
import moment from 'moment';
import { t, plural } from '@lingui/macro';
import { formatDurationAs } from './moment-extensions';
import { t8 } from '../i18n/i18n';

export const getShortDateFormat = () => t8(t`DD.MM.YY`);
export const getShortDateFormatWithMonth = () => t8(t`MMM D, YYYY`);
export const getShortDateFormatWithMonthAndTime = () =>
  t8(t`MMM D, YYYY HH:mm`);
export const getShortDateFormatWithDayOfWeek = () => t8(t`ddd D/M`);
export const getShortDualDateFormat = dividerChar =>
  getShortDateFormat() + dividerChar + getShortDateFormat();
export const getGeneralDateShortTimeFormat = () => t8(t`DD.MM.YY HH:mm`);
export const getUnifiedDateShortTimeFormat = () => t8(t`YYYY-MM-DDTHH:mm`);
export const getLabelTimeFormat = () => t8(t`MMM D, YYYY [at] HH:mm`);
export const getTimeFormat = () => t8(t`HH:mm`);

const fakeDateWithHours = hours => {
  return moment([1, 1, 1, hours]);
};

export const toFormattedUtcDateString = (date, format) => {
  return moment.utc(date).format(format);
};

export const fromFormattedUtcDateString = (date, format) => {
  return moment.utc(date, format, true);
};

export const toShortDateString = date => {
  return moment(date).format(getShortDateFormat());
};

export const toShortDateStringWithDayOfWeek = date => {
  return moment(date).format(getShortDateFormatWithDayOfWeek());
};

export const toShortDateStringWithMonth = date => {
  return moment(date).format(getShortDateFormatWithMonth());
};

export const toShortDateStringWithMonthAndTime = date => {
  return moment(date).format(getShortDateFormatWithMonthAndTime());
};

export const toGeneralDateShortTimeFormatString = date => {
  return moment(date).format(getGeneralDateShortTimeFormat());
};

export const toUnifiedDateShortTimeFormatString = date => {
  return moment(date).format(getUnifiedDateShortTimeFormat());
};

export const toTimeFormatString = date => {
  return moment(date).format(getTimeFormat());
};

export const toLabelFormatString = date => {
  return moment(date).format(getLabelTimeFormat());
};

export const serializeDate = date => {
  return moment(date).format('YYYY-MM-DDTHH:mm:ss');
};

export const serializeDateOnly = date => {
  return moment(date).format('YYYY-MM-DD');
};

export const isValidDate = date => {
  return moment(date).isValid();
};

export const utcDate = date => {
  return moment.utc(date);
};

export const momentDate = date => {
  return moment(date);
};

export const getUtcNow = () => {
  return moment.utc();
};

export const subtractDays = (date, daysCount) => {
  return moment(date).subtract(daysCount, 'day');
};

export const subtractMonths = (date, monthsCount) => {
  return moment(date).subtract(monthsCount, 'month');
};

export const subtractYears = (date, yearsCount) => {
  return moment(date).subtract(yearsCount, 'year');
};

export const addDays = (date, daysCount) => {
  return moment(date).add(daysCount, 'day');
};

export const addHours = (date, hoursCount) => {
  return moment(date).add(hoursCount, 'hour');
};

export const addMonths = (date, monthsCount) => {
  return moment(date).add(monthsCount, 'month');
};

export const addYears = (date, yearsToAdd) => {
  return date.clone().add(yearsToAdd, 'year');
};

export const addDuration = (date, duration) => {
  return moment(date).add(duration);
};

export const startOfDay = date => {
  return moment(date).startOf('day');
};

export const isStartOfDay = date => {
  return moment(date).isSame(startOfDay(date));
};

export const isAfter = (date, afterWhat) => {
  return moment(date).isAfter(afterWhat);
};

export const isSame = (date, afterWhat) => {
  return moment(date).isSame(afterWhat);
};

export const isSameDay = (date1, date2) => {
  return moment(date1).isSame(date2, 'day');
};

export const isSameOrAfter = (date, afterWhat) => {
  return moment(date).isSameOrAfter(afterWhat);
};

export const isSameOrAfterStartOfDay = (date, afterWhat) => {
  return isSameOrAfter(startOfDay(date), startOfDay(afterWhat));
};

export const isBefore = (date, beforeWhat) => moment(date).isBefore(beforeWhat);

export const isSameOrBefore = (date, beforeWhat) => {
  return moment(date).isSameOrBefore(beforeWhat);
};

export const isSameOrBeforeStartOfDay = (date, beforeWhat) => {
  return isSameOrBefore(startOfDay(date), startOfDay(beforeWhat));
};

export const formatTime = date => {
  return moment(date).format('HH:mm');
};

export const formatHour = hour => {
  return formatTime(fakeDateWithHours(hour));
};

export const getDurationDays = duration => {
  return moment.duration(duration).days();
};

export const getDurationAsHours = duration => {
  return moment.duration(duration).asHours();
};

export const getDuration = init => {
  return moment.duration(init);
};

export const getHour = date => {
  return moment(date).hour();
};

export const getMinutes = date => {
  return moment(date).minute();
};

export const isHourSharp = date => {
  return getMinutes(date) === 0;
};

export const formatDurationToString = init => {
  const duration = getDuration(init);
  return formatDurationAs(duration, 'DD.HH:mm:ss');
};

export const formatDurationAsOffset = init => {
  const duration = getDuration(init);
  const ms = duration.asMilliseconds();
  const hhmm = formatDurationAs(duration, 'HH:mm');
  if (ms > 0) {
    return `+${hhmm}`;
  }
  if (ms < 0) {
    return hhmm;
  }
  return '';
};

export const toDateTime = (date, time) => {
  return startOfDay(date)
    .add(moment.duration(time))
    .toDate();
};

export const toUtcDateInclusive = exclusiveEndDate => {
  const end = utcDate(exclusiveEndDate);
  return isStartOfDay(end) ? subtractDays(end, 1) : startOfDay(end);
};

export const toUtcDateExclusive = inclusiveEndDate => {
  const end = utcDate(inclusiveEndDate);
  return addDays(startOfDay(end), 1);
};

export const fractionPassed = (start, current, end) => {
  return moment(current).diff(start) / moment(end).diff(start);
};

export const daysBetween = (dateFrom, dateTo) => {
  return moment.duration(moment(dateTo).diff(dateFrom)).asDays();
};

export const isBetweenInclusive = (day, minimumDate, maximumDate) => {
  return moment(day).isBetween(
    moment(minimumDate),
    moment(maximumDate),
    null,
    '[]'
  );
};

export const getMinimumDate = dateArray => {
  return moment.min(dateArray);
};

export const getMaximumDate = dateArray => {
  return moment.max(dateArray);
};

export const getTime = date => {
  return date ? moment(date).valueOf() : Infinity;
};

// based on https://github.com/codebox/moment-precise-range
export const preciseDiff = (d1, d2) => {
  let m1 = moment(d1);
  let m2 = moment(d2);

  if (m1.isSame(m2)) {
    return '';
  }

  if (m1.isAfter(m2)) {
    const tmp = m1;
    m1 = m2;
    m2 = tmp;
  }

  let yDiff = m2.year() - m1.year();
  let mDiff = m2.month() - m1.month();
  let dDiff = m2.date() - m1.date();
  let hourDiff = m2.hour() - m1.hour();
  let minDiff = m2.minute() - m1.minute();
  let secDiff = m2.second() - m1.second();

  if (secDiff < 0) {
    secDiff = 60 + secDiff;
    minDiff--;
  }
  if (minDiff < 0) {
    minDiff = 60 + minDiff;
    hourDiff--;
  }
  if (hourDiff < 0) {
    hourDiff = 24 + hourDiff;
    dDiff--;
  }
  if (dDiff < 0) {
    const daysInLastFullMonth = moment(
      `${m2.year()}-${m2.month() + 1}`,
      'YYYY-MM'
    )
      .subtract(1, 'months')
      .daysInMonth();
    if (daysInLastFullMonth < m1.date()) {
      // 31/01 -> 2/03
      dDiff = daysInLastFullMonth + dDiff + (m1.date() - daysInLastFullMonth);
    } else {
      dDiff = daysInLastFullMonth + dDiff;
    }
    mDiff--;
  }
  if (mDiff < 0) {
    mDiff = 12 + mDiff;
    yDiff--;
  }

  moment.relativeTimeThreshold('s', 60);
  moment.relativeTimeThreshold('m', 60);
  moment.relativeTimeThreshold('h', 23);
  moment.relativeTimeThreshold('dd', 28);
  moment.relativeTimeThreshold('dm', 45);
  moment.relativeTimeThreshold('dy', 365);

  return {
    years: moment.duration(yDiff, 'year').asYears(),
    months: moment.duration(mDiff, 'month').asMonths(),
    days: moment.duration(dDiff, 'day').asDays(),
    hours: moment.duration(hourDiff, 'hour').asHours(),
    minutes: moment.duration(minDiff, 'minute').asMinutes(),
    seconds: moment.duration(secDiff, 'second').asSeconds(),
  };
};

const formatYearsLeft = years =>
  !years
    ? ''
    : t8(
        plural('dateUtils.years', {
          value: years,
          one: '1 year',
          other: '# years',
        })
      );
const formatMonthsLeft = months =>
  !months
    ? ''
    : t8(
        plural('dateUtils.months', {
          value: months,
          one: '1 month',
          other: '# months',
        })
      );
const formatDaysLeft = days =>
  !days
    ? ''
    : t8(
        plural('dateUtils.days', {
          value: days,
          one: '1 day',
          other: '# days',
        })
      );

export const formatDateDifference = ({ years, months, days }) => {
  return [
    formatYearsLeft(years),
    formatMonthsLeft(months),
    formatDaysLeft(days),
  ].join(' ');
};
