import React from 'react';
import clsx from 'clsx';
import { ArrowDropDownIcon } from '../../../assets';
import './Select.scss';
import Label from '../Label/Label';
import { useShrink } from '../Label/useShrink';
import SelectBase from '../SelectBase/SelectBase';
import { areEqualValues } from '../../../utils';

function isEmpty(display) {
  return display == null || (typeof display === 'string' && !display.trim());
}

const Select = props => {
  const {
    children,
    className,
    onChange,
    disabled,
    label,
    id,
    value = '',
    tabIndex = 0,
    fullWidth,
    name,
    onBlur,
    error,
    container = null,
    withTitle,
    endAdornment,
  } = props;

  const [focused, setFocused] = React.useState(false);
  const [displayNode, setDisplayNode] = React.useState(null);
  const [menuWidth, setMenuWidth] = React.useState();
  const [openState, setOpenState] = React.useState(false);
  const shrink = useShrink([focused, value]);

  const update = open => {
    if (open) {
      displayNode.focus();
    }

    setMenuWidth(displayNode.clientWidth);
    setOpenState(open);
  };

  const handleFocus = event => {
    if (disabled) {
      event.stopPropagation();
      return;
    }

    setFocused(true);
  };

  const handleBlur = () => {
    setFocused(false);
    if (onBlur) {
      onBlur({ target: { name } });
    }
  };

  const handleClick = () => {
    if (openState) {
      handleBlur();
    } else {
      handleFocus();
    }
    update(!openState);
  };

  const open = displayNode !== null && openState;

  const handleClickAway = event => {
    if (displayNode && displayNode.contains(event.target)) {
      return;
    }
    handleBlur();
    update(false);
  };

  const handleItemClick = child => event => {
    handleBlur();
    update(false, event);

    if (onChange) {
      const { value: selected } = child.props;
      event.persist();
      // eslint-disable-next-line no-param-reassign
      event.target = { value: selected };
      onChange(event, child);
    }
  };

  const handleItemMouseDown = event => event.preventDefault();

  const display = React.useMemo(() => {
    const selectedChild = children.find(child =>
      areEqualValues(value, child.props.value)
    );
    return selectedChild && selectedChild.props.children;
  }, [children, value]);

  return (
    <div
      className={clsx(
        'mg-input-base-root',
        {
          'mg-focused': focused,
          'mg-full-width': fullWidth,
          'mg-error': error,
          'mg-disabled': disabled,
        },
        className
      )}
    >
      <div
        className={clsx(
          'mg-select-root',
          'mg-input-base-input',
          'mg-input-select',
          {
            'mg-adorned-end': endAdornment,
          }
        )}
        tabIndex={disabled ? null : tabIndex}
        ref={setDisplayNode}
        onFocus={handleFocus}
        onClick={disabled ? null : handleClick}
        onKeyUp={() => {}}
        role="listbox"
        title={withTitle && display}
      >
        {isEmpty(display) ? <span>&#8203;</span> : display}
      </div>
      {label && <Label id={id} label={label} shrink={shrink} />}
      <input id={id} type="hidden" value={value || ''} name={name} />
      {endAdornment}
      <ArrowDropDownIcon
        className={clsx('mg-select-icon', { 'mg-open': openState })}
      />
      <SelectBase
        open={open}
        displayNode={displayNode}
        container={container}
        onClick={handleClick}
        onItemClick={handleItemClick}
        onClickAway={handleClickAway}
        menuWidth={menuWidth}
        value={value}
        onItemMouseDown={handleItemMouseDown}
      >
        {children}
      </SelectBase>
      <div aria-hidden className="mg-notched-outline-root" />
    </div>
  );
};

export default React.memo(Select);
