import React from 'react';
import { t } from '@lingui/macro';
import {
  FormControl,
  InputParser,
  FormInputWithLength,
  Pillow,
} from '../../Common';
import { t8 } from '../../../i18n/i18n';
import { isEmail } from '../../../utils';

const EmailConfiguration = ({ emailConfiguration }) => {
  const [
    { email, errors, touched },
    {
      handleSubjectChange,
      handleSubjectBlur,
      handleInputParsed,
      handleEmailAddressClear,
      handleEmailAddressBlur,
    },
  ] = emailConfiguration;

  return (
    <>
      <div className="mb-4">
        <FormControl
          error={touched.emailAddresses && errors.emailAddressesCount}
        >
          <InputParser
            autofocus={false}
            className="mg-full-width"
            onInputParsed={handleInputParsed}
            placeholder={t8(t`Email`)}
            onBlur={handleEmailAddressBlur}
            error={touched.emailAddresses && errors.emailAddressesCount}
          />
        </FormControl>
        <div className="mg-overflow-y-auto mt-1">
          {email.emailAddresses.map(e => (
            <Pillow
              key={e}
              title={e}
              className="mt-1"
              color={isEmail(e) ? 'light' : 'error'}
              onDelete={() => handleEmailAddressClear(e)}
            >
              {e}
            </Pillow>
          ))}
        </div>
      </div>
      <FormInputWithLength
        value={email.subject}
        onChange={handleSubjectChange}
        onBlur={handleSubjectBlur}
        label={t8(t`Subject`)}
        maxLength={100}
        error={touched.subject && errors.subject}
      />
    </>
  );
};

export default EmailConfiguration;
