import React from 'react';
import { Trans } from '@lingui/macro';
import Dialog from '../Dialog/index';
import DialogTitle from '../DialogTitle/index';
import DialogContent from '../DialogContent/index';
import DialogActions from '../DialogActions/index';
import Button from '../Button/index';

const DeleteConfirmationDialog = ({ onClose, onConfirm, children }) => {
  return (
    <Dialog open size="sm">
      <DialogTitle>
        <Trans>Delete?</Trans>
      </DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <Trans>Cancel</Trans>
        </Button>
        <Button color="error" onClick={onConfirm}>
          <Trans>Delete</Trans>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
