import React from 'react';
import { Trans, t } from '@lingui/macro';
import { toast } from 'react-toastify';
import { t8 } from '../../../../i18n/i18n';
import { NumericInput, Button, ToastMessage } from '../../../Common';
import VesselInfo from './VesselInfo';
import VesselAddInputs from './VesselAddInputs';

const VesselInsightsEdit = ({ vesselInsights, initialVesselInsights }) => {
  const [vesselCreating, setVesselCreating] = React.useState(false);
  const [
    { vesselInsightsModel, errors, touched },
    { onModelChange, onModelTouched },
  ] = vesselInsights;

  const handleCreateVessel = () => {
    setVesselCreating(true);
  };

  const handleVesselClose = () => {
    setVesselCreating(false);
  };

  const handleVesselCreated = React.useCallback(
    vessel => {
      setVesselCreating(false);
      onModelChange(prev => [...prev.vessels, vessel], 'vessels');
      toast.dismiss();
    },
    [onModelChange]
  );

  const handleVesselDeleted = React.useCallback(
    vessel => {
      onModelChange(prev => {
        return prev.vessels.filter(ves => ves !== vessel);
      }, 'vessels');
      toast(
        <ToastMessage
          text={t8(t`Vessel has been deleted.`)}
          actionsAreaComponent={
            <Button
              borderless
              onClick={() => {
                handleVesselCreated(vessel);
              }}
            >
              <Trans>UNDO</Trans>
            </Button>
          }
        />
      );
    },
    [handleVesselCreated, onModelChange]
  );

  React.useEffect(() => {
    if (initialVesselInsights) {
      onModelChange(initialVesselInsights.vessels, 'vessels');
      onModelChange(initialVesselInsights.routeGuardId, 'routeGuardId');
    }
    return () => {
      toast.dismiss();
    };
  }, [initialVesselInsights, onModelChange]);

  return (
    <>
      <h6>
        <Trans>Insert Vessel Insight information</Trans>
      </h6>
      <NumericInput
        className="mt-2"
        disabled={initialVesselInsights && initialVesselInsights.routeGuardId}
        fullWidth
        maxLength={100}
        label={<Trans>RouteGuard ID</Trans>}
        value={vesselInsightsModel.routeGuardId}
        onChange={v => onModelChange(v, 'routeGuardId')}
        onBlur={() => onModelTouched('routeGuardId')}
        error={touched.routeGuardId && errors.routeGuardId}
      />
      {touched.routeGuardId && errors.routeGuardId && (
        <p className="mg-error-message mt-1">{errors.routeGuardId}</p>
      )}
      <p className="mg-body-2-gray mt-2">
        <Trans>
          Create observed vessels. Note that changes will affect all users of
          this customer.
        </Trans>
      </p>
      <div className="d-flex row mt-2 mx-0 mg-body-2">
        {vesselInsightsModel.vessels && vesselInsightsModel.vessels.length ? (
          vesselInsightsModel.vessels
            .sort((v1, v2) => v2.imo - v1.imo)
            .map(v => {
              return (
                <div className="col-4 p-1" key={`${v.imo}-${v.mmsi}`}>
                  <VesselInfo
                    vessel={v}
                    onDelete={() => handleVesselDeleted(v)}
                  />
                </div>
              );
            })
        ) : (
          <p className="mg-body-2-gray mt-2">
            <Trans>
              No observed vessels have been set. Please, create one.
            </Trans>
          </p>
        )}
      </div>
      {vesselCreating ? (
        <VesselAddInputs
          vessels={vesselInsightsModel.vessels}
          onCreate={handleVesselCreated}
          onClose={handleVesselClose}
        />
      ) : (
        <Button
          className="mt-1 ml-1"
          size="small"
          color="secondary"
          onClick={() => handleCreateVessel()}
        >
          <Trans>Add</Trans>
        </Button>
      )}
    </>
  );
};

export default VesselInsightsEdit;
