import React from 'react';
import './LinkButton.scss';
import clsx from 'clsx';

const LinkButton = ({
  className,
  children,
  disabled,
  startIcon: startIconProp,
  onClick,
  variant,
}) => {
  const iconMargin = variant === 'dense' ? 'mr-1' : 'mr-2';
  const startIcon = startIconProp && (
    <span className={clsx('d-flex', { [iconMargin]: children })}>
      {startIconProp}
    </span>
  );

  return (
    <button
      disabled={disabled}
      type="button"
      className={clsx(className, 'mg-link-button mg-link mg-body-1', {
        'mg-disabled': disabled,
      })}
      onClick={onClick}
    >
      {startIcon}
      {children}
    </button>
  );
};

export default LinkButton;
