import { useState, useEffect, useMemo } from 'react';
import { hasActiveErrors, debounceAndResolveLast } from '../../../utils/index';

const useValidationBase = (state, validate, initErrors, initTouched, delay) => {
  const [touched, setTouched] = useState(initTouched);
  const [errors, setErrors] = useState(initErrors);
  const [pending, setPending] = useState(false);

  const wrappedValidator = useMemo(
    () => debounceAndResolveLast(validate, delay),
    [delay, validate]
  );
  useEffect(() => {
    setPending(true);
    wrappedValidator(state).then(e => {
      setPending(false);
      setErrors(e);
    });
  }, [state, wrappedValidator]);

  const containsActiveErrors = useMemo(() => {
    return hasActiveErrors(errors, touched);
  }, [errors, touched]);

  return [errors, touched, setTouched, containsActiveErrors, pending];
};

export const useValidation = (state, validate, initErrors, initTouched) => {
  return useValidationBase(state, validate, initErrors, initTouched, 0);
};

const asyncValidationDelayMs = 100;

export const useAsyncValidation = (
  state,
  validate,
  initErrors,
  initTouched
) => {
  return useValidationBase(
    state,
    validate,
    initErrors,
    initTouched,
    asyncValidationDelayMs
  );
};
