import React from 'react';
import clsx from 'clsx';
import FormControl from '../FormControl/FormControl';
import Input from '../Input/Input';

const FormInputWithLength = ({
  className,
  value,
  onChange,
  label,
  maxLength,
  error,
  name,
  endAdornment,
  multiline,
  disabled,
  onBlur,
  rowsMin,
}) => {
  return (
    <>
      <FormControl error={error}>
        <Input
          className={className}
          fullWidth
          label={label}
          error={error}
          value={value}
          onChange={onChange}
          maxLength={maxLength}
          name={name}
          multiline={multiline}
          disabled={disabled}
          onBlur={onBlur}
          endAdornment={endAdornment}
          rowsMin={rowsMin}
        />
      </FormControl>
      <p
        className={clsx(
          'mg-body-2-gray',
          'd-flex',
          'justify-content-end',
          'pt-1',
          'mr-3',
          { 'mg-name-length-position': error }
        )}
      >
        {value ? value.length : 0} / {maxLength}
      </p>
    </>
  );
};

export default FormInputWithLength;
