import React from 'react';

export const useConfirmationDialog = callback => {
  const [open, setOpen] = React.useState();

  const onOpen = React.useCallback(() => setOpen(true), []);
  const onClose = React.useCallback(() => setOpen(false), []);

  const onConfirm = React.useCallback(() => {
    callback();
    onClose();
  }, [callback, onClose]);

  return [{ open }, { onOpen, onClose, onConfirm }];
};
