import React from 'react';
import Popper from '../Popper/Popper';
import ClickAwayListener from '../ClickAwayListener/ClickAwayListener';
import Paper from '../Paper/Paper';
import List from '../List/List';
import { areEqualValues } from '../../../utils';

const SelectBase = ({
  open,
  displayNode,
  container,
  onItemClick,
  onClickAway,
  menuWidth,
  children,
  value,
  onItemMouseDown,
}) => {
  const items = React.Children.map(children, child => {
    if (!React.isValidElement(child)) {
      return null;
    }

    const selected = areEqualValues(value, child.props.value);
    return React.cloneElement(child, {
      'aria-selected': selected ? 'true' : undefined,
      onClick: onItemClick(child),
      onMouseDown: onItemMouseDown,
      role: 'option',
      selected,
      value: undefined, // The value is most likely not a valid HTML attribute.
      'data-value': child.props.value, // Instead, we provide it as a data attribute.
    });
  });

  return (
    <Popper
      className="mg-select-popper"
      open={open}
      anchorEl={displayNode}
      container={container}
      disablePortal={!container}
    >
      <ClickAwayListener onClickAway={onClickAway}>
        <Paper
          className="mg-select-paper mg-overflow-y-auto"
          style={{
            width: menuWidth,
          }}
        >
          <List>{items}</List>
        </Paper>
      </ClickAwayListener>
    </Popper>
  );
};

export default SelectBase;
