import React from 'react';
import clsx from 'clsx';
import Input from '../Input/Input';
import { splitTextFiltering } from '../../../utils/parsing-utils';
import { KEYS } from '../../../constants';

const InputParser = ({
  onInputParsed,
  onBlur,
  error,
  className,
  placeholder,
  startAdornment,
  autofocus = true,
  separatorKeys,
  triggerKeys = [KEYS.ENTER, KEYS.TAB],
  triggerOnPaste = true,
  triggerOnBlur = true,
}) => {
  const [inputValue, setInputValue] = React.useState('');

  const handleChange = React.useCallback(event => {
    setInputValue(event.target.value);
  }, []);

  const handleInputAccepted = React.useCallback(
    text => {
      const newItems = splitTextFiltering(text, separatorKeys);
      onInputParsed(newItems);
      setInputValue('');
    },
    [onInputParsed, separatorKeys]
  );

  const handleKeyDown = React.useCallback(
    event => {
      if (inputValue && triggerKeys.includes(event.key)) {
        event.preventDefault();
        handleInputAccepted(inputValue);
      }
    },
    [handleInputAccepted, inputValue, triggerKeys]
  );

  const handlePaste = React.useCallback(
    event => {
      event.preventDefault();
      const paste = event.clipboardData.getData('text');
      if (triggerOnPaste) {
        handleInputAccepted(paste);
      } else {
        setInputValue(paste);
      }
    },
    [handleInputAccepted, triggerOnPaste]
  );

  const handleBlur = React.useCallback(
    event => {
      if (triggerOnBlur) {
        handleInputAccepted(inputValue);
      }
      if (onBlur) {
        onBlur(event);
      }
    },
    [handleInputAccepted, inputValue, onBlur, triggerOnBlur]
  );

  return (
    <Input
      autoFocus={autofocus}
      className={clsx(className)}
      value={inputValue}
      error={error}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      onPaste={handlePaste}
      placeholder={placeholder}
      onBlur={handleBlur}
      startAdornment={startAdornment}
    />
  );
};

export default InputParser;
