import { recentSearchesMaxCount } from '../../../config';
import { filterUnique } from '../../../utils/index';

export const getRecentSearches = (state, action) => {
  return {
    ...state.recentSearches,
    [action.searchTerm.searchField]: [
      action.searchTerm.searchValue.trim(),
      ...((state.recentSearches &&
        state.recentSearches[action.searchTerm.searchField]) ||
        []),
    ]
      .filter(term => term)
      .filter(filterUnique)
      .slice(0, recentSearchesMaxCount),
  };
};
