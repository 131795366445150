import React from 'react';
import clsx from 'clsx';

const Paper = React.forwardRef(function Paper(props, ref) {
  const {
    className: classNameProp,
    component: Component = 'div',
    elevation = 2,
    ...other
  } = props;

  const className = clsx(
    'mg-paper-root',
    'mg-paper-rounded',
    [`mg-paper-elevation${elevation}`],
    classNameProp
  );

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component className={className} ref={ref} {...other} />;
});

export default Paper;
