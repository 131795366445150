import { getPropByPath, isEmptyObject } from '../../../utils/index';

const isInStore = (state, path) => {
  return state && getPropByPath(state, path);
};

const isFetchingOrFailed = state => {
  return state && (state.isFetching || !isEmptyObject(state.error));
};

export const shouldLoadUnlessInStore = (state, entityName) => {
  return !isInStore(state, entityName) && !isFetchingOrFailed(state);
};

export const shouldLoadUnlessFetching = state => {
  return !isFetchingOrFailed(state);
};
