import React from 'react';
import { Trans, t } from '@lingui/macro';
import { t8 } from '../../../../i18n/i18n';
import { useCustomers } from '../../../../store/hooks/useCustomers';
import {
  Dialog,
  Loading,
  DialogTitle,
  DialogContent,
  Description,
  IconButton,
  ListItem,
  DialogActions,
  Button,
  SearchInput,
  List,
  FramedContainer,
  FixErrorsTooltip,
} from '../../../Common';
import { EditIcon } from '../../../../assets/index';
import { useValidation } from '../../../Common/Form/useValidation';
import { validate } from './validators';
import { hasErrors } from '../../../../utils/index';

const ContractSelectDialog = ({
  onClose,
  onContinue,
  customerDescription = (
    <Trans>Select a customer to associate with created user.</Trans>
  ),
}) => {
  const [state, setState] = React.useState({
    selectedCustomer: null,
  });

  const [errors, touched, setTouched, containsActiveErrors] = useValidation(
    state,
    validate,
    {},
    {}
  );

  const setNothingTouched = () => {
    setTouched({
      selectedCustomer: false,
    });
  };

  const setAllTouched = () => {
    setTouched({
      ...touched,
      selectedCustomer: true,
    });
  };

  const [
    { customers, isFetching: isCustomerFetching, searchTerm },
    { setSearchTerm, reset },
  ] = useCustomers('userCreate');

  const handleClose = () => {
    reset();
    onClose();
  };

  const handleSearchingChange = search => {
    setSearchTerm(search);
    setNothingTouched();
  };

  const handleCustomerChange = customerId => {
    setState(prev => {
      return {
        ...prev,
        selectedCustomer: customers.find(
          c => c.customerDetails.salesforceAccountId === customerId
        ),
      };
    });

    setTouched(prev => {
      return {
        ...prev,
        selectedCustomer: true,
      };
    });
  };

  const handleContinue = () => {
    if (hasErrors(errors)) {
      setAllTouched();
    } else {
      reset();
      onContinue(state.selectedCustomer);
    }
  };

  const handleCustomerReset = () => {
    setState({
      selectedCustomer: null,
    });
    setNothingTouched();
    reset();
  };

  return (
    <Dialog open size="sm">
      {isCustomerFetching && <Loading />}
      <DialogTitle onClose={handleClose}>
        <Trans>Select associated customer</Trans>
      </DialogTitle>
      <DialogContent>
        <Description className="mb-4">
          {customerDescription}{' '}
          <Trans>Customer search must contain at least 3 symbols.</Trans>
        </Description>
        {state.selectedCustomer ? (
          <div className="d-flex justify-content-between">
            <div
              className="mg-overflow-ellipsis mt-1"
              title={state.selectedCustomer.customerDetails.name}
            >
              {state.selectedCustomer.customerDetails.name}
            </div>
            <IconButton onClick={handleCustomerReset}>
              <EditIcon />
            </IconButton>
          </div>
        ) : (
          <>
            <SearchInput
              fullWidth
              searchTerm={searchTerm}
              error={touched.selectedCustomer && errors.selectedCustomer}
              placeholder={t8(t`Search customers`)}
              onSearchClick={value => {
                handleSearchingChange(value);
              }}
            />
            {touched.selectedCustomer && errors.selectedCustomer && (
              <p className="mg-error-message mt-1 ml-3">
                {errors.selectedCustomer}
              </p>
            )}
            {customers && !!customers.length && (
              <FramedContainer className="mt-2">
                <List>
                  {customers.map(c => {
                    return (
                      <ListItem
                        key={c.customerDetails.salesforceAccountId}
                        value={c.customerDetails.salesforceAccountId}
                        title={c.customerDetails.name}
                        onClick={() =>
                          handleCustomerChange(
                            c.customerDetails.salesforceAccountId
                          )
                        }
                      >
                        {c.customerDetails.name}
                      </ListItem>
                    );
                  })}
                </List>
              </FramedContainer>
            )}
            {searchTerm &&
              !isCustomerFetching &&
              (!customers || !customers.length) && (
                <Description className="mt-2">
                  <Trans>There are no customers found.</Trans>
                </Description>
              )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>
          <Trans>Cancel</Trans>
        </Button>
        <FixErrorsTooltip show={containsActiveErrors}>
          <Button
            color="secondary"
            onClick={handleContinue}
            disabled={containsActiveErrors}
          >
            <Trans>Continue</Trans>
          </Button>
        </FixErrorsTooltip>
      </DialogActions>
    </Dialog>
  );
};

export default ContractSelectDialog;
