import React from 'react';
import Input from '../Input/Input';
import InputAdornment from '../InputAdornment/InputAdornment';
import IconButton from '../IconButton/IconButton';
import { VisibilityIcon, VisibilityOffIcon } from '../../../assets';

const PasswordInput = ({
  value,
  endAdornmentIcon,
  hidePasswordView = false,
  onChange,
  ...other
}) => {
  const [showPassword, setShowPassword] = React.useState(false);
  return (
    <Input
      value={value}
      onChange={onChange}
      type={!hidePasswordView && showPassword ? 'text' : 'password'}
      endAdornment={
        <InputAdornment position="end">
          {endAdornmentIcon}
          {!hidePasswordView && (
            <IconButton
              onClick={() => {
                setShowPassword(!showPassword);
              }}
            >
              {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
            </IconButton>
          )}
        </InputAdornment>
      }
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    />
  );
};

export default PasswordInput;
