import React from 'react';
import ReactDOM from 'react-dom';

const Portal = ({ children, container, disablePortal = false }) => {
  const [mountNode, setMountNode] = React.useState(null);

  React.useEffect(() => {
    if (!disablePortal) {
      setMountNode(container || document.body);
    }
  }, [container, disablePortal]);

  if (disablePortal) {
    return children;
  }

  return mountNode ? ReactDOM.createPortal(children, mountNode) : mountNode;
};

export default Portal;
