import React from 'react';
import { useAuth0 } from '../react-auth0-spa';
import { checkPermissions } from './checkPermissions';
import appRules from './rules';

export const useUserPermissions = () => {
  const { user } = useAuth0();

  const hasPermissions = React.useCallback(
    (permission, data) => {
      return (
        user &&
        user.roles.some(role =>
          checkPermissions(appRules, role, permission, data, user)
        )
      );
    },
    [user]
  );

  return hasPermissions;
};
