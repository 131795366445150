import { ERROR_SET } from '../actions/error';

export const error = (state = {}, action) => {
  switch (action.type) {
    case ERROR_SET:
      return {
        ...state,
        error: action.error,
      };
    default:
      return state;
  }
};
