import React from 'react';
import { useFetchFromStore } from './utils/useFetchFromStore';
import { loadRolesIfNeeded } from '../actions/index';
import { Role } from '../../auth/app-permissions/role';

export const useRoles = () => {
  const { isFetching, roles } = useFetchFromStore(
    loadRolesIfNeeded,
    state => state.roles
  );

  const adminRoleId = React.useMemo(
    () => roles && roles.find(r => r.name === Role.Admin).id,
    [roles]
  );

  const vesselInsightsRoleId = React.useMemo(
    () => roles && roles.find(r => r.name === Role.VesselInsights).id,
    [roles]
  );

  return {
    isFetching,
    roles,
    adminRoleId,
    vesselInsightsRoleId,
  };
};
