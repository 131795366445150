import React from 'react';
import { Trans, t } from '@lingui/macro';
import { t8 } from '../../../../i18n/i18n';
import {
  TableSortLabel,
  SearchInput,
  SearchInputWithOptions,
  ListItem,
  Loading,
} from '../../../Common';
import { Sorting } from '../../../../models/sorting';
import { useCustomers } from '../../../../store/hooks/useCustomers';
import { useRoles } from '../../../../store/hooks/useRoles';

const TenantsTableHeader = ({ tenants, onSearchTermChange, onSort }) => {
  const [roleSearchTerm, setRoleSearchTerm] = React.useState('');
  const { isFetching, roles } = useRoles();

  const {
    sortingDirection,
    sortingField,
    searchTerms,
    recentSearches,
  } = tenants;
  const columns = [
    {
      className: 'mg-col-name',
      label: t8(t`Name`),
      sortingField: 'name',
    },
    {
      className: 'mg-col-description',
      label: t8(t`Description`),
      sortingField: 'description',
    },
    {
      className: 'mg-col-customer',
      label: t8(t`Customer`),
      sortingField: 'customer',
    },
  ];

  const handleSortingChange = column => {
    onSort({
      sortingField: column.sortingField,
      sortingDirection:
        column.sortingField === sortingField &&
        sortingDirection !== Sorting.DESC
          ? Sorting.DESC
          : Sorting.ASC,
    });
  };

  const handleSearchTermChange = (searchField, searchValue) => {
    onSearchTermChange({ searchField, searchValue });
  };

  const [
    {
      customers,
      isFetching: isCustomerFetching,
      searchTerm: customerSearchTerm,
    },
    { setSearchTerm, reset },
  ] = useCustomers('tenantTable');

  const handleSearch = searchValue => {
    setSearchTerm(searchValue);
  };

  const handleCustomerReset = () => {
    reset();
    handleSearchTermChange('customer', '');
  };

  const handleCustomerClick = e => {
    const customer = e.target.value;
    handleSearch(customer.name);
    handleSearchTermChange('customer', customer.salesforceAccountId);
  };

  const handleRoleSearch = v => {
    setRoleSearchTerm(v);
  };

  const handleRoleClick = e => {
    const role = e.target.value;
    handleRoleSearch(role.name);
    handleSearchTermChange('role', role.id);
  };

  const handleRoleReset = () => {
    handleSearchTermChange('role', '');
    setRoleSearchTerm('');
  };

  const filteredRoles = React.useMemo(() => {
    return (
      roles &&
      roles.length &&
      roles.filter(r =>
        r.name.toLowerCase().includes(roleSearchTerm.toLowerCase())
      )
    );
  }, [roleSearchTerm, roles]);

  return (
    <thead>
      {(isFetching || isCustomerFetching) && <Loading />}
      <tr>
        {columns.map(column => (
          <th className={column.className} key={column.label}>
            <TableSortLabel
              active={
                column.sortingField && sortingField === column.sortingField
              }
              direction={sortingDirection}
              onClick={() => handleSortingChange(column)}
            >
              <span className="mg-overflow-ellipsis" title={column.label}>
                {column.label}
              </span>
            </TableSortLabel>
          </th>
        ))}
        <th className="mg-col-roles">
          <Trans>Default roles</Trans>
        </th>
        <th className="mg-col-actions">
          <Trans>Actions</Trans>
        </th>
      </tr>
      <tr>
        <th>
          <SearchInput
            placeholder={t8(t`Enter Name`)}
            searchTerm={searchTerms && searchTerms.name}
            onSearchClick={value => handleSearchTermChange('name', value)}
            recentSearches={recentSearches.name}
          />
        </th>
        <th>
          <SearchInput
            placeholder={t8(t`Enter Description`)}
            searchTerm={searchTerms && searchTerms.description}
            onSearchClick={value =>
              handleSearchTermChange('description', value)
            }
            recentSearches={recentSearches.description}
          />
        </th>
        <th>
          <SearchInputWithOptions
            fullWidth
            placeholder={t8(t`Enter Customer`)}
            searchTerm={customerSearchTerm}
            onSearch={handleSearch}
            onClick={handleCustomerClick}
            onReset={handleCustomerReset}
          >
            {customers &&
              customers.length &&
              customers.map(entry => {
                return (
                  <ListItem
                    key={entry.customerDetails.salesforceAccountId}
                    title={entry.customerDetails.name}
                    value={entry.customerDetails}
                  >
                    {entry.customerDetails.name}
                  </ListItem>
                );
              })}
            {(!customers || !customers.length) &&
              (customerSearchTerm.length > 2 ? (
                <ListItem title={t8(t`No customer found`)} disabled>
                  <Trans>No customer found</Trans>
                </ListItem>
              ) : (
                <ListItem
                  title={t8(t`Search must contain at least 3 symbols`)}
                  disabled
                >
                  <Trans>Search must contain at least 3 symbols</Trans>
                </ListItem>
              ))}
          </SearchInputWithOptions>
        </th>
        <th>
          <SearchInputWithOptions
            fullWidth
            placeholder={t8(t`Enter Role`)}
            searchTerm={roleSearchTerm}
            onSearch={handleRoleSearch}
            onClick={handleRoleClick}
            onReset={handleRoleReset}
          >
            {filteredRoles && filteredRoles.length ? (
              filteredRoles.map(entry => {
                return (
                  <ListItem key={entry.id} title={entry.name} value={entry}>
                    {entry.name}
                  </ListItem>
                );
              })
            ) : (
              <ListItem title={t8(t`No roles found`)} disabled>
                <Trans>No roles found</Trans>
              </ListItem>
            )}
          </SearchInputWithOptions>
        </th>
        <th />
      </tr>
    </thead>
  );
};

export default TenantsTableHeader;
