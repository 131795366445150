import React from 'react';
import { Trans } from '@lingui/macro';
import EmptyMessage from '../EmptyMessage/EmptyMessage';

const NoMatchesMessage = ({ className }) => {
  return (
    <EmptyMessage className={className}>
      <Trans>There are no results matching the filters found.</Trans>
    </EmptyMessage>
  );
};

export default NoMatchesMessage;
