import React from 'react';
import { Trans } from '@lingui/macro';
import Dialog from '../Dialog/index';
import DialogContent from '../DialogContent/index';
import DialogActions from '../DialogActions/index';
import Button from '../Button/index';
import DialogTitle from '../DialogTitle/index';

const ConfirmationDialog = ({
  onClose,
  title,
  children,
  onConfirm,
  cancelButtonName,
  confirmButtonName,
  confirmButtonColor,
  size = 'sm',
}) => {
  return (
    <Dialog open size={size}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button borderless onClick={onClose}>
          {cancelButtonName || <Trans>Cancel</Trans>}
        </Button>
        <Button borderless onClick={onConfirm} color={confirmButtonColor}>
          {confirmButtonName || <Trans>Yes</Trans>}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialog;
