import { buildPageUri, buildUri } from '../../services/buildUri';
import { Sorting } from '../../models/sorting';
import {
  getHttpActionNames,
  buildHttpGetAction,
  buildHttpPostAction,
  buildHttpDeleteAction,
  buildHttpPatchAction,
} from './utils/httpActions';

export const UsersReadActionNames = getHttpActionNames('USERS');
export const UsersCreateActionNames = getHttpActionNames('USERS_CREATE');
export const UsersDeleteActionNames = getHttpActionNames('USERS_DELETE');
export const UsersUpdateActionNames = getHttpActionNames('USERS_UPDATE');
export const UsersRotateSecretActionNames = getHttpActionNames('USERS_ROTATE');

export const USERS_SORTING_CHANGE = 'USERS_SORTING_CHANGE';
export const USERS_SEARCH_TERM_CHANGE = 'USERS_SEARCH_TERM_CHANGE';
export const USERS_FILTERS_CLEARED = 'USERS_FILTERS_CLEARED';

export const loadUsers = (pageNumber, onSuccess) => (dispatch, getState) => {
  const {
    users: { sortingField, sortingDirection, searchTerms },
  } = getState();
  const url = buildPageUri('users', pageNumber, {
    sortingField,
    ascending: sortingDirection === Sorting.ASC,
    searches: JSON.stringify(searchTerms),
  });
  const action = buildHttpGetAction(UsersReadActionNames, url, onSuccess);

  return dispatch(action);
};

export const createUser = (user, onSuccess) => dispatch => {
  const url = buildUri('users');
  const action = buildHttpPostAction(
    UsersCreateActionNames,
    url,
    user,
    onSuccess
  );
  dispatch(action);
};

export const deleteUsers = (userIds, onSuccess) => dispatch => {
  const url = buildUri('users');
  const action = buildHttpDeleteAction(
    UsersDeleteActionNames,
    url,
    userIds,
    onSuccess
  );
  dispatch(action);
};

export const updateUser = (user, onSuccess) => dispatch => {
  const url = buildUri(`users/${user.id}`);
  const action = buildHttpPatchAction(
    UsersUpdateActionNames,
    url,
    user,
    onSuccess
  );
  dispatch(action);
};

export const setUsersSorting = sorting => dispatch => {
  dispatch({
    type: USERS_SORTING_CHANGE,
    sorting,
  });
};

export const setUsersSearchTerm = searchTerm => dispatch => {
  dispatch({
    type: USERS_SEARCH_TERM_CHANGE,
    searchTerm,
  });
};

export const clearUsersFilters = () => dispatch => {
  dispatch({
    type: USERS_FILTERS_CLEARED,
  });
};

export const rotateUserCredentials = (userId, onSuccess) => dispatch => {
  const url = buildUri(`users/rotate/${userId}`);
  const action = buildHttpPostAction(
    UsersRotateSecretActionNames,
    url,
    null,
    onSuccess
  );
  dispatch(action);
};
