import moment from 'moment';
import { t } from '@lingui/macro';
import {
  toFormattedUtcDateString,
  addYears,
  subtractYears,
} from '../../../utils';
import { t8 } from '../../../i18n/i18n';

export const getNextMonth = date => {
  return date.clone().add(1, 'month');
};

export const getPreviousMonth = date => {
  return date.clone().subtract(1, 'month');
};

export const getNextYear = date => {
  return addYears(date, 1);
};

export const getPreviousYear = date => {
  return subtractYears(date, 1);
};

export const getWeekdays = () => {
  return t8(t('weekdays')`SMTWTFS`).split('');
};

export const getHours = () => {
  const hoursPerDay = 24;
  return new Array(hoursPerDay).fill().map((_, i) => i);
};

export const getCalendarHeaderText = date => {
  return toFormattedUtcDateString(date, 'MMMM YYYY');
};

export const getShortMonthText = date => {
  return toFormattedUtcDateString(date, 'MMM');
};

export const getYearText = date => {
  return toFormattedUtcDateString(date, 'YYYY');
};

export const getMonth = date => {
  return moment.utc(date).get('month');
};

export const getYear = date => {
  return moment.utc(date).get('year');
};

export const isSameMonth = (date1, date2) => {
  return getMonth(date1) === getMonth(date2);
};

export const startOfMonth = date => {
  return date.clone().startOf('month');
};

export const getWeekArray = date => {
  const momentDate = moment(date);
  const start = momentDate
    .clone()
    .startOf('month')
    .startOf('week');
  const end = momentDate
    .clone()
    .endOf('month')
    .endOf('week');

  let count = 0;
  let current = start;
  const nestedWeeks = [];

  while (current.isBefore(end)) {
    const weekNumber = Math.floor(count / 7);
    nestedWeeks[weekNumber] = nestedWeeks[weekNumber] || [];
    nestedWeeks[weekNumber].push(current);

    current = current.clone().add(1, 'day');
    count += 1;
  }

  return nestedWeeks;
};

export const getMonthArray = date => {
  const firstMonth = date.clone().startOf('year');
  const monthArray = [firstMonth];

  while (monthArray.length < 12) {
    const prevMonth = monthArray[monthArray.length - 1];
    monthArray.push(getNextMonth(prevMonth));
  }

  return monthArray;
};

export const getYearRange = (start, end) => {
  const startDate = moment(start).startOf('year');
  const endDate = moment(end).endOf('year');
  const years = [];

  let current = startDate;
  while (current.isBefore(endDate)) {
    years.push(current);
    current = current.clone().add(1, 'year');
  }

  return years;
};
