import React from 'react';
import FormatInputBase from '../FormatInput/FormatInputBase';

const StatefulInput = ({
  value,
  onChange,
  isStringValid = () => true,
  stringToValue = v => v,
  valueToString = v => v,
  accept,
  format,
  replace,
  mask,
  ...other
}) => {
  const [state, setState] = React.useState('');

  const handleChange = string => {
    setState(string);
    if (isStringValid(string)) {
      const newValue = stringToValue(string);
      onChange(newValue);
    }
  };

  React.useEffect(() => {
    const newState = valueToString(value);
    setState(newState);
  }, [value, valueToString]);

  return (
    <FormatInputBase
      accept={accept}
      format={format}
      mask={mask}
      replace={replace}
      value={state}
      onChange={handleChange}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    />
  );
};

export default StatefulInput;
