import React from 'react';
import clsx from 'clsx';

const FormControl = ({ error, children, className }) => {
  return (
    <div className={clsx('mg-form-control', className)}>
      {children}
      {error && <p className="mg-error-message mt-1 ml-3">{error}</p>}
    </div>
  );
};

export default FormControl;
