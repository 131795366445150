import React from 'react';
import { Trans } from '@lingui/macro';
import {
  InputAdornment,
  Input,
  Tooltip,
  IconButton,
  PasswordInput,
  CanUser,
} from '../../Common';
import { ContentCopyIcon } from '../../../assets/index';
import { useUserPermissions } from '../../../auth/app-permissions/useUserPermissions';
import { Permission } from '../../../auth/app-permissions/permission';
import { copyToClipboard } from '../../../utils';

const UserDetailMachineCredentials = ({ user }) => {
  const defaultSecret = '#'.repeat(64);
  const hasPermission = useUserPermissions();
  return (
    <>
      <h5 className="mb-2">
        <Trans>Machine user credentials</Trans>
      </h5>
      <Input
        fullWidth
        className="my-2"
        label={<Trans>Client ID</Trans>}
        disabled
        value={user.user.userName}
        endAdornment={
          <InputAdornment position="end">
            <Tooltip fullWidth title={<Trans>Copy to clipboard</Trans>}>
              <IconButton onClick={() => copyToClipboard(user.user.userName)}>
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        }
      />
      <PasswordInput
        className="my-2"
        disabled
        fullWidth
        label={<Trans>Client secret</Trans>}
        hidePasswordView={!hasPermission(Permission.UsersViewSecret)}
        value={
          hasPermission(Permission.UsersViewSecret)
            ? user.userMachineInfo.clientSecret
            : defaultSecret
        }
        endAdornmentIcon={
          <CanUser permission={Permission.UsersViewSecret}>
            <Tooltip fullWidth title={<Trans>Copy to clipboard</Trans>}>
              <IconButton
                onClick={() =>
                  copyToClipboard(user.userMachineInfo.clientSecret)
                }
              >
                <ContentCopyIcon />
              </IconButton>
            </Tooltip>
          </CanUser>
        }
      />
    </>
  );
};

export default UserDetailMachineCredentials;
