import React from 'react';
import { isNullOrEmpty, isNumber } from '../../../utils/index';
import StatefulInput from '../StatefulInput/StatefulInput';
import utils from './point-separated-utils';

const { parseFractionalNumber, fractional, formatFractionalNumber } = utils;
const valueToString = v => {
  return formatFractionalNumber(v);
};

const stringToValue = s => {
  return isNullOrEmpty(s) ? null : parseFractionalNumber(s);
};

const isStringValid = s => {
  return isNullOrEmpty(s) || isNumber(s);
};

const FractionalNumericInput = ({ value, maxLength, onChange, ...other }) => {
  return (
    <StatefulInput
      accept={fractional}
      format={formatFractionalNumber}
      value={value}
      onChange={onChange}
      maxLength={maxLength}
      valueToString={valueToString}
      stringToValue={stringToValue}
      isStringValid={isStringValid}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    />
  );
};

export default FractionalNumericInput;
