import { combineReducers } from 'redux';
import { locale } from './locale';
import { error } from './error';
import { users } from './users';
import { customers } from './customers';
import { contracts } from './contracts';
import { roles } from './roles';
import { roleGroups } from './roleGroups';
import { tenants } from './tenants';
import { connections } from './connections';
import { roleRules } from './roleRules';

const rootReducer = combineReducers({
  locale,
  error,
  users,
  customers,
  contracts,
  roles,
  roleGroups,
  tenants,
  connections,
  roleRules,
});

export default rootReducer;
