import React from 'react';
import FractionalNumericInput from './FractionalNumericInput';
import utils from './point-separated-utils';

const { formatPositiveInteger } = utils;

const NumericInput = ({ value, onChange, maxLength, ...other }) => {
  return (
    <FractionalNumericInput
      format={formatPositiveInteger}
      value={value}
      onChange={onChange}
      maxLength={maxLength}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...other}
    />
  );
};

export default NumericInput;
