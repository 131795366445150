import { getHttpActionNames, buildHttpGetAction } from './utils/httpActions';
import { buildUri } from '../../services/buildUri';

export const ContractsReadActionNames = getHttpActionNames('CONTRACTS');

const fetchContracts = customerId => dispatch => {
  const url = buildUri(`contracts/customer/${customerId}`);
  const action = buildHttpGetAction(ContractsReadActionNames, url);
  dispatch(action);
};

export const loadContracts = customerId => dispatch => {
  if (customerId) {
    fetchContracts(customerId)(dispatch);
  }
};
