import React from 'react';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Toast.scss';
import { ClearIcon } from '../../../assets';

const Toast = () => {
  return (
    <ToastContainer
      className="mg-toast-container"
      toastClassName="mg-toast-root d-flex align-items-center"
      bodyClassName="mg-toast-body"
      hideProgressBar
      transition={Slide}
      draggable={false}
      closeButton={
        // Need the fragment here to avoid 'React does not recognize the `closeToast`' warning
        <>
          <ClearIcon className="mg-toast-close-icon" />
        </>
      }
    />
  );
};

export default Toast;
