import React from 'react';
import Tooltip from '../Tooltip/index';
import { InfoIcon } from '../../../assets/index';

const Hint = ({ children, size, className, textAlignStart }) => {
  return (
    <Tooltip
      title={children}
      className={className}
      size={size}
      textAlignStart={textAlignStart}
      flex
    >
      <InfoIcon className="mg-icon-gray mg-cursor-pointer" />
    </Tooltip>
  );
};

export default Hint;
