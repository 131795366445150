import { t } from '@lingui/macro';
import { isRequired } from '../../../utils';
import { t8 } from '../../../i18n/i18n';
import { isEmail } from '../../../utils/email-utils';

export const validate = email => {
  let emailError = null;
  if (
    email.emailAddresses.length === 0 ||
    email.emailAddresses.some(e => !isEmail(e))
  ) {
    emailError = t8(t`Some emails are invalid.`);
  }

  return {
    subject: isRequired(t8(t`Subject`))(email.subject),
    emailAddresses: emailError,
    emailAddressesCount:
      email.emailAddresses.length === 0 ? t8(t`Email is required.`) : null,
  };
};
