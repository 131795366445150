import React from 'react';

export const useFetchPage = (
  dispatch,
  loadByPageNumber,
  pageNumber,
  goToFirstPage
) => {
  const fetch = React.useCallback(
    () => dispatch(loadByPageNumber(pageNumber)),
    [dispatch, loadByPageNumber, pageNumber]
  );

  React.useEffect(() => {
    fetch();
  }, [fetch]);

  const goToFirstPageAndFetch = React.useCallback(() => {
    if (+pageNumber === 1) {
      fetch();
    } else {
      goToFirstPage();
    }
  }, [fetch, goToFirstPage, pageNumber]);

  return [fetch, goToFirstPageAndFetch];
};
