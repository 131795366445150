import { getRandomInteger } from './number-utils';

export const filterUnique = (value, idx, array) => array.indexOf(value) === idx;

export const uniqueBy = (array, compareBy) =>
  Array.from(new Set(array.map(compareBy))).map(key =>
    array.find(v => compareBy(v) === key)
  );

export const unique = array => {
  return Array.from(new Set(array));
};

export const replaceBy = (array, replacement, compareBy) => {
  const replaceAtIndex = array.findIndex(
    e => compareBy(e) === compareBy(replacement)
  );
  if (replaceAtIndex < 0) {
    throw new Error(
      'Unable to find element to replace in the array. Make sure that the element exists in the array.'
    );
  }
  const result = [...array];
  result[replaceAtIndex] = replacement;
  return result;
};

export const getMaxValue = (array, valueSelector) => {
  return Math.max(...array.map(valueSelector));
};

export const getRandomElement = array => {
  return array[getRandomInteger(array.length)];
};

export const reduceToObject = (
  array,
  keySelector = ({ id }) => id,
  valueSelector = e => e
) => {
  return array.reduce(
    (acc, element) => ({
      ...acc,
      [keySelector(element)]: valueSelector(element),
    }),
    {}
  );
};

export const defaultSortingFn = (a, b) => {
  if (a && b == null) {
    // defined values go first
    return 1;
  }

  if (a == null && b) {
    // undefined values go last
    return -1;
  }

  if (a == null && b == null) {
    return 0;
  }

  if (a > b) {
    return 1;
  }

  if (a < b) {
    return -1;
  }

  return 0;
};

export const sortByKey = (array, keySelector) => {
  return array.sort((a, b) => defaultSortingFn(keySelector(a), keySelector(b)));
};

export const sortByArray = (
  array1,
  array2,
  compareBy = (el1, el2) => el1 === el2
) => {
  return array1.map(el1 => array2.filter(el2 => compareBy(el1, el2))).flat();
};

const f = (a, b) => [].concat(...a.map(d => b.map(e => [].concat(d, e))));
export const cartesian = (a, b, ...c) => (b ? cartesian(f(a, b), ...c) : a);

export const minBy = (arr, by) => Math.min(...arr.map(by));
export const maxBy = (arr, by) => Math.max(...arr.map(by));
