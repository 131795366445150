import React from 'react';
import clsx from 'clsx';

const Label = ({ id, label, shrink }) => {
  return (
    <label
      htmlFor={id}
      className={clsx('mg-input-label', {
        'mg-input-label-shrink': shrink,
      })}
    >
      {label}
    </label>
  );
};

export default Label;
