import { shouldLoadUnlessInStore } from './utils/index';
import { getHttpActionNames, buildHttpGetAction } from './utils/httpActions';
import { buildUri } from '../../services/buildUri';

export const RolesReadActionNames = getHttpActionNames('ROLES');

const fetchRoles = () => dispatch => {
  const url = buildUri('roles');
  const action = buildHttpGetAction(RolesReadActionNames, url);
  return dispatch(action);
};

const shouldLoadRoles = state => {
  return shouldLoadUnlessInStore(state.roles, 'roles');
};

export const loadRolesIfNeeded = () => (dispatch, getState) => {
  if (shouldLoadRoles(getState())) {
    fetchRoles()(dispatch);
  }
};
