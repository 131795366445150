import React from 'react';
import { Trans } from '@lingui/macro';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Loading,
  FixErrorsTooltip,
  CanUser,
} from '../../Common';
import UserDetailsRolesEdit from '../../Users/UserDetailDialog/UserDetailsRolesEdit';
import UserDetailsRolesView from '../../Users/UserDetailDialog/UserDetailsRolesView';
import { hasErrors } from '../../../utils/index';
import { Permission } from '../../../auth/app-permissions/permission';
import { useContracts } from '../../../store/hooks/useContracts';
import { useRoleSelection } from '../../Users/useRoleSelection';

const TenantDetailDialog = ({
  tenant,
  onClose,
  onSubmit,
  editMode: editModeProp = false,
}) => {
  const [editMode, setEditMode] = React.useState(false);

  const [
    {
      isFetching,
      roles,
      selectedRoles,
      adminRoleId,
      vesselInsights,
      vesselInsightsRoleSelected,
      vesselInsightsModel,
      vesselInsightsErrors,
      errors,
      touched,
      containsActiveErrors,
    },
    { onRoleChange, setAllTouched },
  ] = useRoleSelection(
    tenant.tenant.roles,
    (tenant.customerDetails || {}).salesforceAccountId
  );

  const [, isContractsFetching, productCodes] = useContracts(
    (tenant.customerDetails || {}).salesforceAccountId
  );

  const handleSubmit = () => {
    if (
      hasErrors(errors) ||
      (vesselInsightsRoleSelected && hasErrors(vesselInsightsErrors))
    ) {
      setAllTouched();
    } else {
      onSubmit({
        id: tenant.tenant.id,
        roles: selectedRoles,
        vesselInsights: vesselInsightsRoleSelected ? vesselInsightsModel : null,
      });
    }
  };

  React.useEffect(() => {
    setEditMode(editModeProp);
  }, [editModeProp]);

  const handleEdit = () => {
    setEditMode(true);
  };

  return (
    <Dialog open>
      {(isFetching || isContractsFetching) && <Loading />}
      <DialogTitle onClose={onClose}>
        {editMode ? (
          <Trans>Edit tenant details</Trans>
        ) : (
          <Trans>Tenant details</Trans>
        )}
      </DialogTitle>
      <DialogContent>
        <div>
          <h5 className="mb-2">
            <Trans>Customer name</Trans>
          </h5>
          <p className="mg-body-2 mb-2">{tenant.customerDetails.name}</p>
        </div>
        <h5 className="mb-2">
          {editMode ? (
            <Trans>Select assigned roles</Trans>
          ) : (
            <Trans>Assigned roles</Trans>
          )}
        </h5>
        {touched.selectedRoles && errors.selectedRoles && (
          <p className="mg-error-message mt-1">{errors.selectedRoles}</p>
        )}
        {editMode ? (
          <UserDetailsRolesEdit
            adminRoleId={adminRoleId}
            selectedRoles={selectedRoles}
            roles={roles}
            vesselInsightsRoleSelected={vesselInsightsRoleSelected}
            onRoleChange={onRoleChange}
            vesselInsights={vesselInsights}
            initialVesselInsights={tenant.tenant.vesselInsights}
            productCodes={productCodes}
          />
        ) : (
          <UserDetailsRolesView
            selectedRoles={selectedRoles}
            roles={roles}
            vesselInsightsRoleSelected={vesselInsightsRoleSelected}
            vesselInsights={tenant.tenant.vesselInsights}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          {editMode ? <Trans>Cancel</Trans> : <Trans>Close</Trans>}
        </Button>
        {editMode ? (
          <FixErrorsTooltip show={containsActiveErrors}>
            <Button
              color="secondary"
              onClick={handleSubmit}
              disabled={containsActiveErrors}
            >
              <Trans>Update</Trans>
            </Button>
          </FixErrorsTooltip>
        ) : (
          <CanUser permission={Permission.TenantsEdit}>
            <Button color="secondary" onClick={handleEdit}>
              <Trans>Edit</Trans>
            </Button>
          </CanUser>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default TenantDetailDialog;
