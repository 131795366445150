import { onlyResolvesLast } from 'awesome-only-resolves-last-promise';
import debounce from 'debounce-promise';

export const toPromise = value => Promise.resolve(value);

export const promisify = fn => (...args) => toPromise(fn(...args));

export const debounceAndResolveLast = (fn, delay = 100) => {
  return debounce(onlyResolvesLast(promisify(fn)), delay);
};
