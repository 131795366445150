import { buildUri } from '../../services/buildUri';
import { callApi, HTTP_POST } from '../../services/callApi';

export const useSendEmail = () => {
  const sendEmail = async (id, email, onSuccess, onError) => {
    try {
      const url = buildUri(`invitations/sendCredentials/${id}`);
      await callApi(url, HTTP_POST, email);
      if (onSuccess) {
        onSuccess();
      }
    } catch {
      if (onError) {
        onError();
      }
    }
  };

  const sendInvitation = async (email, onSuccess, onError) => {
    try {
      const url = buildUri('invitations');
      await callApi(url, HTTP_POST, email);
      if (onSuccess) {
        onSuccess();
      }
    } catch {
      if (onError) {
        onError();
      }
    }
  };

  const sendEmailChangedNotification = async (email, onSuccess, onError) => {
    try {
      const url = buildUri('invitations/sendEmailChangedNotification');
      await callApi(url, HTTP_POST, email);
      if (onSuccess) {
        onSuccess();
      }
    } catch {
      if (onError) {
        onError();
      }
    }
  };

  return [sendEmail, sendInvitation, sendEmailChangedNotification];
};
