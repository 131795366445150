import zipObject from 'lodash/zipObject';
import { KEYS } from '../constants';

export const splitText = (
  text,
  splitWith = [KEYS.COMMA, KEYS.SEMICOLON, KEYS.NEW_LINE]
) => {
  return text
    .split(new RegExp(`[${splitWith.join('')}]`, 'g'))
    .map(s => s.trim());
};

export const splitTextFiltering = (text, splitWith) => {
  return splitText(text, splitWith).filter(s => s);
};

export const parseCsvWithHeader = content => {
  const lines = splitTextFiltering(content, [KEYS.NEW_LINE]);
  if (!lines.length) {
    return [];
  }

  const separators = [KEYS.COMMA, KEYS.SEMICOLON];
  const keys = splitText(lines[0], separators);

  if (keys.some(key => key == null)) {
    throw new Error('Invalid csv format');
  }

  const result = lines
    .slice(1)
    .map(line => splitText(line, separators))
    .map(values => zipObject(keys, values));

  return result;
};
