import React from 'react';
import groupBy from 'lodash/groupBy';
import clsx from 'clsx';
import { Trans } from '@lingui/macro';
import {
  FormControlLabel,
  CheckBox,
  Loading,
  FramedContainer,
  Tooltip,
  IconButton,
} from '../../Common';
import { InfoIcon } from '../../../assets/index';
import { useRoleGroups } from '../../../store/hooks/useRoleGroups';
import { useRoleRules } from '../../../store/hooks/useRoleRules';
import './UserDetailsRolesEdit.scss';
import {
  utcDate,
  toGeneralDateShortTimeFormatString,
  isBefore,
  getUtcNow,
} from '../../../utils';
import VesselInsightsEdit from './Vessel/VesselInsightsEdit';

const UserDetailsRolesEdit = ({
  selectedRoles,
  roles,
  adminRoleId,
  vesselInsightsRoleSelected,
  onRoleChange,
  vesselInsights,
  initialVesselInsights,
  productCodes,
}) => {
  const { roleGroups, isFetching } = useRoleGroups();
  const { roleRules, isFetching: isRoleRulesFetching } = useRoleRules();
  const groupedRoles = React.useMemo(
    () => Object.entries(groupBy(roles, r => r.roleGroupId)),
    [roles]
  );

  const adminRoleSelected = React.useMemo(
    () => selectedRoles.includes(adminRoleId),
    [adminRoleId, selectedRoles]
  );

  return (
    <FramedContainer className="px-2 pb-2">
      {(isFetching || isRoleRulesFetching) && <Loading />}
      {selectedRoles &&
        roles &&
        roleGroups &&
        groupedRoles
          .filter(
            ([roleGroupId]) => !!roleGroups.find(rg => rg.id === roleGroupId)
          )
          .map(([roleGroupId, grouped]) => {
            return (
              <div key={roleGroupId} className="py-2">
                <h6>{roleGroups.find(rg => rg.id === roleGroupId).name}</h6>
                <div key={roleGroupId} className="row mx-0">
                  {grouped.map(r => {
                    const rule =
                      roleRules && roleRules.find(rr => rr.roleId === r.id);
                    const productCode =
                      rule &&
                      productCodes.find(
                        pc => pc.productCode === rule.productCode
                      );
                    const productExpired =
                      productCode && isBefore(productCode.endDate, getUtcNow());
                    return (
                      (!rule || !!(rule && productCode)) && (
                        <div key={r.id} className="p-0 mg-thin-checkbox">
                          <FormControlLabel
                            disabled={
                              productExpired ||
                              (adminRoleSelected && r.id !== adminRoleId)
                            }
                            key={r.id}
                            label={r.name}
                            className="mg-align-checkbox-left mr-1"
                          >
                            <CheckBox
                              checked={
                                (adminRoleSelected &&
                                  r.id !== adminRoleId &&
                                  !productExpired) ||
                                selectedRoles.includes(r.id) ||
                                false
                              }
                              onChange={e =>
                                onRoleChange(r.id, e.target.checked)
                              }
                              value={r.name || ''}
                            />
                          </FormControlLabel>
                          <Tooltip title={r.description}>
                            <IconButton size="small">
                              <InfoIcon height={20} width={20} />
                            </IconButton>
                          </Tooltip>
                          {!!rule && (
                            <p
                              className={clsx('mg-contract-end-line', {
                                'mg-error-message': productExpired,
                                'mg-body-2-gray': !productExpired,
                              })}
                            >
                              <Trans>Ends at</Trans>{' '}
                              {toGeneralDateShortTimeFormatString(
                                utcDate(productCode.endDate)
                              )}{' '}
                              <Trans>UTC</Trans>
                            </p>
                          )}
                        </div>
                      )
                    );
                  })}
                </div>
              </div>
            );
          })}
      {vesselInsightsRoleSelected && (
        <VesselInsightsEdit
          vesselInsights={vesselInsights}
          initialVesselInsights={initialVesselInsights}
        />
      )}
    </FramedContainer>
  );
};

export default UserDetailsRolesEdit;
