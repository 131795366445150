import { RoleGroupsReadActionNames } from '../actions';

export const roleGroups = (
  state = {
    isFetching: false,
    roleGroups: null,
  },
  action
) => {
  switch (action.type) {
    case RoleGroupsReadActionNames.REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case RoleGroupsReadActionNames.SUCCESS:
      return {
        ...state,
        isFetching: false,
        roleGroups: action.response,
      };
    case RoleGroupsReadActionNames.FAILURE:
      return {
        ...state,
        isFetching: false,
      };
    default:
      return state;
  }
};
