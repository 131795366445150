import React from 'react';
import clsx from 'clsx';
import Popper from '../Popper';

const Tooltip = ({
  children,
  title,
  placement = 'bottom',
  className,
  size = 'md',
  flex = false,
  inline = false,
  textAlignStart,
  fullWidth,
}) => {
  const [childNode, setChildNode] = React.useState(null);
  const [open, setOpen] = React.useState(false);

  const handleEnter = () => {
    setOpen(true);
  };

  const handleLeave = () => {
    setOpen(false);
  };

  const childrenProps = {
    onMouseOver: handleEnter,
    onMouseLeave: handleLeave,
  };

  return (
    <>
      <div
        className={clsx(
          'mg-tooltip-wrapper',
          {
            'mg-flex': flex,
            'mg-inline': inline,
            'mg-full-width': fullWidth,
          },
          className
        )}
        ref={setChildNode}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...childrenProps}
      >
        {children}
      </div>
      <Popper
        className="mg-tooltip-popper"
        placement={placement}
        anchorEl={childNode}
        open={title && childNode ? open : false}
      >
        <div
          className={clsx('mg-tooltip-content', {
            [`mg-size-${size}`]: size !== 'md',
            'mg-text-align-start': textAlignStart,
          })}
        >
          {title}
        </div>
      </Popper>
    </>
  );
};

export default React.memo(Tooltip);
