import { LOCAL_STORAGE_STATE_KEY } from '../config';

export const loadState = () => {
  try {
    const serialized = localStorage.getItem(LOCAL_STORAGE_STATE_KEY);
    if (serialized === null) {
      return undefined;
    }
    const state = JSON.parse(serialized);
    return state;
  } catch {
    return undefined;
  }
};

export const saveState = state => {
  try {
    const serialized = JSON.stringify(state);
    localStorage.setItem(LOCAL_STORAGE_STATE_KEY, serialized);
  } catch {
    // Ignore write errors
  }
};

export const clearState = () => {
  saveState({});
};
