import React from 'react';
import clsx from 'clsx';
import AppBar from '../AppBar/AppBar';

const AppLayout = ({ header, children, className }) => {
  return (
    <div className="container mg-full-vh d-flex flex-column">
      <AppBar />
      {header}
      <div
        className={clsx(
          'mg-app-body d-flex flex-column flex-grow-1',
          className
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default AppLayout;
