import React from 'react';
import { Trans } from '@lingui/macro';
import VesselInfo from './Vessel/VesselInfo';

const UserDetailsRolesView = ({
  selectedRoles,
  roles,
  vesselInsightsRoleSelected,
  vesselInsights = {},
}) => {
  return (
    <>
      <p className="mg-body-2">
        {selectedRoles.length} (
        {(roles || [])
          .filter(r => selectedRoles.includes(r.id))
          .map(r => {
            return <span key={r.id}>{r.name}</span>;
          })
          .map((c, i) => [i > 0 && ', ', c])}
        )
      </p>
      {vesselInsightsRoleSelected && (
        <div className="mg-body-2">
          <h5 className="my-2">
            <Trans>Vessel Insights information</Trans>
          </h5>
          <p>
            <Trans>RouteGuard ID</Trans>:{' '}
            {vesselInsights.routeGuardId || (
              <span className="mg-error-message">
                <Trans>Not assigned</Trans>
              </span>
            )}
          </p>
          <p>
            <Trans>Vessels</Trans>:
          </p>
          <div className="d-flex row mx-0 mg-body-2">
            {vesselInsights.vessels && vesselInsights.vessels.length ? (
              vesselInsights.vessels.map(v => {
                return (
                  <div className="col-4 p-1" key={`${v.imo}-${v.mmsi}`}>
                    <VesselInfo vessel={v} />
                  </div>
                );
              })
            ) : (
              <p className="mg-body-2-gray mt-2">
                <Trans>No observed vessels have been set.</Trans>
              </p>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default UserDetailsRolesView;
