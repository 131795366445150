import { t } from '@lingui/macro';
import { t8 } from '../../../../i18n/i18n';
import { isRequired } from '../../../../utils/index';

export const validate = state => {
  const containsSameVessel = state.vessels.some(
    v => +v.imo === +state.imo && +v.mmsi === +state.mmsi
  );
  return {
    imo:
      isRequired(t8(t`IMO`))(state.imo) ||
      (containsSameVessel &&
        t8(t`Vessel with same IMO and MMSI already assigned.`)),
    mmsi:
      isRequired(t8(t`MMSI`))(state.mmsi) ||
      (containsSameVessel &&
        t8(t`Vessel with same IMO and MMSI already assigned.`)),
  };
};
