import { createErrorSetAction } from '../actions/error';

export default () => {
  return () => next => action => {
    if (action.error) {
      next(createErrorSetAction(action.error));
    }

    next(action);
  };
};
