import { t } from '@lingui/macro';
import { t8 } from '../../../../i18n/i18n';
import { isRequired, isEmail } from '../../../../utils/index';
import { callApi, HTTP_POST } from '../../../../services/callApi';

export const validate = selectedRoles => {
  return {
    selectedRoles:
      selectedRoles.length < 1 ? t8(t`At least 1 role is required`) : null,
  };
};

const emailExistsAsync = email => {
  return callApi(
    'v1/authenticationUserManagement/users/validateEmail',
    HTTP_POST,
    email
  );
};

const validateEmail = email => {
  const staticError =
    isRequired(t8(t`Email`))(email) ||
    (isEmail(email) ? null : 'Email is invalid.');

  if (staticError) {
    return staticError;
  }

  return emailExistsAsync(email)
    .then(emailExists => {
      return emailExists ? t8(t`A user with this email already exists.`) : null;
    })
    .catch(() => t8(t`Validation error. Please try again.`));
};

export const validateUserDetails = async userDetails => {
  return {
    userName: isRequired(t8(t`User name`))(userDetails.userName),
    email:
      userDetails.initialEmail && userDetails.initialEmail === userDetails.email
        ? null
        : await validateEmail(userDetails.email),
  };
};
