import { isNumber } from '../../../utils/index';

// regexps
const nondigitsOnly = /[^\d]+/gi;
const digitsOnly = /[\d]+/g;

const createFractionalAccept = floatingPointSeparator => {
  return new RegExp(`[\\d${floatingPointSeparator}]+`, 'g');
};

const negative = /[\d-]+/g;

const createNegativeFractionalAccept = floatingPointSeparator => {
  return new RegExp(`[\\d${floatingPointSeparator}-]+`, 'g');
};

// parsers
const parseBase = (string, regexp) => {
  return (string.match(regexp) || []).join('');
};

const createParseFractional = floatingPointSeparator => string =>
  parseBase(string, createFractionalAccept(floatingPointSeparator));
const parseNegative = string => parseBase(string, negative);
const createParseNegativeFractional = floatingPointSeparator => string =>
  parseBase(string, createNegativeFractionalAccept(floatingPointSeparator));

// formatters
const formatDigitsOnly = str => {
  return (str == null ? '' : str).toString().replace(nondigitsOnly, '');
};

const formatPositiveInteger = str => {
  const r = parseInt(formatDigitsOnly(str), 10);
  return isNumber(r) ? r.toString() : '';
};

const formatNegative = v => {
  const string = v == null ? '' : v.toString();
  const parsed = parseNegative(string);
  if (parsed === '-') {
    return '-';
  }

  const number = Number.parseInt(parsed, 10);
  if (Number.isNaN(number)) {
    return '';
  }

  return number.toString();
};

const createParseFractionalNumber = floatingPointSeparator => v => {
  const string = v == null ? '' : v.toString();
  const parsed = createParseFractional(floatingPointSeparator)(string);
  const [head, tail] = parsed.split(floatingPointSeparator);
  const scaledTail = tail != null ? tail : '';

  const number = Number.parseFloat(`${head}.${scaledTail}`);

  return number;
};

const createFormatFractionalNumber = (floatingPointSeparator, locale) => v => {
  const string = v == null ? '' : v.toString();
  const parsed = createParseFractional(floatingPointSeparator)(string);
  const [, tail] = parsed.split(floatingPointSeparator);
  const scaledTail = tail != null ? tail : '';

  const number = createParseFractionalNumber(floatingPointSeparator)(v);

  if (Number.isNaN(number)) {
    return '';
  }

  const formatted = number.toLocaleString(locale);

  if (parsed.includes(floatingPointSeparator)) {
    const [formattedHead] = formatted.split(floatingPointSeparator);

    const formattedTail = scaledTail;

    return `${formattedHead}${floatingPointSeparator}${formattedTail}`;
  }
  return formatted;
};

const createFormatNegativeFractional = (
  floatingPointSeparator,
  locale
) => v => {
  const toString = val =>
    locale ? val.toLocaleString(locale) : val.toString();
  const string = v == null ? '' : toString(v);
  const parsed = createParseNegativeFractional(floatingPointSeparator)(string);
  if (parsed === '-') {
    return '-';
  }
  const [head, tail] = parsed.split(floatingPointSeparator);
  const scaledTail = tail != null ? tail : '';

  const headTail = `${head}.${scaledTail}`;
  const number = Number.parseFloat(headTail);

  if (Number.isNaN(number)) {
    return '';
  }
  const formatted =
    headTail === `-0${floatingPointSeparator}` ? '-0' : toString(number);

  if (parsed.includes(floatingPointSeparator)) {
    const [formattedHead] = formatted.split(floatingPointSeparator);

    const formattedTail = scaledTail;

    return `${formattedHead}${floatingPointSeparator}${formattedTail}`;
  }
  return formatted;
};

const createFormatFixedPointNumber = (floatingPointSeparator, locale) => (
  value,
  digits
) => {
  const parsed = createParseFractional(floatingPointSeparator)(value || '');

  const [head, tail] = parsed.split(floatingPointSeparator);
  const scaledTail = tail != null ? tail.slice(0, digits) : '';

  const number = Number.parseFloat(`${head}.${scaledTail}`);

  if (Number.isNaN(number)) {
    return '';
  }

  const formatted = number.toLocaleString(locale, {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  });

  return formatted;
};

const createFormatFixedPointNumberString = (floatingPointSeparator, locale) => (
  num,
  digits
) => {
  return createFormatFixedPointNumber(floatingPointSeparator, locale)(
    (num == null ? '' : num).toLocaleString('ru'),
    digits
  );
};

export const createNumericInputUtils = (floatingPointSeparator, locale) => {
  return {
    nondigitsOnly,
    digitsOnly,
    fractional: createFractionalAccept(floatingPointSeparator),
    negative,
    negativeFractional: createNegativeFractionalAccept(floatingPointSeparator),
    parseFractionalNumber: createParseFractionalNumber(floatingPointSeparator),
    parseFractional: createParseFractional(floatingPointSeparator),
    parseNegative,
    parseNegativeFractional: createParseNegativeFractional(
      floatingPointSeparator
    ),
    formatDigitsOnly,
    formatPositiveInteger,
    formatNegative,
    formatFractionalNumber: createFormatFractionalNumber(
      floatingPointSeparator,
      locale
    ),
    formatNegativeFractional: createFormatNegativeFractional(
      floatingPointSeparator,
      locale
    ),
    formatNegativeFractionalLocaleInvariant: createFormatNegativeFractional(
      floatingPointSeparator
    ),
    formatFixedPointNumber: createFormatFixedPointNumber(
      floatingPointSeparator,
      locale
    ),
    formatFixedPointNumberString: createFormatFixedPointNumberString(
      floatingPointSeparator,
      locale
    ),
  };
};
