import React from 'react';
import { Trans } from '@lingui/macro';
import { Button, Divider, CanUser } from '../../Common';
import { AddIcon } from '../../../assets/index';
import { Permission } from '../../../auth/app-permissions/permission';

const UsersActions = ({ selectedIds, onAdd, onDeleteSelected }) => {
  return (
    <div className="d-flex">
      <CanUser permission={Permission.UsersCreate}>
        <Button
          color="secondary"
          startIcon={<AddIcon />}
          onClick={() => onAdd()}
        >
          <Trans>User</Trans>
        </Button>
      </CanUser>
      {!!selectedIds.length && (
        <>
          <CanUser permission={Permission.UsersDelete}>
            <Divider className="my-0 mx-5" />
            <Button color="error" onClick={onDeleteSelected}>
              <Trans>{`Delete (${selectedIds.length})`}</Trans>
            </Button>
          </CanUser>
        </>
      )}
    </div>
  );
};

export default UsersActions;
