import { shouldLoadUnlessInStore } from './utils/index';
import { getHttpActionNames, buildHttpGetAction } from './utils/httpActions';
import { buildUri } from '../../services/buildUri';

export const RoleRulesReadActionNames = getHttpActionNames('ROLE_RULES');

const fetchRoleRules = () => dispatch => {
  const url = buildUri('roleRules');
  const action = buildHttpGetAction(RoleRulesReadActionNames, url);
  return dispatch(action);
};

const shouldLoadRoleRules = state => {
  return shouldLoadUnlessInStore(state.roleRules, 'roleRules');
};

export const loadRoleRulesIfNeeded = () => (dispatch, getState) => {
  if (shouldLoadRoleRules(getState())) {
    fetchRoleRules()(dispatch);
  }
};
