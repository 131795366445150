import React from 'react';

const TablePageContainer = ({ children, minWidth }) => {
  return (
    <div className="px-4" style={{ minWidth }}>
      {children}
    </div>
  );
};

export default TablePageContainer;
