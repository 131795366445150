import React from 'react';
import { Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';
import { KeyboardArrowDownIcon } from '../../../assets';
import { useAuth0 } from '../../../auth/react-auth0-spa';
import Menu from '../../Common/Menu/Menu';
import ListItem from '../../Common/ListItem/index';
import Avatar from '../../Common/Avatar/Avatar';
import Divider from '../../Common/Divider/Divider';
import Description from '../../Common/Description/Description';

export default function UserProfile() {
  const { isAuthenticated, user } = useAuth0();
  const anchorEl = React.useRef(null);
  const [open, setOpen] = React.useState(false);

  const handleOpen = React.useCallback(() => setOpen(true), []);
  const handleClose = React.useCallback(() => setOpen(false), []);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <div
      ref={anchorEl}
      className="mg-user-profile d-flex align-items-center"
      onClick={handleOpen}
      role="button"
      tabIndex="0"
      onKeyPress={() => {}}
    >
      <Avatar pictureUrl={user.picture} alt={user.name} />
      <KeyboardArrowDownIcon className="ml-1" />
      <Menu
        className="mg-user-profile-menu"
        anchorEl={anchorEl.current}
        open={open}
        onClickAway={handleClose}
        placement="bottom-end"
      >
        <li className="mx-3 mt-2 mb-3">
          <p>{user.name}</p>
          <Description className="mt-1">{user.email}</Description>
        </li>
        <Divider orientation="horizontal" flexItem={false} className="mx-3" />
        <ListItem className="mg-plain">
          <Link to="/logout" className="mg-link">
            <Trans>Sign Out</Trans>
          </Link>
        </ListItem>
      </Menu>
    </div>
  );
}
