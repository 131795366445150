import React from 'react';
import { useSelector } from 'react-redux';
import { useFetchPage } from './utils/useFetchPage';
import { useThunkDispatch } from './utils/useThunkDispatch';
import {
  loadUsers,
  createUser,
  deleteUsers,
  updateUser,
  rotateUserCredentials,
  setUsersSorting,
  setUsersSearchTerm,
  clearUsersFilters,
} from '../actions';

export const useUsers = (pageNumber, goToFirstPage) => {
  const dispatch = useThunkDispatch();

  const [fetch, goToFirstPageAndFetch] = useFetchPage(
    dispatch,
    loadUsers,
    pageNumber,
    goToFirstPage
  );

  const users = useSelector(store => store.users);

  const create = React.useCallback(
    (user, onSuccess) =>
      dispatch(
        createUser(user, result => {
          fetch();
          onSuccess(result);
        })
      ),
    [dispatch, fetch]
  );

  const remove = React.useCallback(
    (userIds, onSuccess) =>
      dispatch(
        deleteUsers(userIds, () => {
          fetch();
          if (onSuccess) {
            onSuccess();
          }
        })
      ),
    [dispatch, fetch]
  );

  const update = React.useCallback(
    (user, onSuccess) =>
      dispatch(
        updateUser(user, () => {
          fetch();
          onSuccess();
        })
      ),
    [dispatch, fetch]
  );

  const rotateSecret = React.useCallback(
    (user, onSuccess) =>
      dispatch(
        rotateUserCredentials(user, () => {
          fetch();
          onSuccess();
        })
      ),
    [dispatch, fetch]
  );

  const sort = React.useCallback(
    sorting => {
      dispatch(setUsersSorting(sorting));
      fetch();
    },
    [dispatch, fetch]
  );

  const setSearchTerm = React.useCallback(
    searchTerm => {
      const previousValue = users.searchTerms[searchTerm.searchField] || '';
      if (searchTerm.searchValue !== previousValue) {
        dispatch(setUsersSearchTerm(searchTerm));
        goToFirstPageAndFetch();
      }
    },
    [dispatch, goToFirstPageAndFetch, users.searchTerms]
  );

  const hasFilters = React.useMemo(
    () => Object.values(users.searchTerms).some(term => term),
    [users.searchTerms]
  );

  const clearFilters = React.useCallback(() => {
    dispatch(clearUsersFilters());
    fetch();
  }, [dispatch, fetch]);

  return [
    { ...users, hasFilters },
    { create, remove, update, rotateSecret, sort, setSearchTerm, clearFilters },
  ];
};
