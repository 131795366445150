/* eslint-disable no-underscore-dangle */
export default {
  roleClaimType: window.__REACT_APP_AUTH0_ROLE_CLAIM_TYPE__,
  oidClaimType: window.__REACT_APP_AUTH0_OID_CLAIM_TYPE__,
  appMetadataClaimType: 'http://schemas.flexible-reporting.com/app_metadata',
  auth0Options: {
    domain: window.__REACT_APP_AUTH0_DOMAIN__,
    client_id: window.__REACT_APP_AUTH0_CLIENT_ID__,
    audience: window.__REACT_APP_AUTH0_AUDIENCE__,
  },
};
