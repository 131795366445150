import React from 'react';
import { usePopper } from 'react-popper';
import clsx from 'clsx';
import './Popper.scss';
import Portal from '../Portal';

const Popper = props => {
  const {
    anchorEl,
    children,
    open,
    placement: initialPlacement = 'bottom',
    className,
    popperRef,
    container,
    disablePortal = false,
  } = props;
  const [popperElement, setPopperElement] = React.useState(null);

  const popperProps = usePopper(anchorEl, popperElement, {
    placement: initialPlacement,
    // https://github.com/FezVrasta/popper.js/issues/636#issuecomment-474572516
    modifiers: [
      {
        name: 'computeStyles',
        options: {
          gpuAcceleration: false, // true by default
        },
      },
    ],
  });
  if (popperRef) {
    popperRef.current = popperProps;
  }
  if (!open) {
    return null;
  }

  return (
    <Portal disablePortal={disablePortal} container={container}>
      <div
        ref={setPopperElement}
        role="tooltip"
        style={popperProps.styles.popper}
        className={clsx('mg-popper', className)}
        // eslint-disable-next-line react/jsx-props-no-spreading
        {...popperProps.attributes.popper}
      >
        {children}
      </div>
    </Portal>
  );
};

export default Popper;
