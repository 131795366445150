import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import throttle from 'lodash/throttle';
import api from './middleware/api';
import rootReducer from './reducers';
import { loadState, saveState } from '../services/localStorageService';
import { saveStateAtMostOncePerMs } from '../config';
import errorMiddleware from './middleware/errorMiddleware';

const composeEnhancers =
  (window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // set to true when needed.
      // otherwise keep it false, as tracing impacts performance
      trace: false,
      traceLimit: 25,
    })) ||
  compose;

const configureStore = preloadedState =>
  createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(applyMiddleware(thunk, api, errorMiddleware()))
  );

const preloadedState = loadState();

const store = configureStore(preloadedState);

const getDefaultTableState = entity => {
  return {
    recentSearches: entity.recentSearches,
    sortingField: entity.sortingField,
    sortingDirection: entity.sortingDirection,
    dateRange: {},
    isFetching: false,
    page: { items: [] },
    searchTerms: {},
  };
};

store.subscribe(
  throttle(() => {
    const { users } = store.getState();
    saveState({
      users: getDefaultTableState(users),
    });
  }, saveStateAtMostOncePerMs)
);

export default store;
