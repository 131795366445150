import React from 'react';
import clsx from 'clsx';
import './ToastMessage.scss';
import { InfoIcon } from '../../../../assets';

const ToastMessage = ({
  text,
  icon: Icon = InfoIcon,
  color = 'info',
  actionsAreaComponent,
}) => {
  return (
    <div
      className={clsx('mg-toast-content d-flex align-items-center', {
        [`mg-${color}`]: color,
      })}
    >
      <div className="mg-toast-icon d-flex">
        <Icon />
      </div>
      <p className="mg-toast-text">{text}</p>
      {actionsAreaComponent && (
        <div className="mg-toast-actions">{actionsAreaComponent}</div>
      )}
    </div>
  );
};

export default ToastMessage;
