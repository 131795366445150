import React from 'react';
import Portal from '../Portal';
import Backdrop from '../Backdrop/index';

const Modal = ({ open, children }) => {
  return (
    <Portal>
      {open && (
        <div className="mg-modal-root" role="presentation">
          <Backdrop />
          {children}
        </div>
      )}
    </Portal>
  );
};

export default Modal;
