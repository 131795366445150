import { CALL_API } from '../../middleware/api';
import { HTTP_POST, HTTP_PATCH, HTTP_DELETE } from '../../../services/callApi';

export const getHttpActionNames = prefix => {
  return {
    REQUEST: `${prefix}_REQUEST`,
    SUCCESS: `${prefix}_SUCCESS`,
    FAILURE: `${prefix}_FAILURE`,
  };
};

// use for actions that should not show spinner
export const getBackgroundHttpActionNames = prefix => {
  return {
    REQUEST: `${prefix}_REQUEST_BG`,
    SUCCESS: `${prefix}_SUCCESS_BG`,
    FAILURE: `${prefix}_FAILURE_BG`,
  };
};

export const buildHttpPostAction = (
  createActionNames,
  url,
  payload,
  onSuccess,
  id
) => ({
  [CALL_API]: {
    types: [
      createActionNames.REQUEST,
      { type: createActionNames.SUCCESS, callback: onSuccess },
      createActionNames.FAILURE,
    ],
    endpoint: url,
    method: HTTP_POST,
    payload,
    id,
  },
});

export const buildHttpPatchAction = (
  updateActionNames,
  url,
  payload,
  onSuccess,
  id
) => ({
  [CALL_API]: {
    types: [
      updateActionNames.REQUEST,
      { type: updateActionNames.SUCCESS, callback: onSuccess },
      updateActionNames.FAILURE,
    ],
    endpoint: url,
    method: HTTP_PATCH,
    payload,
    id,
  },
});

export const buildHttpGetAction = (readActionNames, url, onSuccess, id) => ({
  [CALL_API]: {
    types: [
      readActionNames.REQUEST,
      { type: readActionNames.SUCCESS, callback: onSuccess },
      readActionNames.FAILURE,
    ],
    endpoint: url,
    id,
  },
});

export const buildHttpDeleteAction = (
  deleteActionNames,
  url,
  payload,
  onSuccess
) => ({
  [CALL_API]: {
    types: [
      deleteActionNames.REQUEST,
      { type: deleteActionNames.SUCCESS, callback: onSuccess },
      deleteActionNames.FAILURE,
    ],
    endpoint: url,
    method: HTTP_DELETE,
    payload,
  },
});
