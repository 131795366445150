import React from 'react';
import clsx from 'clsx';
import Modal from '../Modal/Modal';
import Paper from '../Paper/Paper';

const Dialog = React.forwardRef(
  ({ open, children, size = 'md', paperClassName }, ref) => {
    return (
      <Modal open={open}>
        <div ref={ref} className="mg-dialog-container">
          <Paper
            className={clsx(
              'mg-dialog-paper',
              `mg-dialog-paper-${size}`,
              paperClassName
            )}
            elevation={8}
          >
            {children}
          </Paper>
        </div>
      </Modal>
    );
  }
);

export default Dialog;
