import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './styles';
import { Provider } from 'react-redux';
import { Auth0Provider } from './auth/react-auth0-spa';
import config from './auth/auth-config';
import App from './components/app/App';
import { BreakpointProvider } from './services/breakpoint';
import { mediaQueries } from './config';
import store from './store/configureStore';

ReactDOM.render(
  <Auth0Provider
    auth0Options={config.auth0Options}
    roleClaimType={config.roleClaimType}
    oidClaimType={config.oidClaimType}
    appMetadataClaimType={config.appMetadataClaimType}
  >
    <Provider store={store}>
      <BreakpointProvider queries={mediaQueries}>
        <App />
      </BreakpointProvider>
    </Provider>
  </Auth0Provider>,
  document.getElementById('root')
);
