import React from 'react';
import { Trans } from '@lingui/macro';
import { UserType } from '../../../../models/user-type';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormInputWithLength,
  Loading,
  FixErrorsTooltip,
} from '../../../Common';
import { useAsyncValidation } from '../../../Common/Form/useValidation';
import { validateUserDetails } from './validators';
import { hasErrors } from '../../../../utils/index';
import { useDeepMemo } from '../../../Common/utils/useDeepMemo';
import UserDetailsRolesEdit from '../../UserDetailDialog/UserDetailsRolesEdit';
import { useContracts } from '../../../../store/hooks/useContracts';
import { useRoleSelection } from '../../useRoleSelection';

const UserInfoDialog = ({
  userType,
  selectedCustomer,
  onClose,
  onContinue,
}) => {
  const [initialRoles] = React.useState([]);
  const [
    {
      isFetching,
      roles,
      selectedRoles,
      adminRoleId,
      vesselInsights,
      vesselInsightsRoleSelected,
      vesselInsightsModel,
      vesselInsightsErrors,
      errors,
      touched,
      containsActiveErrors,
    },
    { onRoleChange, setAllTouched },
  ] = useRoleSelection(
    initialRoles,
    (selectedCustomer || {}).customerDetails.salesforceAccountId
  );
  const [, isContractsFetching, productCodes] = useContracts(
    (selectedCustomer || {}).customerDetails.salesforceAccountId
  );

  const [userDetails, setUserDetails] = React.useState({
    email: '',
    userName: '',
  });

  const userDetailsMemo = useDeepMemo(() => userDetails, [userDetails]);
  const [
    detailsErrors,
    detailsTouched,
    detailsSetTouched,
    detailsContainsActiveErrors,
    validationPending,
  ] = useAsyncValidation(userDetailsMemo, validateUserDetails, {}, {});

  const setDialogAllTouched = () => {
    setAllTouched();
    detailsSetTouched({
      email: true,
      userName: true,
    });
  };

  const handleContinue = () => {
    if (
      hasErrors(errors) ||
      (userType === UserType.REGULAR && hasErrors(detailsErrors)) ||
      (vesselInsightsRoleSelected && hasErrors(vesselInsightsErrors))
    ) {
      setDialogAllTouched();
    } else {
      onContinue(
        selectedRoles,
        userDetails,
        vesselInsightsRoleSelected ? vesselInsightsModel : null
      );
    }
  };

  const handleDetailsChange = (value, prop) => {
    setUserDetails(prev => {
      return {
        ...prev,
        [prop]: value,
      };
    });
  };

  const handleDetailsTouched = prop => {
    detailsSetTouched({
      ...detailsTouched,
      [prop]: true,
    });
  };

  return (
    <Dialog open>
      {(isFetching || isContractsFetching) && <Loading />}
      <DialogTitle onClose={onClose}>
        {userType === UserType.REGULAR ? (
          <Trans>New user</Trans>
        ) : (
          <Trans>Select roles</Trans>
        )}
      </DialogTitle>
      <DialogContent>
        {userType === UserType.REGULAR && (
          <>
            <p className="mg-body-2-gray mb-2">
              <Trans>
                Fill user information. Password will be set by user after
                creation.
              </Trans>
            </p>
            <FormInputWithLength
              maxLength={100}
              fullWidth
              label={<Trans>User name</Trans>}
              value={userDetails.userName}
              onChange={e => handleDetailsChange(e.target.value, 'userName')}
              error={detailsTouched.userName && detailsErrors.userName}
              onBlur={() => handleDetailsTouched('userName')}
            />
            <FormInputWithLength
              maxLength={100}
              fullWidth
              className="mt-2"
              label={<Trans>Email</Trans>}
              value={userDetails.email}
              error={
                detailsTouched.email &&
                !validationPending &&
                detailsErrors.email
              }
              onChange={e => handleDetailsChange(e.target.value, 'email')}
              onBlur={() => handleDetailsTouched('email')}
            />
          </>
        )}
        <h5 className="mb-2">
          <Trans>Select roles to assign to user.</Trans>
        </h5>
        {touched.selectedRoles && errors.selectedRoles && (
          <p className="mg-error-message mt-1">{errors.selectedRoles}</p>
        )}
        <UserDetailsRolesEdit
          adminRoleId={adminRoleId}
          selectedRoles={selectedRoles}
          roles={roles}
          vesselInsightsRoleSelected={vesselInsightsRoleSelected}
          onRoleChange={onRoleChange}
          vesselInsights={vesselInsights}
          initialVesselInsights={selectedCustomer.vesselInsights}
          productCodes={productCodes}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>
          <Trans>Cancel</Trans>
        </Button>
        <FixErrorsTooltip
          show={
            containsActiveErrors ||
            (userType === UserType.REGULAR && detailsContainsActiveErrors)
          }
        >
          <Button
            color="secondary"
            onClick={handleContinue}
            disabled={
              containsActiveErrors ||
              (userType === UserType.REGULAR && detailsContainsActiveErrors)
            }
          >
            <Trans>Create</Trans>
          </Button>
        </FixErrorsTooltip>
      </DialogActions>
    </Dialog>
  );
};

export default UserInfoDialog;
