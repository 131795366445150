import React from 'react';
import { useSelector } from 'react-redux';
import { useThunkDispatch } from './useThunkDispatch';

export const useFetchFromStore = (load, selector) => {
  const dispatch = useThunkDispatch();

  const fetch = React.useCallback(() => dispatch(load()), [dispatch, load]);

  React.useEffect(() => {
    fetch();
  }, [fetch]);

  return useSelector(selector);
};
