import React from 'react';
import ContractSelectDialog from './ContractSelectDialog/ContractSelectDialog';
import UserTypeSelectDialog from './UserTypeSelectDialog/UserTypeSelectDialog';
import UserInfoDialog from './UserInfoDialog/UserInfoDialog';

const UserCreateDialog = ({ onClose, onCreate }) => {
  const [selectedCustomer, setSelectedCustomer] = React.useState();
  const [selectedUserType, setSelectedUserType] = React.useState(null);
  const [selectedEmail, setSelectedEmail] = React.useState(null);

  const handleContinue = customer => {
    setSelectedCustomer(customer);
  };

  const handleTypeContinue = ({ userType, email }) => {
    setSelectedUserType(userType);
    setSelectedEmail(email);
  };

  const handleCreate = (roleIds, userDetails, vesselInsightsModel) => {
    onCreate(
      {
        accountId: selectedCustomer.customerDetails.salesforceAccountId,
        customerId: selectedCustomer.customerDetails.salesforceCustomerId,
        userType: selectedUserType,
        roles: roleIds,
        userName: userDetails.userName,
        email: userDetails.email,
        vesselInsights: vesselInsightsModel,
      },
      selectedEmail
    );
  };

  return (
    <>
      {!!selectedCustomer || (
        <ContractSelectDialog onClose={onClose} onContinue={handleContinue} />
      )}
      {selectedCustomer && selectedUserType === null && (
        <UserTypeSelectDialog
          onClose={onClose}
          onContinue={handleTypeContinue}
          customerName={selectedCustomer.customerDetails.name}
        />
      )}
      {selectedUserType !== null && (
        <UserInfoDialog
          userType={selectedUserType}
          onClose={onClose}
          onContinue={handleCreate}
          selectedCustomer={selectedCustomer}
        />
      )}
    </>
  );
};

export default UserCreateDialog;
