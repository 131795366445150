import React from 'react';
import { Trans } from '@lingui/macro';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Description,
  DialogActions,
  Button,
  FormSubmitButton,
} from '../../../Common';
import { hasErrors } from '../../../../utils';
import { useEmailConfiguration } from '../../EmailConfiguration/useEmailConfiguration';
import EmailConfiguration from '../../EmailConfiguration/EmailConfiguration';
import { supportEmail } from '../../../../config';

const UserSendCredentialsDialog = ({ onClose, onSubmit, customerName }) => {
  const emailConfiguration = useEmailConfiguration(customerName);

  const [
    { email, errors, hasActiveErrors },
    { setAllTouched },
  ] = emailConfiguration;

  const handleDone = () => {
    if (hasErrors(errors)) {
      setAllTouched();
      return;
    }

    onSubmit(email);
  };

  return (
    <Dialog open size="sm">
      <DialogTitle onClose={onClose}>
        <Trans>New email</Trans>
      </DialogTitle>
      <DialogContent>
        <Description className="mb-4">
          <Trans id="users.email.message">
            Credentials will be sent to the entered emails from
            {supportEmail}
          </Trans>
        </Description>
        <EmailConfiguration emailConfiguration={emailConfiguration} />
      </DialogContent>

      <DialogActions>
        <Button className="mr-3" onClick={onClose}>
          <Trans>Cancel</Trans>
        </Button>
        <FormSubmitButton disabled={hasActiveErrors} onClick={handleDone}>
          <Trans>Send</Trans>
        </FormSubmitButton>
      </DialogActions>
    </Dialog>
  );
};

export default UserSendCredentialsDialog;
