export const isOwner = (resourceOwnerId, currentUser) => {
  const currentUserId = currentUser && currentUser.oid;
  if (!currentUserId || !resourceOwnerId) {
    return false;
  }

  return currentUserId === resourceOwnerId;
};

export const isNotSelf = (resourceOwnerId, currentUser) => {
  return !isOwner(resourceOwnerId, currentUser);
};

export const hasRole = role => (_, user) => {
  return user && user.roles.some(r => r === role);
};
