import React from 'react';
import './Loading.scss';
import CircularProgress from '../CircularProgress/CircularProgress';
import Portal from '../Portal/index';

const Loading = () => (
  <Portal>
    <div className="mg-progress-container">
      <CircularProgress size={100} />
    </div>
  </Portal>
);

export default Loading;
